import BASEAPI from '../api/config'
import { lancamentoCadastroPayload, paransListLancamentos, lancamentoUpdatePayload} from '../../interfaces/lancamentos'

export function novoLancamento (payload: lancamentoCadastroPayload) {
    return BASEAPI.post('/lancamentos', payload)
}

export function lisatarLancamentos (params: paransListLancamentos) {
    return BASEAPI.get (`/lancamentos?pagina=${params.pagina}&tipo=${params.tipo}&vencimentoInicial=${params.vencimentoInicial}&vencimentoFinal=${params.vencimentoFinal}&cliente=${params.cliente}&descricao=${params.descricao}&situacao=${params.situacao}&recorrencia=${params.recorrencia}&inadimplencia=${params.inadimplencia}`)
}

export function atualizarLancamento (id: string, payload: lancamentoUpdatePayload) {
    return BASEAPI.patch(`/lancamentos/${id}`, payload)
}

export function apagarLancamento (id: string) {
    return BASEAPI.delete(`/lancamentos/${id}`)
}