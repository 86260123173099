import BASEAPI from '../api/config'
import { cadastrarLeadPayload, paramsListLeads, atualizarLeadPayload } from '../../interfaces/leads'

export function novoLead (payload: cadastrarLeadPayload) {
    return BASEAPI.post('/leads', payload)
}

export function lisatarLeads (params: paramsListLeads) {
    return BASEAPI.get (`/leads?nome=${params.nome}&situacao=${params.situacao}`)
}

export function atualizarLead (idLead: string, payload: atualizarLeadPayload) {
    return BASEAPI.patch(`/leads?idLead=${idLead}`, payload)
}

export function deletarLead (idLead: string) {
    return BASEAPI.delete(`/leads?idLead=${idLead}`)
}
