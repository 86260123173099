import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Produtividade } from '../../services/meuPainel'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import LoadinCirculo from '../LoadingCirculo'
import './produtividade.css'

function GraficoProdutividade () {

    const navigate = useNavigate()

    const [dados, setDados] = useState ([])
    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const graficoAtendimentoRealizado = async () => {

            try {
                const resposta = await Produtividade ()
                setCarregando (false)
                setDados (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }

                setGrafico (true)

            } catch (error: any) {
                setCarregando (false)
                
                if (error.status === 401) {
                    return navigate('/');
                }
            }
        }

        graficoAtendimentoRealizado ()

    }, [navigate])


    return (
        <div id = 'graficoProdutividade'>
            <p id = 'p_atendimento'>PRODUTIVIDADE</p>
            {carregando && <LoadinCirculo />}
            {grafico && <ResponsiveContainer>
                <BarChart data={dados} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="período" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip/>
                    <Bar dataKey="produtividade" strokeWidth={1} fill= "#FF6B6C"/>
                </BarChart>
            </ResponsiveContainer>}
            {semDados && <NenhumDadoGrafico texto='Você ainda não' subtexto='finalizou nenhuma tarefa' />}
        </div>
    )
}

export default GraficoProdutividade