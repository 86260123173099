import { useState, FormEvent, useContext, useEffect } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { dadosUsuario, atualizarUsuario } from '../../services/usuarios'
import { telefoneMascara } from '../../helpers'
import { Button, Loader } from '../Styles'
import ModalAlerta from '../ModalAlerta'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import atualizarIcone from '../../assets/img/usuario.png'
import erroImg from '../../assets/img/erroFechar.png'
import '../../styles/modal.css'


function ModalMeuPerfil () {


    const { exibirModalSecundario, atualizarDados } = useContext(ModalEstadoContext)

    const [id, setId] = useState(null)
    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [form, setForm] = useState(true)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState(false)


    const ocultarModal = () => exibirModalSecundario()

    function ocultarModalAlerta () {
        
        setForm(true)
        return setErro(false)
    }

    const atualizarDadosUsuario = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)
        setForm(false)

        if (!id) {
            return
        }

        const payload = {
            nome,
            telefone
        }

        try {

            const resposta = await atualizarUsuario(id, payload)

            if(resposta.status === 200) {

                setProcessando(false)
                
                setConfirmacao(true)
                atualizarDados()
            }
            
        } catch (error: any) {

            setProcessando (false)
            setForm(true)

            return setErro (true)
            
        }
    }

    useEffect(() => {
        
        const usuarioDados = async () => {

            try {

                const resposta = await dadosUsuario()

                if(resposta.status === 200) {

                    const { id, nome, email, telefone } = resposta.data

                    setId(id)
                    setNome(nome)
                    setEmail(email)
                    setTelefone(telefone)
                }
                
            } catch (error) {
                
            }
        }

        usuarioDados ()

    }, [])


    return (
        <div id = 'fundoModal'>
            {confirmacao && <ModalAlerta texto= 'Dados Atualizados com Sucesso' btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModal} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            {form && <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={atualizarIcone} alt='' />
                    <p>Meu Perfil</p>
                </span>
                <form id='formModalNovo' onSubmit={atualizarDadosUsuario}>
                    <section>
                        <span>
                            <label>E-mail</label>
                            <input 
                                type="text" 
                                value={email}
                                disabled/>
                        </span>
                    </section>
                    <section>
                        <span>
                            <label htmlFor="nomeUsuario">Nome</label>
                            <input
                                id='nomeUsuario' 
                                type="text" 
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}/>
                        </span>
                        <span>
                            <label htmlFor="telefoneUsuario">Telefone</label>
                            <input 
                                id='telefoneUsuario'
                                type=""
                                value={telefone}
                                maxLength={18}
                                minLength={18}
                                onChange={(e) => setTelefone(telefoneMascara(e.target.value))} />
                        </span>
                    </section>
                    
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>}
        </div>
    )
}

export default ModalMeuPerfil
