import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalRecuperarSenhaReduce = createSlice ({
    name: 'modal_recuperar_senha',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalRecuperarSenha (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalRecuperarSenha (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalRecuperarSenha, setOcultarModalRecuperarSenha } = modalRecuperarSenhaReduce.actions

export default modalRecuperarSenhaReduce.reducer