const moedaMascara = (valor: any) => {

    if(valor === null || valor === undefined) {
        return 'R$ 0,00'
    }

    valor = valor.toString().replace(/\D/g, '')
    
    valor = Number(valor/100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    })

    return valor

}

export default moedaMascara