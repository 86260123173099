import BASEAPI from "../api/config"

export function clientesTotais () {
    return BASEAPI.get('/dashboard/clientestotais')
}

export function clientesMes () {
    return BASEAPI.get('/dashboard/clientesnovos')
}

export function clientesAtivos () {
    return BASEAPI.get('/dashboard/clientesativos')
}

export function clientesInativos () {
    return BASEAPI.get('/dashboard/clientesinativos')
}

export function clientesCidades () {
    return BASEAPI.get('/dashboard/grafico/clientescidade')
}

export function clientesAtivosMes () {
    return BASEAPI.get('/dashboard/grafico/clientesmes')
}

export function leadFormaConhecimento () {
    return BASEAPI.get('/dashboard/grafico/leads')
}

export function npsOrganizacao () {
    return BASEAPI.get ('/nps/organizacao')
}