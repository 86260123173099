import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import { ModelosProcessosProvider } from "../../contexts/ModelosProcessos"
import ModelosProcessosPage from "../../components/ModelosProcessos"

function ModelosProcessos () {

    useEffect (() => {
        document.title = 'Modelos de Processos | Gesttor'
    }, [])
    
    return (
        <ModelosProcessosProvider>
            <DadosContainerDefault>
                <ModelosProcessosPage />
            </DadosContainerDefault>
        </ModelosProcessosProvider>
    )
}

export default ModelosProcessos
