import { useState } from 'react'
import Perfil from '../../middleware/perfil'
import SubMenuOpcoes from '../SubMenuOpcoes'
import Organizacao from '../../middleware/organizacao'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import '../../styles/menuopcoes.css'

function MenuDocumentosDigitais (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (
        <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                <div>
                    <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                        {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                        <p id = 'tituloMenu'>{props.titulo}</p>
                        <h3>Novo</h3>
                    </div>
                    <div id = 'submenuOpcoes'>
                        {subMenu && <SubMenuOpcoes redirect = {'/contratos'} texto = 'Contratos' />}
                        {subMenu && <SubMenuOpcoes redirect = {'/documentosDigitais'} texto = 'Solicitar Assinaturas' />}
                        <Perfil perfil={['master', 'administrativo']}>
                            <>
                                {subMenu && <SubMenuOpcoes redirect = {'/documentosDigitais?escritorio=true'} texto = 'Todas as Solicitações' />}
                                {subMenu && <SubMenuOpcoes redirect = {'/modelosContratos'} texto = 'Modelos de Contratos' />}
                            </>
                        </Perfil>
                    </div>
                </div>
            </Perfil>
        </Organizacao>
    )
}

export default MenuDocumentosDigitais