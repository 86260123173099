import BASEAPI from '../api/config'
import { cadastrarModelosProcessoPayload, paramsListaModelos, atualizarModeloProcessoPayload } from '../../interfaces/modelosProcessos'


export function novaModeloProcesso (payload: cadastrarModelosProcessoPayload) {
    return BASEAPI.post('/modelosprocessos', payload)
} 

export function listarModelosProcessos (params: paramsListaModelos) {
    return BASEAPI.get (`/modelosprocessos?termo=${params.termo}&situacao=${params.situacao}`)
}

export function atualizarModeloProcesso (idModelo: string, payload: atualizarModeloProcessoPayload) {
    return BASEAPI.patch(`/modelosprocessos?idModeloProcesso=${idModelo}`, payload)
}

export function deletarTarefa (idTarefa: string) {
    return BASEAPI.delete(`/modelosprocessos?idTarefa=${idTarefa}`)
}

