import { propsNotificador } from "../interfaces/propsNotificador"

const notificador = {

    reproduzirAudio () {
        
        const som = new Audio('../assets/audio/notificacao.mp3')

        som.addEventListener('canplaythrough', () => som.play())
    },

    async notificacao (props: propsNotificador) {

        if(!("Notification" in window)) {
            return
        }
        
        const permissao = await Notification.requestPermission()

        if(permissao !== 'granted') {
            
            const concederPermissao = await Notification.requestPermission()

            if(concederPermissao === 'granted') {

                const notificacao = new Notification(props.titulo ?? 'Sistema Gesttor', {
                    body: props.body,
                    icon: 'https://res.cloudinary.com/hgconsultoria/image/upload/v1691858468/favicon_a6pmyq.png'
                })

                notificacao.onshow = () => this.reproduzirAudio()

                return notificacao.onclick = () => {
                    window.open("https://gesttor.co/tarefas")
                }
            }
        }

        const notificacao = new Notification(props.titulo ?? 'Sistema Gesttor', {
            body: props.body,
            icon: 'https://res.cloudinary.com/hgconsultoria/image/upload/v1691858468/favicon_a6pmyq.png'
        })

        notificacao.onshow = () => this.reproduzirAudio()

        return notificacao.onclick = () => {
            window.open("https://gesttor.co/tarefas")
        }
    },
    
    
}

export default notificador