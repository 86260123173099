import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import DocumentoDigital from "../../components/DocumentosDigitais"

function DocumentosDigitais() {

  useEffect(() => {
    document.title = 'Documentos Digitais | Gesttor'
  }, [])

  return (
    <DadosContainerDefault>
      <DocumentoDigital />
    </DadosContainerDefault>
  )
}

export default DocumentosDigitais