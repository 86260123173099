import { useState } from 'react'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import Organizacao from '../../middleware/organizacao'
import Perfil from '../../middleware/perfil'
import SubMenuOpcoes from '../SubMenuOpcoes'

function MenuOpcoesRecrutamento(props: menuOpcoes) {

  const [subMenu, setSubMenu] = useState(false)

  function exibirSubMenu() {
    setSubMenu(subMenu => !subMenu)
  }

  return (
    <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
      <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor']}>
        <div>
          <div id="menuOpcoes" onClick={exibirSubMenu}>
            {<img src={props.icone} alt={props.alt} id="iconeMenu" />}
            <p id='tituloMenu'>{props.titulo}</p>
            <h3>Novo</h3>
          </div>
          <div id="submenuOpcoes">
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                <>
                    {subMenu && <SubMenuOpcoes redirect={'/processos-seletivos'} texto='Todos os Processos' />}
                </>
            </Perfil>
            {subMenu && <SubMenuOpcoes redirect={'/processos-seletivos?meus-processos=true'} texto='Meus Processos' />}
            <Perfil perfil={['master', 'consultor']}>
              <>
                {subMenu && <SubMenuOpcoes redirect={'/questionario-disc'} texto='Questionário Disc' />}
              </>
            </Perfil>
            {subMenu && <SubMenuOpcoes redirect={'/banco-talentos'} texto='Banco de talentos' />}
          </div>
        </div>
      </Perfil>
    </Organizacao>
  )
}

export default MenuOpcoesRecrutamento
