import GraficoProdutividade from '../GraficoProdutividade'
import GraficoNpsAtendimento from '../GraficoNpsAtendimento'
import ResumoTarefas from '../ResumoTarefas'
import UltimosAtendimentos from '../UltimasAtividadesConcluidas'
import './meupainel.css'

function MeuPainel () {
    return (
        <div id = 'meuPainelDados'>
            <ResumoTarefas />
            <UltimosAtendimentos />
            <GraficoProdutividade/>
            <GraficoNpsAtendimento />
        </div>
    )
}

export default MeuPainel