import { useEffect } from 'react'
import { DadosContainerDefault } from "../../components/Styles";
import DadosOrganizacao from "../../components/DadosOrganizacao";

function Organizacao () {

    useEffect (() => {
        document.title = 'Organização | Gesttor'
    }, [])

    return ( 
        <DadosContainerDefault>
            <DadosOrganizacao/>
        </DadosContainerDefault>
    )
}

export default Organizacao
