import { useState, useEffect, useContext, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { documentosListaResposta } from '../../interfaces/documentosDigitais'
import { atualizarDocumento, listarDocumentoDigital } from '../../services/documentosDigitais'
import Perfil from '../../middleware/perfil'
import FiltrosDefault from '../FiltrosDefault'
import avatarDefault from '../../assets/img/avatarDefault.png'
import DivSituacao from '../DivSituacao'
import LoagindBarra from '../LoadingBarra'
import Notificacoes from '../Notificacoes'
import erroImg from '../../assets/img/erroFechar.png'
import visualizarDoc from '../../assets/img/documento.png'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import ModalDocumentosDigitais from '../ModalDocumentosDigitais'
import Organizacao from '../../middleware/organizacao'
import './documentos.css'

function DocumentosDigitais () {

    const { modalEstado, exibirModal, atualizarInformacoes } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname, search } = location
    const paramsUrl = new URLSearchParams(search)

    const data = new Date()

    const ano = data.getFullYear()
    const qtnDias = new Date (data.getFullYear(), data.getMonth() + 1, 0).getDate()
    const dataAnterior = new Date (new Date().setDate(new Date().getDate() - 60))

    const dataInicial = `${dataAnterior.getFullYear()}-${(dataAnterior.getMonth() + 1).toString().padStart(2, '0')}-01`
    const dataFinal = `${ano}-${(data.getMonth() + 1).toString().padStart(2, '0')}-${qtnDias}`
    const escritorio = paramsUrl.get('escritorio') || 'false'

    const [documentos, setDocumentos] = useState<documentosListaResposta[]>([])
    const [documentoSelecionado, setDocumentoSelecionado] = useState<documentosListaResposta>()
    const [modalDocumentos, setModalDocumentos] = useState(false)
    const [maisFiltros, setMaisFiltros] = useState(false)
    const [pagina, setPagina] = useState(2)
    const [cliente, setCliente] = useState('')
    const [dataInicio, setDataInicio] = useState(dataInicial)
    const [dataTermino, setDataTermino] = useState(dataFinal)
    const [situacao, setSituacao] = useState('')
    const [tipo] = useState('documento')
    const [processando, setProcessando] = useState(false)
    const [processandoPesquisa, setProcessandoPesquisa] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [erro, setErro] = useState(false)
    const exibirModalDocumentos = () => exibirModal()

    const scrollInfinito = (e: any) => {

        const { scrollTop, clientHeight, scrollHeight } = e.target

        const posicaoScroll = (scrollTop/(scrollHeight - clientHeight) * 100)

        if (posicaoScroll >= 90) {

            setPagina(prevtState => prevtState + 1)

            const buscarMaisDocumentos = async () => {

                const params = {
                    pagina,
                    nomeCadastro: cliente,
                    situacao,
                    dataInicial: dataInicio,
                    dataFinal: dataTermino,
                    escritorio
                }

                try {

                    const resposta = await listarDocumentoDigital(params)

                    if (resposta.status === 200) {
                        setDocumentos(prevDate => [...prevDate, ...resposta.data])
                        return
                    }
            
                } catch (error) {
                    setErro(true)

                    return setTimeout(() => {
                        setErro(false)
                    }, 2000)
                }
            }

            buscarMaisDocumentos()
        }
    }

    const exibirModalDadosDocumentos = (documento: documentosListaResposta, idDocumento: string) => {

        const escritorio = paramsUrl.get('escritorio')

        if(escritorio) {

            navigate(`${pathname}?escritorio=true&idDocumento=${idDocumento}`)
            exibirModal()

            return setDocumentoSelecionado(documento)
        }

        navigate(`${pathname}?idDocumento=${idDocumento}`)
        exibirModal()

        return setDocumentoSelecionado(documento)
    }

    const buscarDocumentos = async (e: FormEvent) => {

        e.preventDefault()
        setMaisFiltros(false)
        setNenhumResultado(false)
        setProcessandoPesquisa(true)

        const params = {
            pagina: 1,
            nomeCadastro: cliente,
            situacao,
            dataInicial: dataInicio,
            dataFinal: dataTermino,
            escritorio
        }

        try {

            const resposta = await listarDocumentoDigital(params)

            if (resposta.data.length === 0) {
                setProcessandoPesquisa(false)
                setDocumentos(resposta.data)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setDocumentos(resposta.data)
                return setProcessandoPesquisa(false)
            }
            
        } catch (error) {

            setProcessandoPesquisa(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
        }
    }

    const cancelarDocumento = async (idDocumento: string) => {

        setProcessandoPesquisa(true)

        try {

            const payload = {
                situacao: "Cancelado"
            }

            const resposta = await atualizarDocumento(idDocumento, payload)

            if (resposta.status === 200) {
                alert("Documento cancelado!")
                return setProcessandoPesquisa(false)
            }

        } catch (error) {
            setProcessandoPesquisa(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
        }

    }

    useEffect (() => {
        
        setModalDocumentos(modalEstado)

        if(!modalEstado) setDocumentoSelecionado(undefined)

    }, [modalEstado])

    useEffect(() => {

        const buscarDocumento = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            const params = {
                    pagina: 1,
                    nomeCadastro: cliente,
                    situacao,
                    dataInicial: dataInicio,
                    dataFinal: dataTermino,
                    escritorio
                }

            try {

                const resposta = await listarDocumentoDigital(params)

                if (resposta.data.length === 0) {
                    setDocumentos(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {
                    setDocumentos(resposta.data)
                    return setProcessando(false)
                }
                
            } catch (error) {
                
            }
        }

        buscarDocumento ()

    }, [atualizarInformacoes, escritorio])

    return (
        <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                <div id='paginaContainer'>
                    {modalDocumentos && <ModalDocumentosDigitais documento={documentoSelecionado}/>}
                    {processando && <LoagindBarra />}
                    {nenhumResultado && <NenhumResultadoEncontrado />}
                    {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação. Tente Novamente!' cor='erro'/>}
                    <FiltrosDefault termo={cliente} situacao={situacao} placeholder='Pesquise pelo nome fantasia, responsável ou fornecedor' tipo={tipo} onChangeTermo={(e) => setCliente(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={buscarDocumentos} acaoBtn={exibirModalDocumentos} listaSituacao={[{nome: 'Todas', valor: ''}, {nome: 'Aguardando Assinatura', valor: 'Aguardando Assinatura'}, {nome: 'Cancelado', valor: 'Cancelado'}, {nome: 'Assinado', valor: 'Assinado'}]} genero='masculino' isLoading={processandoPesquisa}/>

                    <section id='fundoContainerPagina' onScroll={scrollInfinito}>
                        <table id='tabelaListaContainer'>
                            <thead>
                                <tr>
                                    <th id='ladoEsquerdoContent' align='left'>Cliente</th>
                                    <th id='tituloDocumento' align='left'>Documento</th>
                                    <th align='left'>Solicitado por</th>
                                    <th align='center'>Situação</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {documentos.map((documento) => (
                                    <tr key={Math.random()}>
                                        <td id='primeiraColuna' onClick={() => exibirModalDadosDocumentos(documento, documento.id)}>{documento.cadastro.nomeFantasia}</td>
                                        <td onClick={() => exibirModalDadosDocumentos(documento, documento.id)}>{documento.tituloDocumento}</td>
                                        <td align='center' className='usuarioResponsavelContent' onClick={() => exibirModalDadosDocumentos(documento, documento.id)}>
                                            <img className='usuarioResponsavel' src={documento.usuario.avatar ? documento.usuario.avatar : avatarDefault} alt='' />
                                            {documento.usuario.nome}
                                        </td>
                                        <td align='center' onClick={() => exibirModalDadosDocumentos(documento, documento.id)}>
                                            <DivSituacao texto={`${documento.situacao.charAt(0).toUpperCase().concat()}${documento.situacao.substring(1)}`} cor={documento.situacao.toLowerCase().replace(/\s+/g, '')}/>
                                        </td>
                                        <td id='ladoDireitoContent' align='center'>
                                            <a href={documento.docAssinado ? documento.docAssinado : documento.docOriginal}  target="_blank" rel="noreferrer"><img className='acoesIconeDocumento' title={documento.docAssinado ? 'Visualizar Documento Assinado': 'Visualizar Documento Original'} src={visualizarDoc} alt=''/></a>
                                            <Perfil perfil={['master']}>
                                                <>
                                                    {documento.situacao === "Aguardando Assinatura" && <img className='acoesIconeDocumento' title='Cancelar Documento' src={erroImg} alt='' onClick={()=>{cancelarDocumento(documento.id)}}/>}
                                                </>
                                            </Perfil>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </div>
            </Perfil>
        </Organizacao>
    )
}

export default DocumentosDigitais
