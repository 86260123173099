import { DadosContainerDefault } from "../../components/Styles"
import AvaliacaoDisc from "../../components/AvaliacaoDisc"
import { useEffect } from "react"

function QuestionarioDisc() {

  useEffect(() => {
    document.title = "Questionário Disc | Gesttor"
  }, [])

  return (
    <DadosContainerDefault>
      <AvaliacaoDisc />
    </DadosContainerDefault>
  )
}

export default QuestionarioDisc