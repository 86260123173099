import { FormEvent, useContext, useEffect, useState } from 'react'
import adicionarIcon from '../../assets/img/botao-adicionar.png'
import editarIcon from '../../assets/img/editar.png'
import erroImg from '../../assets/img/erroFechar.png'
import { adicionarPergunta, atualizarPergunta, listarPerguntas } from '../../services/BancoTalentos'
import { listaPerguntaPayload } from '../../interfaces/BancoTalentos'
import "./modalAvaliacao.css"
import { Button, Loader } from '../Styles'
import { criptografarDado } from '../../helpers'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import Organizacao from '../../middleware/organizacao'
import Perfil from '../../middleware/perfil'
import LoadingBarra from '../LoadingBarra'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import Notificacoes from '../Notificacoes'
import DivSituacao from '../DivSituacao'
import FiltrosDefault from '../FiltrosDefault'

function AvaliacaoDisc() {

  const { modalExibir, modal } = useContext(ModalEstadoContext)

  const [processando, setProcessando] = useState(false)
  const [nenhumResultado, setNenhumResultado] = useState(false)
  const [situacaoPerfil, setSituacaoPerfil] = useState("")

  const [termo, setTermo] = useState("")
  const [perguntas, setPerguntas] = useState<listaPerguntaPayload[]>([])
  const [perguntasFiltradas, setPerguntasFiltradas] = useState<listaPerguntaPayload[]>([])
  const [pergunta, setPergunta] = useState("")
  const [a, setA] = useState("");
  const [b, setB] = useState("");
  const [c, setC] = useState("");
  const [d, setD] = useState("");
  const [situacao, setSituacao] = useState("");

  const [alternativaSelecionada, setAlternativaSelecionada] = useState<listaPerguntaPayload>()

  const listarPerguntasAvaliacao = async () => {
    setProcessando(true)
    try {
      const perguntas = await listarPerguntas()
      if (!perguntas) return setNenhumResultado(true)
      setPerguntas(perguntas.data)
      setPerguntasFiltradas(perguntas.data)
      setProcessando(false)
    } catch (error) {
      setProcessando(false)
      modalExibir("erroListarPergunta")
      return setTimeout(() => {
        modalExibir("")
      }, 5000)
    }
  }

  const filtrarPerguntas = (e?: FormEvent) => {
    e?.preventDefault();

    const dadosFiltrados = perguntas.filter(pergunta => {
      const filtroSituacao = situacaoPerfil === '' || pergunta.situacao.toString() === situacaoPerfil;
      const filtroTermo = termo === '' || pergunta.pergunta.includes(termo);
      return filtroSituacao && filtroTermo;
    });

    setPerguntasFiltradas(dadosFiltrados);
  }

  const abrirModalEditarPergunta = (alternativa: listaPerguntaPayload) => {
    setAlternativaSelecionada(alternativa)
    setPergunta(alternativa.pergunta)
    modalExibir("editarPerguntaDisc")
  }

  const abrirModalAdicionarPergunta = () => {
    modalExibir("adicionarPerguntaDisc")
    setAlternativaSelecionada(undefined)
  }

  const handleUpdate = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    try {
      if (!alternativaSelecionada) {
        modalExibir("erroAlternativa")
        return setTimeout(() => {
          modalExibir("")
        }, 5000)
      }

      const idPergunta = alternativaSelecionada.id
      const idAlternativa = alternativaSelecionada.discAlternativas[0].id

      const idPerguntaCriptografado = criptografarDado(idPergunta.toString())
      const idAlternativaCriptografado = criptografarDado(idAlternativa.toString())
      const payload = {
        pergunta,
        situacao,
        a, b, c, d
      }
      const payloadFiltrado = filtroPayload(payload)

      await atualizarPergunta(idPerguntaCriptografado, idAlternativaCriptografado, payloadFiltrado)
      setProcessando(false)
      window.location.reload()
    } catch (error) {
      setProcessando(false)
      modalExibir("erroAtualizarPergunta")
      return setTimeout(() => {
        modalExibir("")
      }, 5000)
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    try {
      const payload = {
        pergunta,
        alternativas: { a, b, c, d }
      }

      const perguntaAtualizada = await adicionarPergunta(payload)
      if (perguntaAtualizada.status === 201) return window.location.reload()
      setProcessando(false)
    } catch (error) {
      modalExibir("erroAdicionarPergunta")
      setProcessando(false)
      return setTimeout(() => {
        modalExibir("")
      }, 5000)

    }
  }

  const filtroPayload = (payload: any) => {
    return Object.fromEntries(
      Object.entries(payload).filter(
        ([_, valor]) => valor !== null && valor !== undefined && valor !== ""
      )
    )
  }

  useEffect(() => {
    listarPerguntasAvaliacao()
  }, [])

  return (
    <section>
      <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
        <Perfil perfil={['master', 'recrutador']}>
          <div id='paginaContainer'>
            <FiltrosDefault termo={termo} situacao={situacaoPerfil} placeholder='Pesquise pela pergunta' tipo={"Pergunta"} onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacaoPerfil(e.target.value)} onSubmit={(e) => filtrarPerguntas(e)} acaoBtn={() => { abrirModalAdicionarPergunta() }} listaSituacao={[{ nome: 'Todos', valor: '' }, { nome: 'Oculto', valor: 'false' }, { nome: 'Visivel', valor: 'true' }]} genero='feminino' isLoading={processando} />
            {processando && <LoadingBarra />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {modal.nome === "erroAdicionarPergunta" && <Notificacoes icone={erroImg} texto='Erro ao Criar uma Nova Pergunta. Tente Novamente!' cor='erro' />}
            {modal.nome === "erroAtualizarPergunta" && <Notificacoes icone={erroImg} texto='Erro ao Atualizar uma Pergunta. Tente Novamente!' cor='erro' />}
            {modal.nome === "erroListarPergunta" && <Notificacoes icone={erroImg} texto='Não é possível Listar as Perguntas' cor='erro' />}
            <section>
              <table id="tabelaListaContainer">
                <thead>
                  <tr>
                    <th align='center' className='hide-responsive'>Pergunta</th>
                    <th align='center' className='hide-responsive'>Dominante</th>
                    <th align='center' className='hide-responsive'>Influente</th>
                    <th align='center' className='hide-responsive'>Estável</th>
                    <th align='center' className='hide-responsive'>Condescendente</th>
                    <th align='center' className='hide-responsive'>Situação</th>
                  </tr>
                </thead>
                <tbody>
                  {perguntasFiltradas.map((alternativa) => (
                    <tr key={alternativa.id} onClick={() => abrirModalEditarPergunta(alternativa)}>
                      <td align='center' className='primeiraColuna'>{alternativa.pergunta}</td>
                      <td align='center' className='hide-responsive'>{alternativa.discAlternativas[0].a}</td>
                      <td align='center' className='hide-responsive'>{alternativa.discAlternativas[0].b}</td>
                      <td align='center' className='hide-responsive'>{alternativa.discAlternativas[0].c}</td>
                      <td align='center' className='hide-responsive'>{alternativa.discAlternativas[0].d}</td>
                      <td align='center'>
                        <DivSituacao texto={alternativa.situacao ? "Visivel" : "Oculto"} cor={alternativa.situacao ? "ativo" : "cancelado"} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </div>
        </Perfil>
      </Organizacao>
      {modal.nome === "editarPerguntaDisc" &&
        <div className='fundoModal'>
          <section className='containerModalCentro'>
            <span className="novoContainerTitulo">
              <h1>Avaliação Disc</h1>
              <img src={adicionarIcon} className='adicionarModalIcone' alt='' onClick={() => modalExibir("adicionarPerguntaDisc")} />
            </span>
            <form className='formModalNovo' onSubmit={handleUpdate}>
              <section>
                <span>
                  <label htmlFor="">Pergunta</label>
                  <textarea rows={3} className='areaTexto' onChange={(e: any) => setPergunta(e.target.value)} required>{pergunta}</textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Dominante</label>
                  <textarea rows={3} className='areaTexto' onChange={(e: any) => setA(e.target.value)} required>{alternativaSelecionada?.discAlternativas[0].a}</textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Influente</label>
                  <textarea rows={3} className='areaTexto' onChange={(e: any) => setB(e.target.value)} required>{alternativaSelecionada?.discAlternativas[0].b}</textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Estável</label>
                  <textarea rows={3} className='areaTexto' onChange={(e: any) => setC(e.target.value)} required>{alternativaSelecionada?.discAlternativas[0].c}</textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Condescendente</label>
                  <textarea rows={4} className='areaTexto' onChange={(e: any) => setD(e.target.value)} required>{alternativaSelecionada?.discAlternativas[0].d}</textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Tornar Pergunta Visivel?</label>
                  <select defaultValue={alternativaSelecionada?.situacao ? "true" : "false"} onChange={(e) => setSituacao(e.target.value)}>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </span>
              </section>
              <span className="botoesContainer">
                <Button type='button' cor='cancelar' isLoading={false} onClick={() => modalExibir("")}>Voltar</Button>
                <Button type='submit' cor='enviar' isLoading={false}>Salvar</Button>
              </span>
            </form>
          </section>
        </div>
      }
      {modal.nome === "adicionarPerguntaDisc" &&
        <div className='fundoModal'>
          <section className='containerModalCentro'>
            <span className="novoContainerTitulo">
              <h1>Avaliação Disc</h1>
            </span>
            <form className='formModalNovo' onSubmit={handleSubmit}>
              <section>
                <span>
                  <label htmlFor="">Pergunta</label>
                  <textarea rows={4} className='areaTexto' onChange={(e: any) => setPergunta(e.target.value)} placeholder='Insira uma nova pergunta' required></textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Dominante</label>
                  <textarea rows={4} className='areaTexto' onChange={(e: any) => setA(e.target.value)} placeholder='Insira uma alternativa para Dominante' required></textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Influente</label>
                  <textarea rows={4} className='areaTexto' onChange={(e: any) => setB(e.target.value)} placeholder='Insira uma alternativa para Influente' required></textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Estável</label>
                  <textarea rows={4} className='areaTexto' onChange={(e: any) => setC(e.target.value)} placeholder='Insira uma alternativa para Estável' required></textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Condescendente</label>
                  <textarea rows={4} className='areaTexto' onChange={(e: any) => setD(e.target.value)} placeholder='Insira uma alternativa para Condescendente' required></textarea>
                </span>
              </section>
              <span className="botoesContainer">
                <Button type='button' cor='cancelar' isLoading={false} onClick={() => modalExibir("")}>Voltar</Button>
                <Button type='submit' cor='enviar' isLoading={false}>Salvar</Button>
              </span>
            </form>
          </section>
        </div>
      }
    </section>
  )
}

export default AvaliacaoDisc