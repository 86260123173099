import { useState, FormEvent, useEffect, ChangeEvent, useContext } from 'react';
import { atualizarOrganizacao, dadosOrganizacao, atualizarAvatarOrganizacao } from '../../services/organizacao';
import { ModalEstadoContext } from '../../contexts/ModalEstado';
import { telefoneMascara, cepMascara } from '../../helpers';
import { organizacaoDados } from '../../interfaces/organizacao';
import { Button, Loader } from '../Styles';
import ModalContratarSistema from '../ModalContratarSistema';
import Perfil from '../../middleware/perfil';
import avatarDefaultImg from '../../assets/img/avatarDefault.png';
import LoagindBarra from '../LoadingBarra';
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import ModalAlerta from '../ModalAlerta';
import './dadosorganizacao.css';


const DadosOrganizacao = () => {

    const { exibirModal, modalEstado, atualizarInformacoes, atualizarDados } = useContext(ModalEstadoContext)

    const [organizacao, setOrganizacao] = useState<organizacaoDados>()
    const [cnpjCpf, setCnpjCpf] = useState('')
    const [nomeFantasia, setNomeFantasia] = useState<string | null>(null)
    const [razaoSocial, setRazaoSocial] = useState<string | null>(null)
    const [email, setEmail] = useState<string | null>(null)
    const [telefone, setTelefone] = useState<string | null>(null)
    const [rua, setRua] = useState<string | null>(null)
    const [numero, setNumero] = useState<string | null>(null)
    const [bairro, setBairro] = useState<string | null>(null)
    const [complemento, setComplemento] = useState<string | null>(null)
    const [cep, setCep] = useState<string | null>(null)
    const [cidade, setCidade] = useState<string | null>(null)
    const [estado, setEstado] = useState<string | null>(null)
    const [avatar, setAvatar] = useState<File>()
    const [site, setSite] = useState<string| null>(null)
    const [modalContratar, setModalContrar] = useState(false)
    const [processando, setProcessando] = useState(false)
    const [confirmacao, setConfirmacao] = useState(false)
    const [erro, setErro] = useState(false)

    const ocultarModalAlerta = () => {
        
        setErro(false)
        setConfirmacao(false)

        return
    }

    const maisUsuario = () => {
        return alert('Acesse o Menu Suporte > Solicitar Atendimento. Informa na descrição da solicitação a quantidade de usuários adicionais que deseja. Esteja ciente que ao ser concluído o atendimento, sua assinatura será atualizada aumentando em R$ 9,90 por cada usuário adicional')
    }

    const exibirModalContratarSistema = () => exibirModal()

    const changeTelefone = (e: ChangeEvent<HTMLInputElement>) => {

        return setTelefone(telefoneMascara(e.target.value))
    }

    const changeCep = (e: ChangeEvent<HTMLInputElement>) => {

        return setCep(cepMascara(e.target.value))
    }

    const avatarFile = (e: any) => {

        const arquivo = e.target.files[0]

        if(arquivo) {
            const extensaoPermitida = ['.png', '.jpeg', '.jpg']
            const extensaoArquivo = arquivo.name.split('.').pop().toLowerCase()

            if(extensaoPermitida.includes(`.${extensaoArquivo}`)) {
                return setAvatar(arquivo)
            } else {
                return alert('Tipo de Arquivo não Permitido. Escolha uma imagem')
            }
        } else {
            setAvatar(undefined)
        }
    }

    const atualizarDadosOrganizacao = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const payload = {
            nomeFantasia,
            razaoSocial,
            email,
            telefone,
            site,
            rua,
            numero,
            bairro,
            complemento,
            cep,
            cidade,
            estado
        }

        try {
            const resposta = await atualizarOrganizacao(payload)

            if (resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
            }
        } catch (error: any) {
            setProcessando(false)
            return setErro(true)
        }
    }

    const atualizarAvatar = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(avatar === undefined) {
            return setProcessando(false)
        }

        const payload = {
            avatar
        }

        try {

            const resposta = await atualizarAvatarOrganizacao(payload)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDados()
                setConfirmacao(true)
            }
            
        } catch (error) {

            setProcessando(false)
            return setErro(true)
            
        }
    }

    useEffect(() => {
        setModalContrar(modalEstado)
    }, [modalEstado])

    useEffect(() => {

        const fetchOrganizacao = async () => {

            setProcessando(true)
            
            try {

                const resposta = await dadosOrganizacao()

                if (resposta.status === 200){
                    
                    const { data } = resposta

                    setCnpjCpf(data.cnpjCpf)
                    setNomeFantasia(data.nomeFantasia)
                    setRazaoSocial(data.razaoSocial)
                    setEmail(data.email)
                    setSite(data.site)
                    setTelefone(data.telefone)
                    setRua(data.rua)
                    setNumero(data.numero)
                    setBairro(data.bairro)
                    setComplemento(data.complemento)
                    setCep(data.cep)
                    setCidade(data.cidade)
                    setEstado(data.estado)

                    setOrganizacao(data)
                }
                
            } catch (error) {

                console.error('Ocorreu um erro ao buscar a organização:', error)

            } finally {
                setProcessando(false)
            }
        }

        fetchOrganizacao()

    }, [atualizarInformacoes])

    return (
        <Perfil perfil={['master', 'administrativo']}>
            <div id="dadosOrganizacaoContainer">
                {modalContratar && <ModalContratarSistema organizacao={organizacao}/>}
                {processando && <LoagindBarra />}
                {confirmacao && <ModalAlerta icone={confirmacaoImg} texto='Dados atualizados com sucesso!' btnTitulo='Continuar' cor='continuarSucesso' acao={ocultarModalAlerta} />}
                {erro && <ModalAlerta icone={erroImg} texto='Fallha ao processar a solicitação. Gentileza tentar novamente!' btnTitulo='Continuar' cor='continuarErro' acao={ocultarModalAlerta}/>}
                <section id='sectionDadosOrganizacaoHeard'>
                    {organizacao?.sistemaContratado === true && <span>
                        <p>Você tem <b>{organizacao.usuariosAdicionais?.toString().padStart(2, '0')}</b> usuários adicionais contratados</p>
                        <p>Sua Licença expira em <b>{organizacao.validadeLicenca.slice(8,10)}-{organizacao.validadeLicenca.slice(5,7)}-{organizacao.validadeLicenca.slice(0,4)}</b></p>
                        <Button type='button' cor='solicitarUsuario' isLoading={processando} onClick={maisUsuario}>Solicitar Mais Usuários</Button>
                    </span>}
                    {organizacao?.sistemaContratado === false && <span>
                        <p>Seu período de teste encerra em <b>{organizacao.validadeLicenca.slice(8,10)}/{organizacao.validadeLicenca.slice(5,7)}/{organizacao.validadeLicenca.slice(0,4)}</b></p>
                        <Button type='button' cor='solicitarUsuario' isLoading={processando} onClick={exibirModalContratarSistema}>Contratar Agora</Button>
                    </span>}
                    <span>
                        <img id="avatarUsuarioGesttor" src={avatarDefaultImg} alt="" />
                    </span>
                </section>
                <section className='containerDadosOrhanizacao'>
                    <span className='avatarOrganizacao'>
                        {organizacao?.avatar ? <img src={organizacao.avatar} alt='Logo da Empresa'/> : null}
                        <form onSubmit={atualizarAvatar}>
                            <div className='formAtualizarAvatar'>
                                <label htmlFor="avatarAtualizarOrganizacao">Atualizar Avatar</label>
                                <input
                                    type='file'
                                    id='avatarAtualizarOrganizacao'
                                    accept='.png, .jpeg, .jpg'
                                    onChange={(e) => avatarFile(e)}
                                    required
                                />
                                <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Salvar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>
                            </div>
                        </form>
                    </span>
                    <form id='formDadosOrganizacao' onSubmit={atualizarDadosOrganizacao}>
                        <div id='dadosOrganizacao'>
                            <section>
                                <span>
                                    <label htmlFor="">CNPJ</label>
                                    <input
                                        type="text"
                                        value={cnpjCpf}
                                        disabled
                                    />
                                </span>
                            </section>
                            
                            <section>
                                <span>
                                    <label htmlFor="nomeFantasiaOrganizacao">Nome Fantasia</label>
                                    <input
                                        type='text'
                                        id='nomeFantasiaOrganizacao'
                                        value={nomeFantasia === null ? '': nomeFantasia}
                                        onChange={(e) => setNomeFantasia(e.target.value)}
                                    />
                                </span>
                                <span>
                                    <label htmlFor='razaoSocialOrganizacao'>Razão Social</label>
                                    <input
                                        type="text"
                                        id='razaoSocialOrganizacao'
                                        value={razaoSocial === null ? '' : razaoSocial}
                                        onChange={(e) => setRazaoSocial(e.target.value)}
                                    />
                                </span>
                            </section>
            
                            <section>
                                <span>
                                    <label htmlFor='telefoneOrganizacao'>Telefone</label>
                                    <input
                                        type="text"
                                        id='telefoneOrganizacao'
                                        placeholder="(00) 0.0000 - 0000"
                                        minLength={18}
                                        maxLength={18}
                                        value={telefone === null ? '' : telefone}
                                        onChange={(e) => changeTelefone(e)}
                                    />
                                </span>
                                <span>
                                    <label htmlFor='emailOrganizacao'>E-mail</label>
                                    <input
                                        type="email"
                                        id='emailOrganizacao'
                                        value={email === null ? '' : email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </span>

                                <span>
                                    <label htmlFor='siteOrganizacao'>Site</label>
                                    <input
                                        type="text"
                                        id='siteOrganizacao'
                                        value={site === null ? '' : site}
                                        onChange={(e) => setSite(e.target.value)}
                                    />
                                </span>
                            </section>

                            <section>
                                <span>
                                    <label htmlFor='ruaOrganizacao'>Rua</label>
                                    <input
                                        type="text"
                                        id='ruaOrganizacao'
                                        value={rua === null ? '' : rua}
                                        onChange={(e) => setRua(e.target.value)}
                                    />
                                </span>
                                <span>
                                    <label htmlFor='numeroOrganizacao'>Número</label>
                                    <input
                                        type="text"
                                        id='numeroOrganizacao'
                                        value={numero === null ? '' : numero}
                                        onChange={(e) => setNumero(e.target.value)}
                                    />
                                </span>
                                <span>
                                    <label htmlFor='bairroOrganizacao'>Bairro</label>
                                    <input
                                        type="text"
                                        id='bairroOrganizacao'
                                        value={bairro === null ? '' : bairro}
                                        onChange={(e) => setBairro(e.target.value)}
                                    />
                                </span>
                                <span >
                                    <label htmlFor='complementoOrganizacao'>Complemento</label>
                                    <input
                                        type="text"
                                        id='complementoOrganizacao'
                                        value={complemento === null ? '' : complemento}
                                        onChange={(e) => setComplemento(e.target.value)}
                                    />
                                </span>
                            </section>

                            <section>
                                <span>
                                    <label htmlFor='cepOrganizacao'>CEP</label>
                                    <input
                                        type="text"
                                        id='cepOrganizacao'
                                        placeholder='0000 - 000'
                                        maxLength={11}
                                        minLength={11}
                                        value={cep === null ? '' : cep}
                                        onChange={(e) => changeCep(e)}
                                    />
                                </span>
                                <span>
                                    <label htmlFor='cidadeOrganizacao'>Cidade</label>
                                    <input
                                        type="text"
                                        id='cidadeOrganizacao'
                                        value={cidade === null ? '' : cidade}
                                        onChange={(e) => setCidade(e.target.value)}
                                    />
                                </span>
                                <span>
                                    <label htmlFor='estadosOrganizacao'>Estado</label>
                                    <select name="estadosOrganizacao" id="estadosOrganizacao" value={estado === null ? '' : estado} onChange={(e) => setEstado(e.target.value)}>
                                        <option value = 'acre'>Acre</option>
                                        <option value = 'alagoas'>Alagoas</option>
                                        <option value = 'amapá'>Amapá</option>
                                        <option value = 'amazonas'>Amazonas</option>
                                        <option value = 'bahia'>Bahia</option>
                                        <option value = 'ceará'>Ceará</option>
                                        <option value = 'espírito santo'>Espírito Santo</option>
                                        <option value = 'goiás'>Goiás</option>
                                        <option value = 'maranhão'>Maranhão</option>
                                        <option value = 'mato grosso'>Mato Grosso</option>
                                        <option value = 'mato grosso do sul'>Mato Grosso do Sul</option>
                                        <option value = 'minas gerais'>Minas Gerais</option>
                                        <option value = 'pará'>Pará</option>
                                        <option value = 'paraíba'>Paraíba</option>
                                        <option value = 'paraná'>Paraná</option>
                                        <option value = 'pernanbuco'>Pernambuco</option>
                                        <option value = 'piauí'>Piauí</option>
                                        <option value = 'rio de janeiro'>Rio de Janeiro</option>
                                        <option value = 'rio grande do norte'>Rio Grande do Norte</option>
                                        <option value = 'rio grande do sul'>Rio Grande do Sul</option>
                                        <option value = 'rondônica'>Rondônia</option>
                                        <option value = 'roraima'>Roraima</option>
                                        <option value = 'santa catarina'>Santana Catarina</option>
                                        <option value = 'são paulo'>São Paulo</option>
                                        <option value = 'sergipe'>Sergipe</option>
                                        <option value = 'tocantins'>Tocantins</option>
                                    </select>
                                </span>
                            </section>
                            <span id='btnSalvarDadosOrganizacao'>
                                <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Salvar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>
                            </span>
                        </div>
                    </form>
                </section>
            </div>
        </Perfil>
    );
};

export default DadosOrganizacao;