import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles"
import Integracoes from "../../components/Integracoes"

function IntegracoesPage () {

    useEffect (() => {
        document.title = 'Integrações | Gesttor'
    }, [])


    return (
        <DadosContainerDefault>
            <Integracoes />
        </DadosContainerDefault>
    )
}

export default IntegracoesPage