import { useEffect, useState, useContext } from 'react'
import { statusIntegracao } from '../../services/inter'
import { statusIntegracaoResposta } from '../../interfaces/inter'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { dadosWa, apagarSessaoWa } from '../../services/wa'
import { dadosIntegracaoWaResposta } from '../../interfaces/wa'
import ModalIntegracaoInter from '../ModalIntegracaoInter'
import ModalQrCodeWhatsApp from '../ModalQrCodeWhatsApp'
import Notificacoes from '../Notificacoes'
import LoagindBarra from '../LoadingBarra'
import Perfil from '../../middleware/perfil'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import DivSituacao from '../DivSituacao'
import bancoInteterImg from '../../assets/img/bancoInter.png'
import whatsAppImg from '../../assets/img/whatsappCobranca.png'
import cancelarImg from '../../assets/img/erroFechar.png'
import qrCodeImg from '../../assets/img/qrCodeImagem.png'
import './integracoes.css'

function Integracoes () {

    const { modalEstado, exibirModal, atualizarInformacoes } = useContext(ModalEstadoContext)

    const { modal , modalExibir } = useContext(ModalEstadoContext)

    const [dadosIntegracao, setDadosIntegracao] = useState<statusIntegracaoResposta>()
    const [dadosIntegracaoWa, setDadosIntegracaoWa] = useState<dadosIntegracaoWaResposta>()
    const [modalInter, setModalInter] = useState(false)
    const [processando, setProcessando] = useState(false)
    const [sucessoApagarWa, setSucessoApagarWa] = useState(false)
    const [erro, setErro] = useState(false)


    const exibirModaltarefas = () => exibirModal()

    const exibirModalQrCodeWhatsApp = () => modalExibir('qrCodeWa')


    const finalizarSessao = async () => {

        try {

            const resposta = await apagarSessaoWa()

            if(resposta.status === 200) {
                return setSucessoApagarWa(true)
            }
            
        } catch (error) {

            setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 2000)
            
        }
    }


    useEffect (() => {
        
        setModalInter(modalEstado)

    }, [modalEstado])

    useEffect(() => {
        
        const buscarDadosIntegracaoWa = async () => {

            setProcessando(true)

            try {

                const resposta = await dadosWa()

                if(resposta.status === 200) {
                    setDadosIntegracaoWa(resposta.data)
                    setProcessando(false)
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 2000)
                
            }
        }

        buscarDadosIntegracaoWa()

    }, [atualizarInformacoes])

    useEffect(() => {

        const buscarDadosIntegracao = async () => {

            setProcessando(true)

            try {

                const resposta = await statusIntegracao()

                if(resposta.status === 200) {
                    setDadosIntegracao(resposta.data)
                    setProcessando(false)
                }
                
            } catch (error) {
                
                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 2000)
            }
        }

        buscarDadosIntegracao()

    }, [atualizarInformacoes])

    
    return (
        <Perfil perfil={['master', 'administrativo']}>
            <div id='paginaIntegracoes'>
                {sucessoApagarWa && <Notificacoes icone={confirmacaoImg} texto='WhatsApp Desconectado com Sucesso' cor='sucesso' />}
                {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação. Tente Novamente' cor='erro' />}
                {modalInter && <ModalIntegracaoInter dadosIntegracaoInter={dadosIntegracao}/>}
                {modal.nome === 'qrCodeWa' && <ModalQrCodeWhatsApp/>}
                {processando && <LoagindBarra />}
                <section className='tituloIntegracaoContainer'>
                    <h1>Integrações Disponíveis</h1>
                </section>
                <div className = 'integracoesContainer'>
                    <section className='cardIntegracoesContainer' onClick={exibirModaltarefas}>
                        <span className='cardIntegracao'>
                            <img className='cardIntegracaoIcone' src={bancoInteterImg} alt='' />
                            <p>Gere cobranças automaticamente com o Banco Inter</p>
                            {dadosIntegracao?.validade && <p>Validade: <b>{dadosIntegracao?.validade?.slice(8,10)}/{dadosIntegracao.validade?.slice(5,7)}/{dadosIntegracao.validade?.slice(0,4)}</b></p>}
                            {dadosIntegracao && <span className='situacaoIntegracao'>
                                <DivSituacao texto={dadosIntegracao.situacao.charAt(0).toUpperCase() + dadosIntegracao.situacao.substring(1)} cor={dadosIntegracao.situacao?.toLowerCase()}/>
                            </span>}
                        </span>
                    </section>
                    <section className='cardIntegracoesContainer'>
                        <span className='cardIntegracao'>
                            <img className='cardIntegracaoIcone' src={whatsAppImg} alt='' />
                            <p>Conecte seu WhatsApp e envie cobranças e avisos automáticos</p>
                            {dadosIntegracaoWa && <span className='situacaoIntegracao'>
                                <DivSituacao texto={dadosIntegracao !== null ? dadosIntegracaoWa.situacao : ''} cor={dadosIntegracaoWa.situacao.toLowerCase()}/>
                            </span>}
                            <div className='contentOpcoesIntegracoesWhatsApp'>
                                {dadosIntegracaoWa?.situacao === 'Desconectado' && <img className='qrCodeIntegracaoWhatsApp' src={qrCodeImg} alt='' title='Gerar QrCode' onClick={() => exibirModalQrCodeWhatsApp()}/>}
                                {dadosIntegracaoWa?.situacao === 'Conectado' && <img className='desativarIntegracaoWhatsApp' src={cancelarImg} alt='' title='Desativar Integração' onClick={finalizarSessao}/>}
                            </div>
                        </span>
                    </section>
                </div>
            </div>
        </Perfil>
    )
}

export default Integracoes