import { Link } from 'react-router-dom'
import SubmenuOpcoes from '../../interfaces/propsSubMenuOpcoes'
import '../../styles/submenuopcoes.css'

function SubMenuOpcoes (props: SubmenuOpcoes) {
    return (
        <div id = 'submenuOpcoes'>
            <span>
                <Link id='submenuLink' to = {props.redirect} onClick={props.acao}>
                    {props.texto}
                </Link>
                <h5>{props.notificacao}</h5>
            </span>
        </div>
    )
}

export default SubMenuOpcoes