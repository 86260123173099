import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import DashboardFinanceiroPage from "../../components/DashboardFinanceiro"

function DashboardFinanceiro () {

    useEffect (() => {
        document.title = 'Dashboard Financeiro | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <DashboardFinanceiroPage />
        </DadosContainerDefault>
    )
}

export default DashboardFinanceiro
