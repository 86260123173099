import BASEAPI from '../api/config'
import BASEAPIFORDATA from '../api/formData'

import { cadastrarTarefasPayload, paramsListaTarefas, atualizartarefasPayload, anexarDocumentoPayload, cadastrarComentarioPayload } from '../../interfaces/tasks'


// SERVICES PARA O CRUD DE TAREFAS

export function novaTarefa (payload: cadastrarTarefasPayload) {
    return BASEAPI.post('/tarefas', payload)
} 

export function listarTarefas (params: paramsListaTarefas) {
    return BASEAPI.get (`/tarefas?pagina=${params.pagina}&titulo=${params.titulo}&descricao=${params.descricao}&cliente=${params.cliente}&dataInicio=${params.dataInicio}&dataTermino=${params.dataTermino}&minhasSolicitacoes=${params.minhasSolicitacoes}&tarefasEscritorio=${params.tarefasEscritorio}&tarefasAutomaticas=${params.tarefasAutomaticas}&situacao=${params.situacao}&responsavel=${params.responsavel}`)
}


export function atualizarTarefa (idTarefa: string, payload: atualizartarefasPayload) {
    return BASEAPI.patch(`/tarefas?idTarefa=${idTarefa}`, payload)
}

export function deletarTarefa (idTarefa: string) {
    return BASEAPI.delete(`/tarefas?idTarefa=${idTarefa}`)
}

// SERVICES PARA O CRUD DE ANEXOS

export function anexarArquivo (idTarefa: string, payload: anexarDocumentoPayload) {
    return BASEAPIFORDATA.post(`/tarefas/anexos?idTarefa=${idTarefa}`, payload)
}

export function buscarAnexos (idTarefa: string) {
    return BASEAPI.get(`/tarefas/anexos?idTarefa=${idTarefa}`)
}

export function deletarAnexo (idAnexo: string) {
    return BASEAPI.delete(`/tarefas/anexos?idAnexo=${idAnexo}`)
}

// ROTAS PARA O CRUD DE COMENTÁRIOS

export function postarComentario (idTarefa: string, payload: cadastrarComentarioPayload) {
    return BASEAPI.post(`/tarefas/comentarios?idTarefa=${idTarefa}`, payload)
}

export function listarComentarios (idTarefa: string) {
    return BASEAPI.get(`/tarefas/comentarios?idTarefa=${idTarefa}`)
}

