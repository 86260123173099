import './loadingBarra.css'

function LoadingBarra () {
    return (
        <div className = 'loadingBarraContainer'>
            <span className='loadingBarra'/>
        </div>
    )
}

export default LoadingBarra