import propsNotificacoes from '../../interfaces/propsNotificacoes'
import './notificacoesusuarios.css'

function Notificacoes (props: propsNotificacoes) {

    return (
        <div className = {`notificacoesContainer ${props.cor}`}>
            <section className={`notificacoesContent ${props.cor}Content`}>
                <span>
                    <img src={props.icone} alt=''/>
                </span>
                <span>
                    <p><b>{props.titulo}</b>{props.texto}</p>
                </span>
            </section>
        </div>
    )
}

export default Notificacoes