import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const alertaReduce = createSlice ({
    name: 'alertas',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirAlerta (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },
        setOcultarAlerta (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirAlerta, setOcultarAlerta, } = alertaReduce.actions

export default alertaReduce.reducer