import { useState, useEffect, FormEvent, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { novoCertificado, novaChave, novoDadosInter, habilitarWebHook, gerarTokenInter } from '../../services/inter'
import { listarContas } from '../../services/contas'
import { dadosIntegracao } from '../../interfaces/inter'
import { contasListaResposta } from '../../interfaces/contas'
import { Button, Loader } from '../Styles'
import integracaoImg from '../../assets/img/integracao.png'
import ModalAlerta from '../ModalAlerta'
import erroImg from '../../assets/img/erroFechar.png'
import DivSituacao from '../DivSituacao'
import './modalintergracao.css'
import '../../styles/modal.css'


function ModalIntegracaoInter ({ dadosIntegracaoInter }: dadosIntegracao) {

    const { exibirModal } = useContext(ModalEstadoContext)


    const [contasBancarias, setContasBancarias] = useState<contasListaResposta[]>([])
    const [contaPadrao, setContaPadrao] = useState(0)
    const [interClient, setInterClient] = useState('')
    const [interSecrety, setInterSecrety] = useState('')
    const [validade, setValidade] = useState('')

    const [certificado, setCertificado] = useState<File>()
    const [chave, setChave] = useState<File>()

    const [processando, setProcessando] = useState (false)
    const [erro, setErro] = useState(false)

    function ocultarModal () {

        exibirModal()
    }

    const certificadoFile = (e: any) => {

        const arquivo = e.target.files[0]

        if(arquivo) {
            const extensaoPermitida = ['.crt']
            const extensaoArquivo = arquivo.name.split('.').pop().toLowerCase()

            if(extensaoPermitida.includes(`.${extensaoArquivo}`)) {
                return setCertificado(arquivo)
            } else {
                return alert('Tipo de Certificado Inválido')
            }
        } else {
            setCertificado(undefined)
        }
    }

    const chaveFile = (e: any) => {

        const arquivo = e.target.files[0]

        if(arquivo) {
            const extensaoPermitida = ['.key']
            const extensaoArquivo = arquivo.name.split('.').pop().toLowerCase()

            if(extensaoPermitida.includes(`.${extensaoArquivo}`)) {
                return setChave(arquivo)
            } else {
                return alert('Tipo de Chave Inválida')
            }
        } else {
            setChave(undefined)
        }
    }

    
    const cadastrarDadosInter = async (e: FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                idContaPadrao: contaPadrao,
                interClient,
                interSecrety,
                validade
            }
            const resposta = await novoDadosInter(payload)

            if(resposta.status === 200) {
                setProcessando(false)
                window.location.reload()
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
        }
    }

    const cadastrarCertificado = async (e: FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        if(certificado === undefined) {
            return setProcessando(false)
        }

        try {

            const payload = {
                certificado
            }
            const resposta = await novoCertificado(payload)

            if(resposta.status === 200) {
                setProcessando(false)
                window.location.reload()
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
        }
    }

    const cadastrarChave = async (e: FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        if(chave === undefined) {
            return setProcessando(false)
        }

        try {

            const payload = {
                chave
            }
            const resposta = await novaChave(payload)

            if(resposta.status === 200) {
                setProcessando(false)
                window.location.reload()
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
        }
    }

    const cadastrarTokenInter = async (e: FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        try {

            const resposta = await gerarTokenInter()

            if(resposta.status === 201) {
                setProcessando(false)
                window.location.reload()
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
        }
    }

    const cadastrarHebHookInter = async (e: FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        try {

            const resposta = await habilitarWebHook()

            if(resposta.status === 201) {
                setProcessando(false)
                window.location.reload()
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
        }
    }

    useEffect(() => {

        const buscarContas = async () => {

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativa'
                }

                const resposta = await listarContas(params)

                if(resposta.status === 200) {
                    setProcessando(false)

                    return setContasBancarias(resposta.data)
                }
                
            } catch (error) {
                
            }
        }

        buscarContas()

    }, [])


    return (
        <div id='fundoModal' className='fundoModalIntegracaoInter'>
            {erro && <ModalAlerta texto= 'Falha ao Processar a Solicitação' btnTitulo='Continuar' icone={erroImg} cor='continuarErro' acao={ocultarModal} />}
            <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={integracaoImg} alt='' />
                    <p>{dadosIntegracaoInter?.situacao === 'ativo' ? 'Refazer Integração' : 'Configurar Integracao'}</p>
                </span>
                <div id='formModalNovo'>
                    <form onSubmit={cadastrarDadosInter}>
                        <section className='etapaConfiguracaoInter'>
                            <div className='estapaIntegracaoInter'>
                                <p>Etapa I</p>
                                {dadosIntegracaoInter?.interClient && dadosIntegracaoInter.situacao === 'incompleto' && <DivSituacao texto='Finalizada' cor='finalizada'/>}
                            </div>
                            {!dadosIntegracaoInter?.interClient && <span>
                                <label htmlFor="interCliente">interClient</label>
                                <input 
                                    id='interCliente' 
                                    type="text"
                                    min={40}
                                    maxLength={40} 
                                    value={interClient}
                                    placeholder='Ex.: fef40279-878b-4496-8f79-89e4d8a6eb4b'
                                    onChange={(e) => setInterClient(e.target.value)}
                                    required/>
                            </span>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <span>
                                <label htmlFor="interCliente">interClient</label>
                                <input 
                                    id='interCliente' 
                                    type="text"
                                    min={40}
                                    maxLength={40} 
                                    value={interClient}
                                    placeholder='Ex.: fef40279-878b-4496-8f79-89e4d8a6eb4b'
                                    onChange={(e) => setInterClient(e.target.value)}
                                    required/>
                            </span>}

                            {!dadosIntegracaoInter?.interSecrety && <span>
                                <label htmlFor="interSecrety">interSecrety</label>
                                <input 
                                    id='interSecrety' 
                                    type="text"
                                    min={40}
                                    maxLength={40} 
                                    value={interSecrety}
                                    placeholder='Ex.: 9648b2e7-41a8-4646-a5dd-f7346817c59f'
                                    onChange={(e) => setInterSecrety(e.target.value)}
                                    required/>
                            </span>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <span>
                                <label htmlFor="interSecrety">interSecrety</label>
                                <input 
                                    id='interSecrety' 
                                    type="text"
                                    min={40}
                                    maxLength={40} 
                                    value={interSecrety}
                                    placeholder='Ex.: 9648b2e7-41a8-4646-a5dd-f7346817c59f'
                                    onChange={(e) => setInterSecrety(e.target.value)}
                                    required/>
                            </span>}

                            {!dadosIntegracaoInter?.validade && <span>
                                <label htmlFor="validadeInter">Validade</label>
                                <input 
                                    id='validadeInter' 
                                    type="date"
                                    value={validade}
                                    onChange={(e) => setValidade(e.target.value)}
                                    required/>
                            </span>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <span>
                                <label htmlFor="validadeInter">Validade</label>
                                <input 
                                    id='validadeInter' 
                                    type="date"
                                    value={validade}
                                    onChange={(e) => setValidade(e.target.value)}
                                    required/>
                            </span>}

                            {!dadosIntegracaoInter?.validade && <span>
                                <label htmlFor="contaPadraoInter">Conta Padrão</label>
                                <select id="contaTransacao" value={contaPadrao} onChange={(e) => setContaPadrao(Number(e.target.value))} required>
                                    <option value=""></option>
                                    {contasBancarias.map((conta) => (
                                        <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                    ))}
                                </select>
                            </span>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <span>
                                <label htmlFor="contaPadraoInter">Conta Padrão</label>
                                <select id="contaTransacao" value={contaPadrao} onChange={(e) => setContaPadrao(Number(e.target.value))} required>
                                    <option value=""></option>
                                    {contasBancarias.map((conta) => (
                                        <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                    ))}
                                </select>
                            </span>}

                            {!dadosIntegracaoInter?.validade && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                            </Button>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                            </Button>}
                        </section>
                    </form>

                    <form onSubmit={cadastrarCertificado}>
                        <section className='etapaConfiguracaoInter'>
                            <div className='estapaIntegracaoInter'>
                                <p>Etapa II</p>
                                {dadosIntegracaoInter?.interClient && dadosIntegracaoInter.situacao === 'incompleto' && <DivSituacao texto='Finalizada' cor='finalizada'/>}
                            </div>
                            {!dadosIntegracaoInter?.certificado && <span>
                                <label htmlFor="certificadoInter">Certificado</label>
                                <input 
                                    id='certificadoInter' 
                                    type="file"
                                    accept='.crt'
                                    onChange={(e) => certificadoFile(e)}
                                    required/>
                            </span>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <span>
                                <label htmlFor="certificadoInter">Certificado</label>
                                <input 
                                    id='certificadoInter' 
                                    type="file"
                                    accept='.crt'
                                    onChange={(e) => certificadoFile(e)}
                                    required/>
                            </span>}
                            
                            {!dadosIntegracaoInter?.certificado && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                            </Button>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                            </Button>}

                        </section>
                    </form>

                    <form onSubmit={cadastrarChave}>
                        <section className='etapaConfiguracaoInter'>
                            <div className='estapaIntegracaoInter'>
                                <p>Etapa III</p>
                                {dadosIntegracaoInter?.interClient && dadosIntegracaoInter.situacao === 'incompleto' && <DivSituacao texto='Finalizada' cor='finalizada'/>}
                            </div>
                            {!dadosIntegracaoInter?.chave && <span>
                                <label htmlFor="chaveInter">Chave</label>
                                <input 
                                    id='chaveInter' 
                                    type="file"
                                    accept='.key'
                                    onChange={(e) => chaveFile(e)}
                                    required/>
                            </span>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <span>
                                <label htmlFor="chaveInter">Chave</label>
                                <input 
                                    id='chaveInter' 
                                    type="file"
                                    accept='.key'
                                    onChange={(e) => chaveFile(e)}
                                    required/>
                            </span>}
                                
                            {!dadosIntegracaoInter?.chave && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                            </Button>}

                        </section>
                    </form>

                    <form onSubmit={cadastrarTokenInter}>
                        <section className='etapaConfiguracaoInter'>
                            <div className='estapaIntegracaoInter'>
                                <p>Etapa IV</p>
                                {dadosIntegracaoInter?.webhook && dadosIntegracaoInter.situacao === 'incompleto' && <DivSituacao texto='Finalizada' cor='finalizada'/>}
                            </div>
                            {!dadosIntegracaoInter?.webhook && <span>
                                <label htmlFor="tokenInter">Gerar Token</label>
                                <input 
                                    id='tokenInter' 
                                    type="text"
                                    disabled
                                    value='Gere o seu Primeiro Token de Acesso'/>
                            </span>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <span>
                                <label htmlFor="tokenInter">Gerar Token</label>
                                <input 
                                    id='tokenInter' 
                                    type="text"
                                    disabled
                                    value='Atualize seu Token de Acesso'/>
                            </span>}

                            {!dadosIntegracaoInter?.webhook && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>}
                            
                            {dadosIntegracaoInter?.situacao === 'ativo' && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>}
                            
                        </section>
                    </form>
                    
                    <form onSubmit={cadastrarHebHookInter}>
                        <section className='etapaConfiguracaoInter'>
                            <div className='estapaIntegracaoInter'>
                                <p>Etapa V</p>
                                {dadosIntegracaoInter?.webhook && dadosIntegracaoInter.situacao === 'incompleto' && <DivSituacao texto='Finalizada' cor='finalizada'/>}
                            </div>
                            {!dadosIntegracaoInter?.webhook && <span>
                                <label htmlFor="habilitarWebHook">Habilitar o WebHook</label>
                            </span>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <span>
                                <label htmlFor="habilitarWebHook">Habilitar o WebHook</label>
                            </span>}

                            {!dadosIntegracaoInter?.webhook && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>}

                            {dadosIntegracaoInter?.situacao === 'ativo' && <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Enviar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>}
                            
                        </section>
                    </form>

                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Sair</Button>
                    </span>
                </div>
            </section>
        </div>
    )
}

export default ModalIntegracaoInter
