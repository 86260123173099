import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import ProcessoSeletivo from "../../components/ProcessoSeletivo"
import { useLocation } from "react-router-dom"

function ProcessosSeletivos() {
  const location = useLocation()

  const { search } = location

  const paramsUrl = new URLSearchParams(search)
  const meusProcessos = paramsUrl.get('meusProcessos')

  useEffect(() => {
    document.title = meusProcessos ? "Meus Processos Seletivos | Gesttor" : "Processo Seletivo | Gesttor"
  }, [meusProcessos])

  return (
    <DadosContainerDefault>
      <ProcessoSeletivo />
    </DadosContainerDefault>
  )
}

export default ProcessosSeletivos