import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import Categorias from "../../components/Categorias";

function CategoriasPage () {

    useEffect (() => {
        document.title = 'Categorias | Gesttor'
    }, [])


    return (
        <DadosContainerDefault>
            <Categorias />
        </DadosContainerDefault>
    )
}

export default CategoriasPage