import { useState, useEffect, useContext, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { listarSuporteOrganizacoes } from '../../services/suporte'
import { toogleSugestao } from '../../services/sugestao'
import { suporteListaOrganizacoesResposta } from '../../interfaces/suporte'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import ModalSuporte from '../ModalSuporte'
import FiltrosDefault from '../FiltrosDefault'
import avatarDefault from '../../assets/img/avatarDefault.png'
import DivSituacao from '../DivSituacao'
import ToogleDefault from '../ToogleDafault'
import LoagindBarra from '../LoadingBarra'
import LoadingCirculo from '../LoadingCirculo'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import '../../styles/paginaListaDados.css'
import './suporte.css'

function SuporteGesttor () {

    const { modalEstado, exibirModal, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname, search } = location

    const [suportes, setSuportes] = useState<suporteListaOrganizacoesResposta[]>([])
    const [suporte, setSuporte] = useState<suporteListaOrganizacoesResposta>()
    const [modalSuporte, setModalSuporte] = useState(false)
    const [maisSuporte, setmaisSuporte] = useState(false)
    const [check, setCheck] = useState(false)
    const [pagina, setPagina] = useState(2)
    const [tipo, setTipo] = useState('')
    const [termo, setTermo] = useState('')
    const [tipos, setTipos] = useState<{nome: string, valor: string}[]>([])
    const [situacao, setSituacao] = useState('')
    const [sugestao, setSugestao] = useState(false)
    const [processando, setProcessando] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [erro, setErro] = useState(false)

    const exibirModalSuporte = () => exibirModal()

    const exibirModalDadosSuporte = (suporte: suporteListaOrganizacoesResposta, idSuporte: string) => {

        navigate(`${pathname}?tipo=${tipo}&id${tipo.charAt(0).toUpperCase()}${tipo.substring(1)}=${idSuporte}`)
        exibirModal()

        return setSuporte(suporte)
    }

    const participacao = (participantes: {id: string, idUsuario: string}[]) => {

        const meuUsario = localStorage.getItem('usuarioGesttor')

        if(meuUsario === undefined) {
            return false
        }

        const souParticipante = participantes.find(usuario => usuario.idUsuario === meuUsario)

        if(souParticipante) {
            return true
        }

        return false

    }

    const toogleParticipacao = async (idSugestao: string) => {

        setProcessando(true)

        try {

            const payload = {
                idSugestao: idSugestao
            }

            const resposta = await toogleSugestao(payload)

            if(resposta.status === 201 || resposta.status === 200) {
                setProcessando(false)
                setSugestao(true)
                setCheck(true)
                atualizarDados()

                return setTimeout(() => {
                    setSugestao(false)
                }, 2500)

            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout (() => {
                setErro(false)
            }, 2500)
            
        }
    }

    const buscarSuportes = async (e: FormEvent) => {

        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        const params = {
            tipo,
            termo,
            pagina: 1,
            status: situacao
        }

        try {

            const resposta = await listarSuporteOrganizacoes(params)

            if (resposta.data.length === 0) {
                setSuportes(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setSuportes(resposta.data)

                return setProcessando(false)
            }
            
        } catch (error) {
            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
        }
    }

    const scrollInfinito = (e: any) => {

        const { scrollTop, clientHeight, scrollHeight } = e.target;

        const posicaoScroll = (scrollTop/(scrollHeight - clientHeight)*100)

        if (posicaoScroll === 100) {

            setPagina(prevtState => prevtState + 1)

            const buscarMaisCadastros = async () => {

                setmaisSuporte(true)

                if(!tipo) {
                    return
                }

                const params = {
                    tipo,
                    termo,
                    pagina,
                    status: situacao
                }

                try {

                    const resposta = await listarSuporteOrganizacoes(params)

                    if (resposta.status === 200) {
                        setSuportes(prevDate => [...prevDate, ...resposta.data])
                        return setmaisSuporte(false)
                    }
            
                } catch (error) {

                    setmaisSuporte(false)
                    setErro(true)

                    return setTimeout(() => {
                        setErro(false)
                    }, 2000)
                }
            }

            buscarMaisCadastros()
        }
    }

    useEffect (() => {
        
        setModalSuporte(modalEstado)

        if(!modalEstado) setSuporte(undefined)

    }, [modalEstado])


    useEffect (() => {

        const paramsUrl = new URLSearchParams(search)

        const tipo = paramsUrl.get('tipo')

        if(tipo === undefined || !tipo) {
            return
        }

        return setTipo(tipo)

    }, [search])

    useEffect(() => {

        const atualizarTipos = () => {

            if(!tipo) {
                return {nome: '', valor: ''}
            }
    
            const tipos: {[key: string]: {nome: string, valor: string}[]} = {
                suporte: [
                    { nome: 'Todos', valor: '' },
                    { nome: 'Sem Análise', valor: 'sem análise' },
                    { nome: 'Em Análise', valor: 'em análise' },
                    { nome: 'Aguardando Retorno do Cliente', valor: 'aguardando retorno do cliente' },
                    { nome: 'Aguardando Retorno do Suporte', valor: 'aguardando retorno do suporte' },
                    { nome: 'Finalizado', valor: 'finalizado'}
                ],
                bug: [
                    { nome: 'Todos', valor: '' },
                    { nome: 'Sem Análise', valor: 'sem análise'},
                    { nome: 'Em Análise', valor: 'em análise' },
                    { nome: 'Conclúido', valor: 'concluído' },
                ],
                sugestão: [
                    { nome: `${tipo === 'sugestão' ? 'Todas' : 'Todos'}`, valor: '' },
                    { nome: 'Sem Análise', valor: 'sem análise'},
                    { nome: 'Aceita', valor: 'aceita' },
                    { nome: 'Em Desenvolvimento', valor: 'em desenvolvimento' },
                    { nome: 'Recusada', valor: 'recusada' },
                    { nome: `${tipo === 'sugestão' ? 'Finalizada' : 'Finalizado'}`, valor: 'finalizado' },
                ]
            }
    
            return setTipos(tipos[tipo])
        }

        atualizarTipos()

    }, [tipo])

    useEffect(() => {

        const buscarSuporte = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            if(!tipo) {
                return
            }

            try {

                const params = {
                    tipo,
                    termo: '',
                    pagina: 1,
                    status: ''
                }

                const resposta = await listarSuporteOrganizacoes(params)

                if (resposta.data.length === 0) {
                    setSuportes(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {
                    setProcessando(false)
                    return setSuportes(resposta.data)
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 2500)
            }
        }

        buscarSuporte ()

    }, [tipo, atualizarInformacoes])

    return (
        <div id='paginaContainer'>
            {modalSuporte && <ModalSuporte suporte={suporte}/>}
            {processando && <LoagindBarra />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {sugestao && <Notificacoes icone={confirmacaoImg} texto={check === true ? 'Parcitipação Inserida com com Sucesso' : 'Participação Removida com Sucesso'} cor='sucesso' />}
            {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação' cor='erro'/>}
            <FiltrosDefault termo={termo} situacao={situacao} placeholder='Pesquise pelo Título' tipo={tipo} onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={buscarSuportes} acaoBtn={exibirModalSuporte} listaSituacao={tipos} genero={tipo === 'sugestão' ? 'feminino': 'masculino'} isLoading={processando}/>

            <section id='fundoContainerPagina' onScroll={scrollInfinito}>
                <table id='tabelaListaContainer'>
                    <thead>
                        <tr>
                            {tipo === 'sugestão' ? <th id='ladoEsquerdoContent' align='left'>Ranking</th> : ''}
                            <th id={tipo === 'sugestão' ? '' : 'ladoEsquerdoContent'}  align='left'>Título</th>
                            <th id='responsavelSuporte' align='left'>Reportador por </th>
                            <th align='left'>Empresa</th>
                            <th className='situacaoSuporte' align='center'>Situação</th>
                            {tipo === 'sugestão' ? <th align='center'>Participar</th>: ''}
                        </tr>
                    </thead>
                    <tbody>
                        {suportes.map((suporte) => (
                            <tr key={suporte.id}>
                                {tipo === 'sugestão' ? <td id='primeiraColuna' onClick={() => exibirModalDadosSuporte(suporte, suporte.id)}>{suporte.participantes.length.toString().padStart(3,'000')}</td> : ''}
                                <td id={tipo === 'sugestão' ? '' : 'primeiraColuna'} onClick={() => exibirModalDadosSuporte(suporte, suporte.id)}>{suporte.titulo}</td>
                                <td id='usuarioResponsavelAjudaContent' onClick={() => exibirModalDadosSuporte(suporte, suporte.id)}>
                                    <img id='usuarioResponsavelAjuda' src={suporte.solicitadoPor.avatar ? suporte.solicitadoPor.avatar : avatarDefault} alt='' />
                                    {suporte.solicitadoPor.nome}
                                </td>
                                <td align='left'>{suporte.solicitadoPor.organizaco.nomeFantasia}</td>
                                <td id={tipo === 'sugestão' ? '' : 'ladoDireitoContent'} className='situacaoSuporte' align='center' onClick={() => exibirModalDadosSuporte(suporte, suporte.id)}>
                                    <DivSituacao texto={`${suporte.status.charAt(0).toUpperCase()}${suporte.status.substring(1)}`} cor={suporte.status.replace(/\s+/g, '')}/>
                                </td>
                                {tipo === 'sugestão' ? <td id='ladoDireitoContent' align='center' onClick={() => toogleParticipacao(suporte.id)}>
                                    <ToogleDefault checked={() => participacao(suporte.participantes)} id={suporte.id}/>
                                </td>: ''}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {maisSuporte && <div id='buscarMaisDados'>
                    <LoadingCirculo />
                </div>}
            </section>
        </div>
    )
}

export default SuporteGesttor