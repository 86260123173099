import { useState, useEffect, useContext } from 'react'
import { lisatarLeads } from '../../services/leads'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import Perfil from '../../middleware/perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import '../../styles/menuopcoes.css'

function MenuComercial (props: menuOpcoes) {

    const { atualizarInformacoes } = useContext(ModalEstadoContext)

    const [leads, setLeads] = useState(0)
    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    useEffect(() => {
        
        const buscarLeads = async () => {

            const params = {
                pagina: 1,
                nome: '',
                descricao: '',
                situacao: '',
            }

            try {

                const resposta = await lisatarLeads(params)

                if (resposta.status === 200) return setLeads(resposta.data.filter((lead: { situacao: string }) => lead.situacao === 'Novo' || lead.situacao === 'Em Negociação').length)
                
            } catch (error) {
                
            }
        }

        buscarLeads ()

    }, [atualizarInformacoes])

    return (
        <Perfil perfil={['master', 'administrativo', 'comercial', 'atendimento']}>
            <div>
                <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                    {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                    <p id = 'tituloMenu'>{props.titulo}</p>
                    {leads !== 0 ? <h2>{leads}</h2> : null}
                </div>
                <div id = 'submenuOpcoes'>
                    {subMenu && <SubMenuOpcoes redirect = '/leads' texto = 'Leads' notificacao={leads !== 0 ? leads : null}/>}
                </div>
            </div>
        </Perfil>
    )
}

export default MenuComercial