import BASEAPI from '../api/config'
import { cadastrarModeloTarefaPayload, atualizarModeloTarefaPayload } from '../../interfaces/modelosTarefas'


export function novaTarefaModelo (payload: cadastrarModeloTarefaPayload) {
    return BASEAPI.post('/tarefasmodelos', payload)
} 

export function listarTarefasModelos (idModeloProcesso: string) {
    return BASEAPI.get(`/tarefasmodelos?idModeloProcesso=${idModeloProcesso}`)
}

export function atualizarTarefaModelo (idTarefaModelo: string, payload: atualizarModeloTarefaPayload) {
    return BASEAPI.patch(`/tarefasmodelos?idTarefaModelo=${idTarefaModelo}`, payload)
}

export function deletarTarefaModelo (idTarefa: string) {
    return BASEAPI.delete(`/tarefasmodelos?idTarefaModelo=${idTarefa}`)
}

