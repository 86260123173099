import { createContext, useState } from 'react'
import { propsProvider } from '../../interfaces/propsProvider'
import { propsContext } from '../../interfaces/propsContext'

const ModalEstadoContext = createContext<propsContext>({
    modal: { nome: '' },
    modalSecundario: { nome: '' },
    modalEstado: false,
    modalEstadoSecundario: false,
    modalEstadoTernario: false,
    atualizarInformacoes: false,
    modalExibir: (nome: string) => {},
    modalSecundarioExibir: (nome: string) => {},
    exibirModal: () => {},
    atualizarDados: () => {},
    exibirModalSecundario: () => {},
    exibirModalTernario: () => {}
})

const ModalEstadoProvider = ({ children }: propsProvider) => {

    const [modal, setModal] = useState({
        nome: ''
    })
    const [modalSecundario, setModalSecundario] = useState({
        nome: ''
    })
    const [modalEstado, setModalEstado] = useState(false)
    const [modalEstadoSecundario, setModalEstadoSecundario] = useState(false)
    const [modalEstadoTernario, setModalEstadoTernario] = useState(false)
    const [atualizarInformacoes, setAtualizarInformacoes] = useState(false)

    const modalExibir = (nome: string ) => setModal({ nome: nome })
    const modalSecundarioExibir = (nome: string ) => setModalSecundario({ nome: nome })
    const exibirModal = () => setModalEstado(!modalEstado)
    const exibirModalSecundario = () => setModalEstadoSecundario(!modalEstadoSecundario)
    const exibirModalTernario = () => setModalEstadoTernario(!modalEstadoTernario)
    
    const atualizarDados = () => setAtualizarInformacoes(!atualizarInformacoes)

    return (
        <ModalEstadoContext.Provider value={{modal, modalSecundario, modalExibir, modalSecundarioExibir, modalEstado, exibirModal, exibirModalSecundario, modalEstadoSecundario, atualizarDados, atualizarInformacoes, exibirModalTernario, modalEstadoTernario}}>
            {children}
        </ModalEstadoContext.Provider>
    )
}

export { ModalEstadoContext, ModalEstadoProvider }