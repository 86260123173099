import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import ClientesGesttor from "../../components/ClientesGesttor"

function ClientesGesttorPage () {

    useEffect (() => {
        document.title = 'Gerenciar Licenças | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <ClientesGesttor />
        </DadosContainerDefault>
    )
}

export default ClientesGesttorPage