import { useEffect, useState, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { gerarQrCode } from '../../services/cobrancas'
import { Button } from '../Styles'
import Notificacoes from '../Notificacoes'
import io from 'socket.io-client'
import ProcessandoSolicitacao from '../ProcessandoSolicitacao'
import ModalAlerta from '../ModalAlerta'
import erroImg from '../../assets/img/erroFechar.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import './modalqrcode.css'

function ModalQrCode () {

    const { atualizarDados, modalSecundarioExibir } = useContext(ModalEstadoContext)

    const [processando, setProcessando] = useState(true)
    const [processandoLink, setProcessandoLink] = useState(false)
    const [qrCode, setQrCode] = useState({__html: 'Seu QrCode está Sendo Gerado'})
    const [sucessoPagamentoPix, setSucessoPagamentoPix] = useState(false)
    const [confirmacaoLink, setConfirmacaoLink] = useState(false)
    const [erro, setErro] = useState(false)


    const ocultarModal = () => modalSecundarioExibir('')

    const PixCopiaECola = async (link: string | undefined) => {

        setProcessandoLink(true)

        if(!link) {
            return setProcessandoLink(false)
        }

        try {

            const parser = new DOMParser()
            const doc = parser.parseFromString(link, 'image/svg+xml')
            

            const svgElement = doc.querySelector('svg')

            const pixCopiaCola = svgElement ? svgElement.outerHTML : ''

            console.log(pixCopiaCola)

            await navigator.clipboard.writeText(pixCopiaCola)

            setConfirmacaoLink(true)

            return setTimeout(() => {
                setProcessandoLink(false)
                setConfirmacaoLink(false)
            }, 5000)
            
        } catch (error) {
            
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
        }
    }
 

    useEffect(() => {
        
        const iniciarSocket = () => {

            const idOrganizacao = localStorage.getItem("organizacaoGesttor")

            const socket = io(`${process.env.REACT_APP_API_GESTTOR}?idOrganizacao=${idOrganizacao}`)
        
            socket.on('pix', (data) => {

                const { idCobranca,  situacao } = data

                if(idCobranca === localStorage.getItem("idCobrancaGesttor") && situacao === 'recebida') {
                    
                    setSucessoPagamentoPix(true)
                    atualizarDados()
                    socket.disconnect()
                }
            })

            return () => {
                socket.disconnect()
            }

        }

        iniciarSocket()

    }, [atualizarDados])

    useEffect (() => {

        const criarCobranca = async () => {

            const idCobranca = Number(localStorage.getItem("idCobrancaGesttor"))

            try {

                const resposta = await gerarQrCode(idCobranca)

                if(resposta.status === 200) {
                    setProcessando(false)
                    return setQrCode({__html: resposta.data}) 
                }
                
            } catch (error) {
                setProcessando(false)
                return setErro (true)
            }
        }

        criarCobranca ()

    }, [])

    return (
        
        <div id='modalQrCode'>
            {confirmacaoLink && <Notificacoes texto='PixCopiaCola copiado com Sucesso' icone={confirmacaoImg} cor='sucesso'/>}
            {sucessoPagamentoPix && <ModalAlerta icone={confirmacaoImg} texto='Seu Pagamento foi confirmado com sucesso. Em alguns instantes você receberá o recibo no e-mail que está em seu cadastro.' btnTitulo='Continuar' cor='continuarSucesso' acao={ocultarModal}/>}
            {erro && <ModalAlerta icone={erroImg} texto='Ocorreu um erro ao gerar o QrCode. Tente Novamente, se o erro persistir entre em contato com o nosso atendimento.' cor='continuarErro' btnTitulo='Tentar Novamente' acao={ocultarModal} />}
            {!erro && !sucessoPagamentoPix && <section id = 'modalFundoQrCode'>
            {processando && <ProcessandoSolicitacao textoSuperior='Gerando QrCode' />}
            {!processando && <span dangerouslySetInnerHTML={qrCode} />}
            {!processando &&  <Button type='button' isLoading={processandoLink} cor='link' onClick={() => PixCopiaECola(qrCode.__html)}>PixCopiaCola</Button>}
                <div>
                    <p>Quem vai receber este pagamento?</p>
                    <br />
                    <p>Razão Social: <b>SILVA SERVIÇOS EMPRESARAIS LTDA</b></p>
                    <p>Nome Fantasia: <b>HG CONSULTORIA</b></p>
                    <p>CNPJ: <b>19.306.845/0001 - 38</b></p>
                </div>
                <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar Operação</Button>
            </section>}
        </div>
    )
}

export default ModalQrCode