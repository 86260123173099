import nenhumResultado from '../../assets/img/nenhumResultado.png'
import './nenhumresultado.css'

function NenhumResultadoEncontrado () {

    return (
        <div id = 'containerNenhumResultado'>
            <img src= {nenhumResultado} alt = '' />
            <p>Nenhum Resultado Encontrato</p>
        </div>
    )
}

export default NenhumResultadoEncontrado

