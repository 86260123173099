import { useState, FormEvent, useContext, useEffect } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { contratarSistema } from '../../services/contratarSistema'
import { atualizarOrganizacao } from '../../services/organizacao'
import { dadosUsuario } from '../../services/usuarios'
import { cepMascara, cpfCnpjMascara, telefoneMascara, validarCpfCnpj, moedaMascara, converterNumero } from '../../helpers'
import { ModalOrganizacaoDados } from '../../interfaces/organizacao'
import { Button, Loader } from '../Styles'
import ModalAlerta from '../ModalAlerta'
import Notificacoes from '../Notificacoes'
import contratoImg from '../../assets/img/contrato.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import './modalcontratarsistema.css'
import '../../styles/modal.css'


function ModalContratarSistema ({ organizacao }: ModalOrganizacaoDados) {

    const { exibirModal, atualizarDados } = useContext(ModalEstadoContext)

    const [clienteCnpjCpf] = useState(organizacao?.cnpjCpf)
    const [clienteNomeFantasia, setClienteNomeFantasia] = useState(organizacao?.nomeFantasia)
    const [clienteRazaoSocial, setClienteRazaoSocial] = useState(organizacao?.razaoSocial)
    const [clienteTelefone, setClienteTelefone] = useState(organizacao?.telefone)
    const [clienteEmail, setClienteEmail] = useState(organizacao?.email)
    const [clienteRua, setClienteRua] = useState(organizacao?.rua)
    const [clienteNumero, setClienteNumero] = useState(organizacao?.numero)
    const [clienteBairro, setClienteBairro] = useState(organizacao?.bairro)
    const [clienteComplemento, setClienteComplemento] = useState(organizacao?.complemento)
    const [clienteCep, setClienteCep] = useState(organizacao?.cep)
    const [clienteCidade, setClienteCidade] = useState(organizacao?.cidade)
    const [clienteEstado, setClienteEstado] = useState(organizacao?.estado)
    const [responsavelCpf, setResponsavelCpf] = useState('')
    const [responsavelNome, setResponsavelNome] = useState('')
    const [responsavelTelefone, setResponsavelTelefone] = useState('')
    const [responsavelEmail, setResponsavelEmail] = useState('')
    const [responsavelDataNascimento, setResponsavelDataNascimento] = useState('')
    const [responsavelRua, setResponsavelRua] = useState(organizacao?.rua)
    const [responsavelNumero, setResponsavelNumero] = useState(organizacao?.numero)
    const [responsavelBairro, setResponsavelBairro] = useState(organizacao?.bairro)
    const [responsavelComplemento, setResponsavelComplemento] = useState(organizacao?.complemento)
    const [responsavelCep, setResponsavelCep] = useState(organizacao?.cep)
    const [responsavelCidade, setResponsavelCidade] = useState(organizacao?.cidade)
    const [responsavelEstado, setResponsavelEstado] = useState(organizacao?.estado)
    const [dadosOrganizacao, setDadosOrganizacao] = useState(true)
    const [dadosResponsavel, setDadoResponsavel] = useState(false)
    const [dadosContratacao, setDadosContratacao] = useState(false)
    const [usuariosAdicionais, setUsuariosAdicionais] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)
    const [form, setForm] = useState(true)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState(false)
    const [cpfInvalido, setCpfInvalido] = useState(false)
    const [dadosIncompletos, setDadosIncompletos] = useState(false)

    function ocultarModal () {

        return exibirModal ()
    }

    function ocultarModalAlerta () {
        
        setForm(true)
        return setErro(false)
    }

    const avancarVoltarContratacao = () => {

        setDadosOrganizacao(!dadosOrganizacao)
        setDadoResponsavel(!dadosResponsavel)

        return
    }

    const ultimaEtapaContratacao = () => {

        setDadoResponsavel(!dadosResponsavel)
        setDadosContratacao(!dadosContratacao)
        setDadosOrganizacao(false)

        return
    }

    const validarCpf = (e: any) => {

        const validarDados = validarCpfCnpj(e.target.value)

        if (validarDados === false) {
            setCpfInvalido(true)
            setResponsavelCpf('')

            return setTimeout(() => {
                setCpfInvalido(false)
            }, 2500)
        }
    }

    useEffect(() => {

        const usuariosExtras = usuariosAdicionais
        
        setValorTotal(39.90*100 + usuariosExtras*9.90*100)
        
        return 

    }, [usuariosAdicionais])

    const contratarPlataforma = async (e: FormEvent) => {

        e.preventDefault()
        setForm(false)
        setProcessando(true)

        if(responsavelRua === undefined || responsavelRua === null || responsavelNumero === undefined || responsavelNumero ===  null || responsavelBairro === undefined || responsavelBairro === null || responsavelComplemento === undefined || responsavelCep === undefined || responsavelCep === null || responsavelCidade === null || responsavelCidade === undefined || responsavelEstado === null || responsavelEstado === undefined || clienteCnpjCpf === null || clienteCnpjCpf === undefined || clienteNomeFantasia === null || clienteNomeFantasia === undefined || clienteRazaoSocial === null || clienteRazaoSocial === undefined || clienteEmail === null || clienteEmail === undefined || clienteTelefone === undefined || clienteTelefone === null || clienteRua === null || clienteRua === undefined || clienteNumero === null || clienteNumero === undefined || clienteBairro === null || clienteBairro === undefined || clienteComplemento === undefined || clienteCep === undefined || clienteCep === null || clienteCidade === null || clienteCidade === undefined || clienteEstado === null || clienteEstado === undefined) {
            setProcessando(false)
            setDadosIncompletos(true)

            return setTimeout (() => {
                setDadosIncompletos(false)
            }, 2500)
        }

        try {

            const payload = {
                responsavel: {
                    nome: responsavelNome,
                    cnpjCpf: responsavelCpf,
                    email: responsavelEmail,
                    telefone: responsavelTelefone,
                    dataNascimento: responsavelDataNascimento,
                    rua: responsavelRua,
                    numero: responsavelNumero,
                    complemento: responsavelComplemento,
                    cep: responsavelCep,
                    bairro: responsavelBairro,
                    cidade: responsavelCidade,
                    estado: responsavelEstado
                },
                cliente: {
                    cnpjCpf: clienteCnpjCpf,
                    nomeFantasia: clienteNomeFantasia,
                    razaoSocial: clienteRazaoSocial,
                    email: clienteEmail,
                    telefone: clienteTelefone,
                    rua: clienteRua,
                    numero: clienteNumero,
                    complemento: clienteComplemento,
                    cep: clienteCep,
                    bairro: clienteBairro,
                    cidade: clienteCidade,
                    estado: clienteEstado
                },
                usuariosAdicionais,
                valorAssinatura: converterNumero(valorTotal)
            }

            const payloadAtualizarOrganizacao = {
                nomeFantasia: clienteNomeFantasia,
                razaoSocial: clienteRazaoSocial,
                email: clienteEmail,
                telefone: clienteTelefone,
                rua: clienteRua,
                numero: clienteNumero,
                complemento: clienteComplemento,
                cep: clienteCep,
                bairro: clienteBairro,
                cidade: clienteCidade,
                estado: clienteEstado
            }

            await atualizarOrganizacao (payloadAtualizarOrganizacao)

            const resposta = await contratarSistema(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                setConfirmacao(true)

                return
            }
            
        } catch (error: any) {

            if(error.response.status === 400) {
                setProcessando(false)
                setDadosIncompletos(true)

                return setTimeout(() => {
                    setDadosIncompletos(false)
                    setForm(true)
                }, 2500)
            }

            setProcessando(false)
            setErro(true)
            
            return
        }

    }

    useEffect(() => {
        
        const usuarioDados = async () => {

            try {

                const resposta = await dadosUsuario()

                if(resposta.status === 200) {

                    const { nome, email, telefone } = resposta.data

                    setResponsavelNome(nome)
                    setResponsavelEmail(email)
                    setResponsavelTelefone(telefone)
                }
                
            } catch (error) {
                
            }
        }

        usuarioDados ()

    }, [])


    return (
        <div id = 'fundoModal'>
            {dadosIncompletos && <Notificacoes icone={erroImg} texto='Dados Incompletos. Veja os campos obrigatório e tente novamente' cor='erro'/>}
            {cpfInvalido && <Notificacoes icone={erroImg} texto='CPF Inválido' cor='erro' />}
            {confirmacao && <ModalAlerta texto= 'Contratação Realizada com Sucesso. Logo Breve Você receberá o boleto para pagamento da contratação' btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModal} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            {form && <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={contratoImg} alt='' />
                    <p>Revise seus Dados</p>
                </span>
                <form id='formModalNovo' className='formContratarSistema'  onSubmit={contratarPlataforma}>
                    {dadosOrganizacao && <div>
                        <h1 className='dadosOrganizacaoContratar'>DADOS DO NEGÓCIO</h1>
                        <section>
                            <span>
                                <label>CNPJ/CPF</label>
                                <input 
                                    type="text" 
                                    value={clienteCnpjCpf}
                                    disabled/>
                            </span>
                            <span>
                                <label htmlFor='nomeFantasiaOrganizacao'>Nome Fantasia</label>
                                <input
                                    id='nomeFantasiaOrganizacao' 
                                    type="text" 
                                    value={clienteNomeFantasia === null ? '' : clienteNomeFantasia}
                                    onChange={(e) => setClienteNomeFantasia(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='razaoSocialOrganizacao'>Razão Social</label>
                                <input
                                    id='nomeFantasiaOrganizacao' 
                                    type="text" 
                                    value={clienteRazaoSocial === null ? '' : clienteRazaoSocial}
                                    onChange={(e) => setClienteRazaoSocial(e.target.value)}
                                    required/>
                            </span>
                        </section>
                        
                        <section>
                            <span>
                                <label htmlFor='telefoneOrganizazao'>Telefone</label>
                                <input 
                                    id='telefoneOrganizazao'
                                    type="text" 
                                    value={clienteTelefone === null ? '' : clienteTelefone}
                                    onChange={(e) => setClienteTelefone(telefoneMascara(e.target.value))}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='emailOrganizacao'>E-mail</label>
                                <input
                                    id='emailOrganizacao' 
                                    type="email" 
                                    value={clienteEmail === null ? '' : clienteEmail}
                                    onChange={(e) => setClienteEmail(e.target.value)}
                                    required/>
                            </span>
                        </section>
                        <h1 className='dadosOrganizacaoContratar'>Endereço do Estabelecimento</h1>
                        <section>
                            <span>
                                <label htmlFor='ruaOrganizacao'>Rua</label>
                                <input
                                    id='ruaOrganizacao' 
                                    type="text" 
                                    value={clienteRua === null ? '' : clienteRua}
                                    onChange={(e) => setClienteRua(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='numeroOrganizacao'>Número</label>
                                <input
                                    id='numeroOrganizacao' 
                                    type="text" 
                                    value={clienteNumero === null ? '' : clienteNumero}
                                    onChange={(e) => setClienteNumero(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='bairroOrganizacao'>Bairro</label>
                                <input
                                    id='bairroOrganizacao' 
                                    type="text" 
                                    value={clienteBairro === null ? '' : clienteBairro}
                                    onChange={(e) => setClienteBairro(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='complementoOrganizacao'>Complemento</label>
                                <input
                                    id='complementoOrganizacao' 
                                    type="text" 
                                    value={clienteComplemento === null ? '' : clienteComplemento}
                                    onChange={(e) => setClienteComplemento(e.target.value)}/>
                            </span>
                        </section>
                        <section>
                            <span>
                                <label htmlFor='cepOrganizacao'>CEP</label>
                                <input
                                    id='cepOrganizacao' 
                                    type="text" 
                                    value={clienteCep === null ? '' : clienteCep}
                                    maxLength={11}
                                    minLength={11}
                                    onChange={(e) => setClienteCep(cepMascara(e.target.value))}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='cidadeOrganizacao'>Cidade</label>
                                <input
                                    id='cidadeOrganizacao' 
                                    type="text" 
                                    value={clienteCidade === null ? '' : clienteCidade}
                                    onChange={(e) => setClienteCidade(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='estadoOrganizacao'>Estado</label>
                                <select name="estadosOrganizacao" id="estadosOrganizacao" value={clienteEstado === null ? '' : clienteEstado} onChange={(e) => setClienteEstado(e.target.value)}>
                                    <option value = 'acre'>Acre</option>
                                    <option value = 'alagoas'>Alagoas</option>
                                    <option value = 'amapá'>Amapá</option>
                                    <option value = 'amazonas'>Amazonas</option>
                                    <option value = 'bahia'>Bahia</option>
                                    <option value = 'ceará'>Ceará</option>
                                    <option value = 'espírito santo'>Espírito Santo</option>
                                    <option value = 'goiás'>Goiás</option>
                                    <option value = 'maranhão'>Maranhão</option>
                                    <option value = 'mato grosso'>Mato Grosso</option>
                                    <option value = 'mato grosso do sul'>Mato Grosso do Sul</option>
                                    <option value = 'minas gerais'>Minas Gerais</option>
                                    <option value = 'pará'>Pará</option>
                                    <option value = 'paraíba'>Paraíba</option>
                                    <option value = 'paraná'>Paraná</option>
                                    <option value = 'pernanbuco'>Pernambuco</option>
                                    <option value = 'piauí'>Piauí</option>
                                    <option value = 'rio de janeiro'>Rio de Janeiro</option>
                                    <option value = 'rio grande do norte'>Rio Grande do Norte</option>
                                    <option value = 'rio grande do sul'>Rio Grande do Sul</option>
                                    <option value = 'rondônica'>Rondônia</option>
                                    <option value = 'roraima'>Roraima</option>
                                    <option value = 'santa catarina'>Santana Catarina</option>
                                    <option value = 'são paulo'>São Paulo</option>
                                    <option value = 'sergipe'>Sergipe</option>
                                    <option value = 'tocantins'>Tocantins</option>
                                </select>
                            </span>
                        </section>
                        <span id="botoesContainer">
                            <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                            <Button type='submit' cor='salvar' isLoading={processando} onClick={avancarVoltarContratacao}>
                                {!processando && 'Próxima Etapa'}
                                {processando && <Loader isLoading={processando}/>}
                            </Button>
                        </span>
                    </div>}
                    {dadosResponsavel && <div>
                        <h1 className='dadosOrganizacaoContratar'>DADOS DO RESPONSÁVEL</h1>
                        <section>
                            <span>
                                <label htmlFor='responsavelCpf'>CPF</label>
                                <input
                                    id='responsavelCpf' 
                                    type="text" 
                                    value={responsavelCpf === null ? '' : responsavelCpf}
                                    onChange={(e) => setResponsavelCpf(cpfCnpjMascara(e.target.value))}
                                    placeholder='000.000.000 - 00'
                                    minLength={16}
                                    maxLength={16}
                                    onBlur={(e) => validarCpf(e)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='responsavelNome'>Nome Completo</label>
                                <input
                                    id='responsavelNome' 
                                    type="text" 
                                    value={responsavelNome === null ? '' : responsavelNome}
                                    onChange={(e) => setResponsavelNome(e.target.value)}
                                    required/>
                            </span>
                        </section>
                        <section>
                            <span>
                                <label htmlFor='responsavelTelefone'>Telefone</label>
                                <input
                                    id='responsavelTelefone' 
                                    type="text"
                                    placeholder='(00) 0.0000 - 0000'
                                    minLength={18}
                                    maxLength={18} 
                                    value={responsavelTelefone === null ? '' : responsavelTelefone}
                                    onChange={(e) => setResponsavelTelefone(telefoneMascara(e.target.value))}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='responsavelEmail'>E-mail</label>
                                <input
                                    id='responsavelEmail' 
                                    type="email" 
                                    value={responsavelEmail === null ? '' : responsavelEmail}
                                    onChange={(e) => setResponsavelEmail(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='responsavelDataNascimento'>Data de Nascimento</label>
                                <input
                                    id='responsavelDataNascimento' 
                                    type="date" 
                                    value={responsavelDataNascimento}
                                    onChange={(e) => setResponsavelDataNascimento(e.target.value)}
                                    required/>
                            </span>
                        </section>
                        <h1 className='dadosOrganizacaoContratar'>Endereço do Responsável</h1>
                        <section>
                            <span>
                                <label htmlFor='responsavelRua'>Rua</label>
                                <input 
                                    type="text" 
                                    value={responsavelRua === null ? '' : responsavelRua}
                                    onChange={(e) => setResponsavelRua(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='responsavelNumero'>Número</label>
                                <input
                                    id='responsavelNumero' 
                                    type="text" 
                                    value={responsavelNumero === null ? '' : responsavelNumero}
                                    onChange={(e) => setResponsavelNumero(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='responsavelBairro'>Bairro</label>
                                <input
                                    id='responsavelBairro' 
                                    type="text" 
                                    value={responsavelBairro === null ? '' : responsavelBairro}
                                    onChange={(e) => setResponsavelBairro(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='responsavelComplemento'>Complemento</label>
                                <input
                                    id='responsavelComplemento' 
                                    type="text" 
                                    value={responsavelComplemento === null ? '' : responsavelComplemento}
                                    onChange={(e) => setResponsavelComplemento(e.target.value)}/>
                            </span>
                        </section>
                        <section>
                            <span>
                                <label htmlFor='responsavelCep'>CEP</label>
                                <input 
                                    id='responsavelCep'
                                    type="text" 
                                    value={responsavelCep === null ? '' : responsavelCep}
                                    minLength={11}
                                    maxLength={11}
                                    onChange={(e) => setResponsavelCep(cepMascara(e.target.value))}/>
                            </span>
                            <span>
                                <label htmlFor='responsavelCidade'>Cidade</label>
                                <input
                                    id='responsavelCidade' 
                                    type="text" 
                                    value={responsavelCidade === null ? '' : responsavelCidade}
                                    onChange={(e) => setResponsavelCidade(e.target.value)}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='responsavelEstado'>Estado</label>
                                <select name="responsavelEstado" id="responsavelEstado" value={responsavelEstado === null ? '' : responsavelEstado} onChange={(e) => setResponsavelEstado(e.target.value)}>
                                    <option value = 'acre'>Acre</option>
                                    <option value = 'alagoas'>Alagoas</option>
                                    <option value = 'amapá'>Amapá</option>
                                    <option value = 'amazonas'>Amazonas</option>
                                    <option value = 'bahia'>Bahia</option>
                                    <option value = 'ceará'>Ceará</option>
                                    <option value = 'espírito santo'>Espírito Santo</option>
                                    <option value = 'goiás'>Goiás</option>
                                    <option value = 'maranhão'>Maranhão</option>
                                    <option value = 'mato grosso'>Mato Grosso</option>
                                    <option value = 'mato grosso do sul'>Mato Grosso do Sul</option>
                                    <option value = 'minas gerais'>Minas Gerais</option>
                                    <option value = 'pará'>Pará</option>
                                    <option value = 'paraíba'>Paraíba</option>
                                    <option value = 'paraná'>Paraná</option>
                                    <option value = 'pernanbuco'>Pernambuco</option>
                                    <option value = 'piauí'>Piauí</option>
                                    <option value = 'rio de janeiro'>Rio de Janeiro</option>
                                    <option value = 'rio grande do norte'>Rio Grande do Norte</option>
                                    <option value = 'rio grande do sul'>Rio Grande do Sul</option>
                                    <option value = 'rondônica'>Rondônia</option>
                                    <option value = 'roraima'>Roraima</option>
                                    <option value = 'santa catarina'>Santana Catarina</option>
                                    <option value = 'são paulo'>São Paulo</option>
                                    <option value = 'sergipe'>Sergipe</option>
                                    <option value = 'tocantins'>Tocantins</option>
                                </select>
                            </span>
                        </section>
                        <span id="botoesContainer">
                            <Button type='button' cor='cancelar' isLoading={processando} onClick={avancarVoltarContratacao}>Voltar</Button>
                            <Button type='button' cor='salvar' isLoading={processando} onClick={ultimaEtapaContratacao}>Próxima Etapa</Button>
                        </span>
                    </div>}
                    {dadosContratacao && <div>
                        <h1 className='dadosOrganizacaoContratar'>DADOS DA CONTRATAÇÃO</h1>
                        <section>
                            <span>
                                <label htmlFor='dadosContratacao'>Valor Base</label>
                                <input
                                    id='dadosContratacao' 
                                    type="text" 
                                    value={'R$ 39,90'}
                                    disabled
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='usuariosAdicionais'>Usuários Adicionais</label>
                                <input
                                    id='usuariosAdicionais' 
                                    type="number"
                                    min={0} 
                                    value={usuariosAdicionais}
                                    onChange={(e) => setUsuariosAdicionais(Number(e.target.value))}
                                    required/>
                            </span>
                            <span>
                                <label htmlFor='valorTotalContratado'>Valor Final da Contratação</label>
                                <input
                                    id='valorTotalContratado' 
                                    type="text"
                                    value={moedaMascara(valorTotal)}
                                    disabled
                                    required/>
                            </span>
                        </section>
                        
                       
                        <span id="botoesContainer">
                            <Button type='button' cor='cancelar' isLoading={processando} onClick={ultimaEtapaContratacao}>Voltar</Button>
                            <Button type='submit' cor='salvar' isLoading={processando}>
                                {!processando && 'Finalizar Contratação'}
                                {processando && <Loader isLoading={processando}/>}
                            </Button>
                        </span>
                    </div>}
                </form>
            </section>}
        </div>
    )
}

export default ModalContratarSistema
