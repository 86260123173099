import { createSlice } from "@reduxjs/toolkit"
import atualizarDados from "../../../interfaces/atualizarDados"

const atualizarDadosReduce = createSlice ({
    name: 'atualizar_dados',
    initialState: {
        atualizar: false
    } as atualizarDados,
    reducers: {
        setAtualizarDados (state, action) {
            Object.assign (state, {
                atualizar: action.payload.atualizar
            })
        }
    }
})

export const { setAtualizarDados } = atualizarDadosReduce.actions

export default atualizarDadosReduce.reducer