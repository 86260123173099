import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { tarefasAtrasadas, tarefasPendentes, chamadosPendentes, atendimentosPendentes } from '../../services/meuPainel'
import meuPainelTarefasResposta from '../../interfaces/meuPainel'
import LoadingCirculo from '../LoadingCirculo'
import './resumotarefas.css'

function ResumoTarefas () {

    const navigate = useNavigate()

    const [carregando, setCarregando] = useState (true)
    const [alertaAtrasada, setAlertaAtrasada] = useState('semTarefasAtrasadas')
    const [tarefaAtrasadas, setTarefasAtrasadas] = useState<meuPainelTarefasResposta>()
    const [tarefaPendentes, setTarefasPendentes] = useState<meuPainelTarefasResposta>()
    const [chamadoPendentes, setChamadosPendentes] = useState<meuPainelTarefasResposta>()
    const [atendimentoPendentes, setAtendimentosPendentes] = useState<meuPainelTarefasResposta>()
    const [dados, setDados] = useState (false)

    const irTarefas = () => {

        return navigate('/tarefas');
    }

    useEffect(() => {

        const tarefas = tarefaAtrasadas?.quantidade

        if(!tarefas) {
            return
        }

        if (tarefas > 0) {
            return setAlertaAtrasada('tarefasAtrasadas')
        }

    }, [tarefaAtrasadas])


    useEffect (() => {

        const tarefas = async () => {

            try {

                const respotaTarefasAtrasadas = await tarefasAtrasadas ()
                const respotaTarefasPendentes = await tarefasPendentes ()
                const respostaChamadosPendentes = await chamadosPendentes ()
                const respostaAtendimentoPendente = await atendimentosPendentes ()
                setTarefasAtrasadas (respotaTarefasAtrasadas.data)
                setTarefasPendentes (respotaTarefasPendentes.data)
                setChamadosPendentes (respostaChamadosPendentes.data)
                setAtendimentosPendentes (respostaAtendimentoPendente.data)
                setCarregando (false)
                setDados (true)
                
            } catch (error:any) {

                if (error.status === 401) {
                    return navigate('/');
                }
                
            }
        }

        tarefas ()

    }, [navigate])

    return (
        <div id = 'resumoTarefas'>
            <p id = 'tarefasP'>QUADRO RESUMO</p>
            {carregando && <LoadingCirculo />}
            {dados && <div id = 'quadroResumo' onClick={() => irTarefas()}>
                <span id = {alertaAtrasada}>
                    <h1>{tarefaAtrasadas?.quantidade}</h1>
                    <p>Tarefas Atrasadas</p>
                </span>
                <span id = 'demaisTarefas'>
                    <span onClick={() => irTarefas()}>
                        <p>Tarefas Pendentes</p>
                        <h1>{tarefaPendentes?.quantidade}</h1>
                    </span>
                    <span onClick={() => irTarefas()}>
                        <p>Chamados Pendentes</p>
                        <h1>{chamadoPendentes?.quantidade}</h1>
                    </span>
                    <span onClick={() => irTarefas()}>
                        <p>Atendimentos Pendentes</p>
                        <h1>{atendimentoPendentes?.quantidade}</h1>
                    </span>
                </span>
            </div>}
        </div>
    )
}

export default ResumoTarefas