const converterNumero = (valor: any) => {

    if(valor === null) {
        return 0
    }

    valor = valor.toString()

    valor = valor.replace(/\D/g, '')
    
    valor = Number(valor/100)
    
    return valor

}

export default converterNumero