import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import UsuariosPage from "../../components/Usuarios"

function Usuarios () {

    useEffect (() => {
        document.title = 'Usuários | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <UsuariosPage />
        </DadosContainerDefault>
    )
}

export default Usuarios
