const dinheiroMascara = (valor: string): string => {
  valor = valor.replace(/\D/g, '');

  const valorNumerico = parseFloat(valor) / 100;

  const valorFormatado = isNaN(valorNumerico)
    ? ''
    : `R$ ${valorNumerico
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

  return valorFormatado;
};

export default dinheiroMascara