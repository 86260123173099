import BASEAPI from '../api/config'
import { cadastroPayload, paransListCadastros, cadastrosUpdatePayload } from '../../interfaces/cadastros'


export function novoCadastro (payload: cadastroPayload) {
    return BASEAPI.post ('/cadastros', payload)
}

export function listarCadastros (params: paransListCadastros) {
    return BASEAPI.get(`/cadastros?pagina=${params.pagina}&tipo=${params.tipo}&termo=${params.termo}&situacao=${params.situacao}`)
}

export function listarCobrancas (idCadastro: string) {
    return BASEAPI.get(`/cadastros/cobrancas/${idCadastro}`)
}

export function atualizarCadastro (payload: cadastrosUpdatePayload, idCadastro: string) {
    return BASEAPI.patch(`/cadastros/${idCadastro}`, payload)
}

