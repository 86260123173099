import { useState, FormEvent, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { lisatarLeads, deletarLead } from '../../services/leads'
import { listaLeadsRespostaPayload } from '../../interfaces/leads'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import ModalLeads from '../ModalLeads'
import ModalCadstro from '../ModalCadastros'
import ModalProcessos from '../ModalProcessos'
import FiltrosDefault from '../FiltrosDefault'
import Perfil from '../../middleware/perfil'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import apagarImg from '../../assets/img/excluir.png'
import erroImg from '../../assets/img/erroFechar.png'
import Notificacoes from '../Notificacoes'
import LoagindBarra from '../LoadingBarra'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import '../../styles/paginaListaDados.css'
import './leads.css'

function Leads () {

    const { atualizarDados, atualizarInformacoes, modal, modalExibir } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation ()
    const { pathname } = location

    const [leads, setLeads] = useState<listaLeadsRespostaPayload[]>([])
    const [leadSelecionado, setLeadSelecionado] = useState<listaLeadsRespostaPayload>()
    const [termo, setTermo] = useState('')
    const [situacao, setSituacao] = useState('')
    const [processando, setProcessando] = useState(true)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [leadExcluido, setLeadExcluido] = useState(false)
    const [erro, setErro] = useState(false)


    const exibirModalNovoLead = () => modalExibir('lead')

    const exibirModalDadosLead = (idLead: string, lead: listaLeadsRespostaPayload) => {

        modalExibir('lead')
        navigate(`${pathname}?idLead=${idLead}`)

        return setLeadSelecionado(lead)
    }

    const filtrarLeads = async (e: FormEvent) => {
        
        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        try {

            const params = {
                nome: termo,
                situacao: situacao
            }

            const resposta = await lisatarLeads(params)

            if (resposta.data.length === 0) {
                setLeads(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if(resposta.status === 200) {
                setLeads(resposta.data)
                setProcessando(false)

                return
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)
            
            return
        }

    }

    useEffect (() => {

        if(!modal.nome) setLeadSelecionado(undefined)

    }, [modal])


    const apagarLead = async (idLead: string) => {

        setProcessando(true)

        try {

            const resposta = await deletarLead(idLead)

            if(resposta.status === 200) {
                setProcessando(false)
                atualizarDados()
                setLeadExcluido(true)

                return setTimeout(() => {
                    setLeadExcluido(false)
                }, 2500)
            }
            
        } catch (error) {

            setProcessando(false)
            return setErro(true)
        }
    }

    useEffect(() => {

        const buscarLeads = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            try {

                const params = {
                    nome: '',
                    situacao: ''
                }

                const resposta = await lisatarLeads(params)

                if (resposta.data.length === 0) {
                    setLeads(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if(resposta.status === 200) {
                    setLeads(resposta.data)
                    setProcessando(false)

                    return
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)
                
                return
            }
        }

        buscarLeads()

    }, [atualizarInformacoes])

    return (
        <div id='paginaContainer'>
            {modal.nome === 'lead' && <ModalLeads lead={leadSelecionado} />}
            {modal.nome === 'cadastro' && <ModalCadstro/>}
            {modal.nome === 'processo' && <ModalProcessos/>}
            {processando && <LoagindBarra />}
            {leadExcluido && <Notificacoes icone={confirmacaoImg} texto='Lead Deletado com Sucesso' cor='sucesso' />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {erro && <Notificacoes texto='Ocorreu um erro ao processar a solicitação, favor tente novamente!' icone={erroImg} cor='erro'/>}
            <FiltrosDefault placeholder='Pesquise pelo nome do lead' termo={termo} situacao={situacao} onChangeTermo={(e) => setTermo(e.target.value)} tipo='lead' onChangeSituacao={(e) => setSituacao(e.target.value)} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Sem Contato', valor: 'Novo'}, {nome: 'Em Negociação', valor: 'Em Negociação'}, {nome: 'Conquistado', valor: 'Conquistado'}, {nome: 'Perdido', valor: 'Perdido'}]} onSubmit={filtrarLeads} acaoBtn={exibirModalNovoLead} genero='masculino' isLoading={processando}/>

            <section id='fundoContainerPagina'>
                <section id='leadsContainer'>
                    <span>
                        <h1>Novo</h1>
                        <section className='leadContainer'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th align='left' className='ladoEsquerdoContent'>Nome</th>
                                        <th id='telefoneLead' align='left'>Telefone</th>
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leads.filter(lead => lead.situacao === 'Novo').map((lead) => (
                                        <tr key={lead.id}>
                                            <td className='primeiraColuna' onClick={() => exibirModalDadosLead(lead.id, lead)}>{lead.nome}</td>
                                            <td id='telefoneLead' onClick={() => exibirModalDadosLead(lead.id, lead)}>{lead.telefone}</td>
                                            <td id='ladoDireitoContent' align='center'>
                                                <Perfil perfil={['master']}>
                                                    <img id='apagarIconeLead' title='Excluir Lead' src={apagarImg} alt='' onClick={() => apagarLead(lead.id)}/>
                                                </Perfil>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </span>
                    <span>
                        <h1>Em Negociação</h1>
                        <section className='leadContainer'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th align='left' className='ladoEsquerdoContent'>Nome</th>
                                        <th id='telefoneLead' align='left'>Telefone</th>
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leads.filter(lead => lead.situacao === 'Em Negociação').map((lead) => (
                                        <tr key={lead.id}>
                                            <td className='primeiraColuna' onClick={() => exibirModalDadosLead(lead.id, lead)}>{lead.nome}</td>
                                            <td id='telefoneLead' onClick={() => exibirModalDadosLead(lead.id, lead)}>{lead.telefone}</td>
                                            <td id='ladoDireitoContent' align='center'>
                                                <Perfil perfil={['master']}>
                                                    <img id='apagarIconeLead' title='Excluir Lead' src={apagarImg} alt='' onClick={() => apagarLead(lead.id)}/>
                                                </Perfil>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </span>
                    <span>
                        <h1>Conquistado/Perdido</h1>
                        <section className='leadContainer'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th align='left' className='ladoEsquerdoContent'>Nome</th>
                                        <th id='telefoneLead' align='left'>Telefone</th>
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leads.filter(lead => lead.situacao === 'Conquistado' || lead.situacao === 'Perdido').map((lead) => (
                                        <tr key={lead.id} className = {`lead${lead.situacao}`}>
                                            <td className='primeiraColuna' onClick={() => exibirModalDadosLead(lead.id, lead)}>{lead.nome}</td>
                                            <td id='telefoneLead' onClick={() => exibirModalDadosLead(lead.id, lead)}>{lead.telefone}</td>
                                            <td id='ladoDireitoContent' align='center'>
                                                <Perfil perfil={['master']}>
                                                    <img id='apagarIconeLead' title='Excluir Lead' src={apagarImg} alt='' onClick={() => apagarLead(lead.id)}/>
                                                </Perfil>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </span>
                </section>
            </section>
        </div>
    )
}

export default Leads