import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, FormEvent, useContext } from 'react'
import { ModalEstadoContext } from "../../contexts/ModalEstado"
import { setOcultarModalRecuperarSenha } from '../../store/modules/modalRecuperarSenha'
import { recuperarSenha, novaSenha, buscarOrganizacoes } from '../../services/auth'
import { modalOrganizacoesUsuario } from "../../interfaces/auth"
import { Button, Loader } from '../Styles'
import ModalOrganizacoesUsuario from "../ModalOrganizacoesUsuarios"
import ModalAlerta from '../ModalAlerta'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import './recuperarsenha.css'

function ModalRecuperarSenha () {

    const dispatch = useDispatch ()
    const navigate = useNavigate()
    const { pathname, search } = useLocation()

    const paramsUrl = new URLSearchParams(search)
    const organizacaoSelecionada = paramsUrl.get('organizacaoSelecionada')

    const { modal, modalExibir } = useContext(ModalEstadoContext)

    const [email, setEmail] = useState ('')
    const [token, setToken] = useState ('')
    const [senha, setSenha] = useState ('')
    const [organizacao, setOrganizacao] = useState<string | null>(null)
    const [organizacoesUsuario, setOrganizacoesUsuario] = useState<modalOrganizacoesUsuario[]>([])
    const [confirmarSenha, setConfirmarSenha] = useState ('')
    const [formToken, setFormToken] = useState (false)
    const [formSenha, setFormSenha] = useState (false)
    const [usuarioNaoEncontrato, setUsuarioNaoEntrato] = useState (false)
    const [processando, setProcessando] = useState(false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    
    function ocultarModal () {
        
        dispatch (setOcultarModalRecuperarSenha ({
            exibir: false
        }))
    }

    function ocultarModalConfirmacaoRecuperarSenha () {

        dispatch (setOcultarModalRecuperarSenha ({
            exibir: false
        }))

        return setConfirmacao (false)
    }

    const proximoPasso = async (e: FormEvent) => {
        e.preventDefault ()

        setProcessando(true)

        try {

            if(!organizacao) {
                return setProcessando(false)
            }

            const payload = {
                email,
                idOrganizacao: organizacao
            }

            const resposta = await recuperarSenha (payload)

            if (resposta.status === 200) {
                setProcessando(false)
                return setFormToken (true)
            }
            
        } catch (error: any) {

            if (error.response.status === 400) {
                setProcessando(false)
                setUsuarioNaoEntrato (true)

                return setTimeout (() => {
                    setUsuarioNaoEntrato (false)
                    dispatch (setOcultarModalRecuperarSenha ({
                        exibir: false
                    }))
                }, 2500)
            }
           
            return alert (error)
            
        }
    }

    const buscarOrganizacoesUser = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)
  
        try {
  
            const payload = {
              email
            }
  
            const resposta = await buscarOrganizacoes(payload)
  
            if(resposta.data.length === 1) {
        
                navigate(`${pathname}?organizacaoSelecionada=${resposta.data[0].idOrganizacao}`)
                
                setFormToken (true)

                const payload = {
                    email,
                    idOrganizacao: resposta.data[0].idOrganizacao
                }
    
                await recuperarSenha (payload)
                
                
                return setProcessando(false)
            }
  
            modalExibir('ModalOrganizacoesUsuario')
            setOrganizacoesUsuario(resposta.data)
  
            return setProcessando(false)
          
        } catch (error: any) {
  
          if (error.response.status === 404) {
            setProcessando(false)
            setUsuarioNaoEntrato(true)
    
            return setTimeout(() => {
                setUsuarioNaoEntrato(false)
            }, 5000)
          }
        }
    }

    const digitarSenha = async (e: FormEvent) => {
        e.preventDefault ()

        setFormToken (false)
        return setFormSenha (true)
    }

    const cadastrarNovaSenha = async (e: FormEvent) => {
        e.preventDefault()

        setProcessando(true)

        if (senha !== confirmarSenha) {
            return alert ('A senha não confere. Digite Novamente')
        }

        try {

            if(!organizacao) {
                return setProcessando(false)
            }

            const payload = {
                email,
                senha,
                token,
                idOrganizacao: organizacao
            }

            const resposta = await novaSenha (payload)

            if (resposta.status === 200) {
                setConfirmacao (true)
                setProcessando(false)
            }
            
        } catch (error: any) {

            if (error.response.status === 400 || 401 || 402) {
                setErroOperacao (true)
                setProcessando(false)

                return setTimeout (() => {
                    setErroOperacao (false)
                    dispatch (setOcultarModalRecuperarSenha ({
                        exibir: false
                    }))
                }, 3500)
            }
           
            return alert (error)
            
        }
    }

    useEffect(() => {

        const identificarOrganizacao = () => {
    
          const organizacao  = organizacaoSelecionada
    
          if(!organizacao) {
            return
          }
    
          return setOrganizacao(organizacao)
        }
    
        identificarOrganizacao()
    
      },[organizacaoSelecionada])


    return (
        <>
            {modal.nome === 'ModalOrganizacoesUsuario' && <ModalOrganizacoesUsuario dados={organizacoesUsuario}/>}
            {confirmacao && <ModalAlerta texto='Senha Atualizada com Sucesso' icone={confirmacaoImg} btnTitulo='Continuar' cor='continuarSucesso' acao={ocultarModalConfirmacaoRecuperarSenha}/>}
            {erroOperacao && <ModalAlerta texto='Dados não confere. Tente Novamente!' icone={erroImg} btnTitulo='Tentar Novamente' cor='continuarErro'/>}  
            {usuarioNaoEncontrato && <ModalAlerta texto='Usuário não Encontrato!' icone={erroImg} btnTitulo='Tentar Novamente' cor='continuarErro'/> }      
            <div id = 'fundo_modal_recuperar_senha'>
                <div id="fundo_recuperar_senha">
                    <form id = 'form_nova_venda' onSubmit={organizacao === null ? buscarOrganizacoesUser : proximoPasso}>
                        <div id="div_recuperar_senha">
                            <input 
                            type = 'email'
                            required
                            onChange={(e) => setEmail (e.target.value.toLowerCase())}/>
                            <span>Digite seu e-mail</span>
                        </div>

                        <div id="recuperar_senha_botao">
                            <div>
                                <Button type='button' cor='cancelar' isLoading={false} onClick={ocultarModal}>Cancelar</Button>
                            </div>
                            <div>
                                <Button type='submit' cor='enviar' isLoading={false}>Próximo</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {formToken && <div id = 'fundo_modal_recuperar_senha'>
                <div id="fundo_recuperar_senha">
                    <form id = 'form_nova_venda' onSubmit={(e) => digitarSenha (e)}>
                        <div id="div_recuperar_senha">
                            <input 
                            type = 'number'
                            required
                            onChange={(e) => setToken (e.target.value)}/>
                            <span>Digite o token recebido por e-mail</span>
                        </div>

                        <div id="recuperar_senha_botao">
                            <div>
                                <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                            </div>
                            <div>
                                <Button type='submit' cor='enviar' isLoading={processando}>
                                    {!processando && 'Próximo'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}

            {formSenha && <div id = 'fundo_modal_recuperar_senha'>
                <div id="fundo_recuperar_senha">
                    <form id = 'form_nova_venda' onSubmit={(e) => cadastrarNovaSenha (e)}>
                        <div id="div_recuperar_senha">
                            <input 
                            type = 'password'
                            minLength= {8} 
                            maxLength= {8} 
                            required
                            onChange={(e) => setSenha (e.target.value)}/>
                            <span>Digite a nova senha</span>
                        </div>

                        <div id="div_recuperar_senha">
                            <input 
                            type = 'password'
                            minLength= {8} 
                            maxLength= {8}
                            required
                            onChange={(e) => setConfirmarSenha (e.target.value)}/>
                            <span>Confirmar senha</span>
                        </div>

                        <div id="recuperar_senha_botao">
                            <div>
                                <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                            </div>
                            <div>
                                <Button type='submit' cor='enviar' isLoading={processando}>
                                    {!processando && 'Finalizar'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </>
    )
}

export default ModalRecuperarSenha