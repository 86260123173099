import { useEffect } from "react";
import Leads from "../../components/Leads";
import { DadosContainerDefault } from "../../components/Styles";

function LeadsPage () {

    useEffect (() => {
        document.title = 'Leads | Gesttor'
    }, [])


    return (
        <DadosContainerDefault>
            <Leads />
        </DadosContainerDefault>
    )
}

export default LeadsPage