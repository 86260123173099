import { useState, FormEvent, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { clausulasContratoListaResposta, modalModeloContrato, topicosContratosListaResposta } from '../../interfaces/contratosModelos'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { contratoModelo, atualizarContratoModelo, cadastrarTopico, listarTopicos, cadastrarClausula, listarClausulas } from '../../services/contratosModelos'
import { Button, Loader } from '../Styles'
import Notificacoes from '../Notificacoes'
import documentoImg from '../../assets/img/documentos.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import './modeloscontratos.css'
import '../../styles/modal.css'

function ModalContratosModelos({ modeloContrato }: modalModeloContrato) {

    const navigate = useNavigate()
    const location = useLocation()

    const { atualizarDados, modalExibir, atualizarInformacoes } = useContext(ModalEstadoContext)

    const { pathname, search } = location

    const [descricao, setDescricao] = useState(modeloContrato?.descricao)
    const [topicos, setTopicos] = useState<topicosContratosListaResposta []>([])
    const [clausulas, setClausulas] = useState<clausulasContratoListaResposta []>([])
    const [situacao, setSituacao] = useState(modeloContrato?.situacao)
    const [processando, setProcessando] = useState(false)
    const [confirmacao, setConfirmacao] = useState(false)
    const [erro, setErro] = useState(false)

    const params = new URLSearchParams(search)
    const idModelo = params.get('idModelo')

    function ocultarModal() {

        navigate(`${pathname}`)

        return modalExibir('')
    }

    const cadastrarModelo = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(!descricao) return setProcessando(false)

        try {

            const payload = {
                descricao
            }

            const resposta = await contratoModelo(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
            
        }
    }

    const updateModeloContrato = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const idContrato = params.get('idModelo')

        if(!idContrato || !descricao || situacao === undefined) {
            setProcessando(false)
            return
        }

        try {

            const payload = {
                descricao,
                situacao
            }

            const resposta = await atualizarContratoModelo(idContrato, payload)

            if(resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
            
        }
    }

    const novoTopico = async () => {

        setProcessando(true)
        const descricao = window.prompt('Insira um Tópico')

        if(!idModelo || !descricao) return setProcessando(false)

        try {


            const payload = {
                descricao
            }

            const resposta = await cadastrarTopico(idModelo, payload)

            if(resposta.status === 201) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
            
        }
    }

    const novoClausula = async (idContratoModelo: string, idContratoTopico: string) => {

        setProcessando(true)
        const descricao = window.prompt('Insira o Texto da Cláusula')

        if(!idModelo || !descricao) return setProcessando(false)

        try {


            const payload = {
                idContratoModelo,
                idContratoTopico,
                descricao
            }

            const resposta = await cadastrarClausula(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
            
        }
    }

    useEffect(() => {

        const buscarDados = async () => {
    
            if(!idModelo) return setProcessando(false)

            try {

                const promisses = [
                    await listarTopicos(idModelo),
                    await listarClausulas(idModelo)
                ]

                const resposta = await Promise.all(promisses)

                const topitos = resposta[0]
                const clausulas = resposta[1]

                if(topitos.status === 200) {
                    setProcessando(false)
                    setTopicos(topitos.data)
                }

                if(clausulas.status === 200) {
                    setProcessando(false)
                    setClausulas(clausulas.data)
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 5000)
                
            }
        }

        buscarDados ()

    }, [atualizarInformacoes, idModelo])


    return (
        <div id='fundoModal'>
            {confirmacao && <Notificacoes texto={modeloContrato ? 'Modelo Atualizado com Sucesso' : 'Modelo Criado com Sucesso'} icone={confirmacaoImg} cor='sucesso' />}
            {erro && <Notificacoes texto='Ocorreu um erro ao processar a solicitação!' icone={erroImg} cor='erro' />}
            <section id={modeloContrato ? 'containerModalNovo' : ''} className={modeloContrato ? 'containerModalDocumento' : 'containerModalCentro'}>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={documentoImg} alt='' />
                    <p>{!modeloContrato ? 'Novo Modelo' : 'Vizualizar Modelo'}</p>
                </span>
                <form id='formModalNovo' onSubmit={modeloContrato ? updateModeloContrato : cadastrarModelo}> 
                    <section>
                        <span>
                            <label htmlFor="tituloDocumento">TÍtulo do Modelo</label>
                            <input
                                id='tituloDocumento'
                                value={descricao}
                                placeholder='Contrato de Prestação de Serviço'
                                onChange={(e) => setDescricao(e.target.value)}
                                type='text'
                                required />
                        </span>

                        {modeloContrato && <span>
                            <select value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                <option value="Ativo">Ativo</option>
                                <option value="Inativo">Inativo</option>
                            </select>
                        </span>}    
                    </section>
                    {modeloContrato && <section className='topicoAdicionarContainer'>
                        <Button cor='salvar' type='submit' isLoading={processando} onClick={novoTopico}>
                            {!processando && 'Adicionar Tópicos'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </section>}

                    {modeloContrato && <div className='topicosModelosContratosContainer'>
                        {topicos.map((topico) => (
                            <div className='topicosModelosContratosContent'>
                                <div key={topico.id} className='topicosModelosContratosSection'>
                                    {topico.descricao}
                                    <Button cor='novo' type='submit' isLoading={processando} onClick={() => 
                                        novoClausula(topico.idContratoModelo, topico.id)}>
                                        {!processando && 'Adicionar Cláusulas'}
                                        {processando && <Loader isLoading={processando} />}
                                    </Button>
                                </div>
                                <span>
                                    {clausulas.filter(clausula => clausula.idContratoTopico === topico.id).map((clausula) => (
                                        <p>{clausula.descricao}</p>
                                    ))}
                                </span>
                            </div>
                        ))}
                    </div>}

                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Modelo'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalContratosModelos