import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import TasksPagina from "../../components/Tasks"

function Tarefas () {

    useEffect (() => {
        document.title = 'Tarefas | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <TasksPagina />
        </DadosContainerDefault>
    )
}

export default Tarefas
