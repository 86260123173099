import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ultimosAtendimentos } from '../../services/meuPainel'
import UltimosAtendimentoResposta from '../../interfaces/ultimosAtendimentos'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import LoadingCirculo from '../LoadingCirculo'
import './ultimosatendimentos.css'

function UltimosAtendimentos () {

    const navigate = useNavigate()

    const [carregando, setCarregando] = useState (true)
    const [atendimentos, setAtendimentos] = useState<UltimosAtendimentoResposta []> ([])
    const [dados, setDados] = useState (false)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const atendimentos = async () => {

            try {

                const resposta = await ultimosAtendimentos ()

                setCarregando (false)
                setAtendimentos (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setDados (true)
                
            } catch (error: any) {

                if (error.status === 401) {
                    return navigate('/');
                }
            }
        }

        atendimentos ()

    }, [navigate])


    return (
        
        <div id = 'graficoUltimoAtendimento'>
            <p id = 'ultimoAtendimentoP'>ÚLTIMAS ATIVIDADES CONCLUÍDAS</p>
            {dados && <section>
                <table id = 'tabelaUltimosAtendimentos'>
                    <thead>
                        <tr id = 'atendimentoCabecalho'>
                            <th id='clienteNomeAtendimentosConcluidos' align='left'>CLIENTE</th>
                            <th align='left'>TÍTULO</th>
                            <th align='center'>TIPO</th>
                        </tr>
                    </thead>

                    <tbody>
                        {atendimentos.map ((atendimento) => (
                            <tr key={atendimento.id} id = 'listaAtendimentos'>
                                <td id='clienteNomeAtendimentosConcluidos' align='left'>{atendimento.cliente.nomeFantasia.toUpperCase()}</td>
                                <td align='left'>{atendimento.titulo.toUpperCase()}</td>
                                <td align='center'>{atendimento.tipo.toUpperCase()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>}
            {carregando && <LoadingCirculo />}
            {semDados && <NenhumDadoGrafico texto='Nenhuma atividade recente' subtexto='foi concluída' />}
        </div>
    )
}

export default UltimosAtendimentos