import { useState, useEffect } from 'react'
import Perfil from '../../middleware/perfil'
import {clientesAtivos, clientesMes, clientesInativos, clientesTotais } from '../../services/dashboard'
import clientesAtivosImg from '../../assets/img/clientesAtivos.png'
import clientesMesImg from '../../assets/img/clientesNovos.png'
import clientesInativosImg from '../../assets/img/clientesInativos.png'
import clientesTotaisImg from '../../assets/img/clientesTotais.png' 
import GraficoClienteCidades from '../GraficoClienteCidade'
import GraficoLeadsFormaConhecimento from '../GraficoLeadsFormaConhecimento'
import GraficoNpsOrganizacao from '../GraficoNpsOrganizacao'
import GraficoClientesAtivosoMes from '../GraficoNovosClientesMes'
import LoadingCirculo from '../LoadingCirculo'
import './dashboard.css'


function Dashboard () {


    const [carregando, setCarregando] = useState(false)

    const [clientesAtivosCarregando, setClientesAtivosCarregando] = useState (false)
    const [clientesAtivosQuantidade, setClientesAtivosQuantidade] = useState <string> ('')

    const [clientesMesCarregando, setClientesMesCarregando] = useState (false)
    const [clientesMesQuantidade, setClientesMesQuantidade] = useState <string> ('')

    const [clientesInativoCarregando, setClientesInativoCarregando] = useState (false)
    const [clientesInativosQuantidade, setClientesInativoQuantidade] = useState <string> ('')

    const [clientesTotaisCarregando, setClientesTotaisCarregando] = useState (false)
    const [clientesTotaisQuantidade, setClientesTotaisQuantidade] = useState <string> ('')


    useEffect (() => {

        const clientesAtivosDashboard = async () => {

            
            setCarregando (true)
            try {
                const resposta = await clientesAtivos ()
                setClientesAtivosQuantidade (resposta.data)
                setCarregando (false)
                setClientesAtivosCarregando (true)
        
            } catch (error: any) {
    
            }
        }

        clientesAtivosDashboard ()
        
    }, [setClientesAtivosQuantidade])

    useEffect (() => {

        const clientesMesDashboard = async () => {
            
            setCarregando (true)
            try {
                const resposta = await clientesMes ()
                setClientesMesQuantidade (resposta.data)
                setClientesMesCarregando (true)
                setCarregando (false)
        
            } catch (error: any) {
                
            }
        }

        clientesMesDashboard ()
        
    }, [setClientesMesQuantidade])

    useEffect (() => {

        const clientesInativoDashboard = async () => {
        

            setCarregando (true)
            try {
                const resposta = await clientesInativos ()
                setClientesInativoQuantidade (resposta.data)
                setClientesInativoCarregando (true)
                setCarregando (false)
        
            } catch (error: any) {
    
            }
        }

        clientesInativoDashboard ()
        
    }, [setClientesInativoQuantidade])

    useEffect (() => {

        const clientesTotaisDashboard = async () => {
            
            setCarregando (true)

            try {
                const resposta = await clientesTotais ()
                setClientesTotaisQuantidade (resposta.data)
                setClientesTotaisCarregando (true)
                setCarregando (false)
        
            } catch (error: any) {
                
            }
        }

        clientesTotaisDashboard ()
        
    }, [setClientesTotaisQuantidade])

    return (
        <Perfil perfil= {['master']}>
            <div id = 'dashboardContainer'>
                <div id = 'dadosDashboard'>
                    <div id = 'quantidadeClientes'>
                        {carregando && <LoadingCirculo />}
                        {clientesAtivosCarregando && <h1>{clientesAtivosQuantidade}</h1>}
                        <p>Clientes Ativos</p>
                    </div>
                    <div>
                        <img src= {clientesAtivosImg} alt= ''></img>
                    </div>
                </div>

                <div id = 'dadosDashboard'>
                    <div id = 'quantidadeClientes'>
                        {carregando && <LoadingCirculo />}
                        {clientesMesCarregando && <h1>{clientesMesQuantidade}</h1>}
                        <p>Clientes do Mês</p>
                    </div>
                    <div>
                        <img id = 'clientes_mes' src= {clientesMesImg} alt="Clientes ativos do Hg" width="90px"></img>
                    </div>
                </div>  

                <div id = 'dadosDashboard'>
                    <div id = 'quantidadeClientes'>
                        {carregando && <LoadingCirculo />}
                        {clientesInativoCarregando && <h1>{clientesInativosQuantidade}</h1>}
                        <p>Clientes Inativos</p>
                    </div>
                    <div>
                        <img id = 'clientes_inativos' src= {clientesInativosImg} alt="Clientes ativos do Hg" width="90px"></img>
                    </div>
                </div> 

                <div id = 'dadosDashboard'>
                    <div id = 'quantidadeClientes'>
                        {carregando && <LoadingCirculo />}
                        {clientesTotaisCarregando && <h1>{clientesTotaisQuantidade}</h1>}
                        <p>Clientes Totais</p>
                    </div>
                    <div>
                        <img id = 'clientes_totais' src= {clientesTotaisImg} alt="Clientes ativos do Hg" width="90px"></img>
                    </div>
                </div>
                <div id = 'graficosDashboard'>
                    <GraficoClienteCidades />
                    <GraficoLeadsFormaConhecimento />
                    <GraficoNpsOrganizacao />
                    <GraficoClientesAtivosoMes />
                </div>
            </div>
        </Perfil>
    )
}

export default Dashboard