import { useContext } from 'react'
import { Button } from '../Styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { modalOrganizacaoUsuarioResposta } from '../../interfaces/auth'
import './modalorganizacoes.css'

function ModalOrganizacoesUsuario (organizacaoUsuario: modalOrganizacaoUsuarioResposta) {

    const { modalExibir } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location

    const ocultarModal = () => modalExibir('')

    const selecionarOrganizacao = (idOrganizacao: string) => {

        navigate(`${pathname}?organizacaoSelecionada=${idOrganizacao}`)

        return modalExibir('')
    }


    return (

        <div className='modalUsuarioOrganizacao'>
            <section className = 'modalUsuarioOrganizacaoContainer'>
                <span className='modalUsuarioOrganizacaoContent'>
                    {organizacaoUsuario.dados.map((dado) => (
                        <div key={Math.random()} onClick={() => selecionarOrganizacao(dado.idOrganizacao)}>
                            <img src={dado.organizaco.avatar} alt=''/>
                            <p>{dado.organizaco.nomeFantasia}</p>
                        </div>
                    ))}
                </span>
                <Button type='button' cor='cancelar' isLoading={false} onClick={ocultarModal}>Cancelar Operação</Button>
            </section>
        </div>
    )
}

export default ModalOrganizacoesUsuario