import { Navigate } from "react-router-dom"
import { Autenticacao } from "../Token"
import propsComponentePrivado from "../../interfaces/propsComponentePrivado"

const ComponenePrivado = ( props: propsComponentePrivado)=> {

    const usuario = Autenticacao ()

    if (!usuario) {
        return <Navigate to = '/'></Navigate>
    }
    
    return props.children

}

export default ComponenePrivado