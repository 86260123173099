import { useState } from 'react'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import Perfil from '../../middleware/perfil'
import '../../styles/menuopcoes.css'

function MenuRelatorio (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (
        <Perfil perfil={['master']}>
            <div>
                <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                    {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                    <p id = 'tituloMenu'>{props.titulo}</p>
                </div>
                <div id = 'submenuOpcoes'>
                    {subMenu && <SubMenuOpcoes redirect = {'/dre'} texto = 'Demostração de Resultado' />}
                </div>
            </div>
        </Perfil>
    )
}

export default MenuRelatorio