import { useState, FormEvent, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { lisatarCentros, atualizarCentro } from '../../services/centros'
import { listaCentrosRespostaPayload } from '../../interfaces/centros'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import ModalCentros from '../ModalCentros'
import FiltrosDefault from '../FiltrosDefault'
import erroImg from '../../assets/img/erroFechar.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import Notificacoes from '../Notificacoes'
import ModalAlerta from '../ModalAlerta'
import LoagindBarra from '../LoadingBarra'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import DivSituacao from '../DivSituacao'
import ToogleDefault from '../ToogleDafault'
import '../../styles/paginaListaDados.css'
import './centros.css'

function Centros () {

    const { atualizarDados, atualizarInformacoes, exibirModal, modalEstado } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation ()
    const { pathname } = location

    const [centros, setCentros] = useState<listaCentrosRespostaPayload[]>([])
    const [centroSelecionado, setCentroSelecionado] = useState<listaCentrosRespostaPayload>()
    const [novoCentro, setNovoCentro] = useState(false)
    const [descricao, setDescricao] = useState('')
    const [situacao, setSituacao] = useState('ativo')
    const [processando, setProcessando] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [confirmacao, setConfirmacao] = useState(false)
    const [erro, setErro] = useState(false)


    const ocultarModal = () => {

        return setErro(false)
    }

    const exibirModalNovoLead = () => exibirModal()


    const exibirModalDadosCentro = (centro: listaCentrosRespostaPayload, idCentro: string) => {

        exibirModal()
        navigate(`${pathname}?idCentro=${idCentro}`)

        return setCentroSelecionado(centro)
    }

    const filtrarCentros = async (e: FormEvent) => {
        
        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        try {

            const params = {
                descricao,
                situacao: situacao
            }

            const resposta = await lisatarCentros(params)

            if (resposta.data.length === 0) {
                setCentros(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if(resposta.status === 200) {
                setCentros(resposta.data)
                setProcessando(false)

                return
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)
            
            return
        }

    }

    const toggleCentro = async (idCentro: string, situacao: string, e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                situacao: situacao === 'ativo' ? 'inativo' : 'ativo'
            }

            const resposta = await atualizarCentro(idCentro, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDados()
                setConfirmacao(true)

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 2500)
            }

        } catch (error) {

            setProcessando(false)

            return setErro(true)
        }
    }

    useEffect(() => {

        if(!modalEstado) setCentroSelecionado(undefined)
        return setNovoCentro(modalEstado)

    }, [modalEstado])

    useEffect(() => {

        const buscarCentros = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativo'
                }

                const resposta = await lisatarCentros(params)

                if (resposta.data.length === 0) {
                    setCentros(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if(resposta.status === 200) {
                    setCentros(resposta.data)
                    setProcessando(false)

                    return
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)
                
                return
            }
        }

        buscarCentros()

    }, [atualizarInformacoes])

    return (
        <div id='paginaContainer'>
            {novoCentro && <ModalCentros centro={centroSelecionado} />}
            {processando && <LoagindBarra />}
            {confirmacao && <Notificacoes icone={confirmacaoImg} texto='Dados Atualizados com Sucesso' cor='sucesso' />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação, favor tente novamente!' icone={erroImg} btnTitulo='Tentar Novamente' cor='continuarErro' acao={ocultarModal} />}
            <FiltrosDefault placeholder='Pesquise pela descrição do centro' termo={descricao} situacao={situacao} onChangeTermo={(e) => setDescricao(e.target.value)} tipo='centro' onChangeSituacao={(e) => setSituacao(e.target.value)} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Ativos', valor: 'ativo'}, {nome: 'Inativos', valor: 'inativo'}]} onSubmit={filtrarCentros} acaoBtn={exibirModalNovoLead} genero='masculino' isLoading={processando}/>

            <section id='fundoContainerPagina'>
                <section id='centrosContainer'>
                    <span>
                        <h1>Centros de Resultados</h1>
                        <section className='centrosContainer'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th align='left' className='ladoEsquerdoContent'>Descrição</th>
                                        <th align='center'>Situação</th>
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {centros.filter(centro => centro.tipo === 'Centro de Resultado').map((centro) => (
                                        <tr key={centro.id}>
                                            <td className='primeiraColuna' onClick={() => exibirModalDadosCentro(centro, centro.id)}>{centro.descricao}</td>
                                            <td align='center' onClick={() => exibirModalDadosCentro(centro, centro.id)}>
                                                <DivSituacao texto={`${centro.situacao.charAt(0).toUpperCase()}${centro.situacao.substring(1)}`}  cor={centro.situacao}/>
                                            </td>
                                            <td id='ladoDireitoContent' align='center' onClick={(e) => toggleCentro(centro.id, centro.situacao, e)}>
                                                <ToogleDefault id={centro.id} checked={() => centro.situacao === 'ativo' ? true : false}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </span>
                    <span>
                        <h1>Centros de Custos</h1>
                        <section className='centrosContainer'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th align='left' className='ladoEsquerdoContent'>Descrição</th>
                                        <th id='telefoneLead' align='center'>Situação</th>
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {centros.filter(centro => centro.tipo === 'Centro de Custo').map((centro) => (
                                        <tr key={centro.id}>
                                            <td className='primeiraColuna' onClick={() => exibirModalDadosCentro(centro, centro.id)}>{centro.descricao}</td>
                                            <td align='center' onClick={() => exibirModalDadosCentro(centro, centro.id)}>
                                                <DivSituacao texto={`${centro.situacao.charAt(0).toUpperCase()}${centro.situacao.substring(1)}`}  cor={centro.situacao}/>
                                            </td>
                                            <td id='ladoDireitoContent' align='center' onClick={(e) => toggleCentro(centro.id, centro.situacao, e)}>
                                                <ToogleDefault id={centro.id} checked={() => centro.situacao === 'ativo' ? true : false}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </span>
                    <span>
                        <h1>Centros Especiais</h1>
                        <section className='centrosContainer'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th align='left' className='ladoEsquerdoContent'>Descrição</th>
                                        <th id='telefoneLead' align='center'>Situação</th>
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {centros.filter(centro => centro.tipo !== 'Centro de Resultado' && centro.tipo !== 'Centro de Custo').map((centro) => (
                                        <tr key={centro.id}>
                                            <td className='primeiraColuna' onClick={() => exibirModalDadosCentro(centro, centro.id)}>{centro.descricao}</td>
                                            <td align='center' onClick={() => exibirModalDadosCentro(centro, centro.id)}>
                                                <DivSituacao texto={`${centro.situacao.charAt(0).toUpperCase()}${centro.situacao.substring(1)}`}  cor={centro.situacao}/>
                                            </td>
                                            <td id='ladoDireitoContent' align='center' onClick={(e) => toggleCentro(centro.id, centro.situacao, e)}>
                                                <ToogleDefault id={centro.id} checked={() => centro.situacao === 'ativo' ? true : false}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </span>
                </section>
            </section>
        </div>
    )
}

export default Centros