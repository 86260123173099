import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import Cadastros from "../../components/Cadastros"

function CadastrosPage () {

    useEffect (() => {
        document.title = 'Cadastros | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <Cadastros />
        </DadosContainerDefault>
    )
}

export default CadastrosPage