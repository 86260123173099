import { useState, useEffect, useContext, FormEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { listarTarefas, deletarTarefa } from '../../services/tasks'
import { listarUsuariosOrganizacao } from '../../services/usuarios'
import { tarefasListaResposta } from '../../interfaces/tasks'
import { usuariosRespostaPayload } from '../../interfaces/usuarios'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { Button, Loader } from '../Styles'
import ModalTarefas from '../ModalTarefas'
import Perfil from '../../middleware/perfil'
import FiltrosDefault from '../FiltrosDefault'
import avatarDefault from '../../assets/img/avatarDefault.png'
import apagarImg from '../../assets/img/excluir.png'
import DivSituacao from '../DivSituacao'
import maisFiltrosImg from '../../assets/img/maisFiltros.png'
import LoagindBarra from '../LoadingBarra'
import LoadingCirculo from '../LoadingCirculo'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import '../../styles/paginaListaDados.css'
import './tasks.css'

function Tasks () {

    const { modalEstado, exibirModal, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation()

    const { pathname, search } = location

    const data = new Date()

    const ano = data.getFullYear()
    const qtnDias = new Date (data.getFullYear(), data.getMonth() + 1, 0).getDate()
    const dataAnterior = new Date (new Date().setDate(new Date().getDate() - 60))
    
    const dataInicial = `${dataAnterior.getFullYear()}-${(dataAnterior.getMonth() + 1).toString().padStart(2, '0')}-01`
    const dataFinal = `${ano}-${(data.getMonth() + 1).toString().padStart(2, '0')}-${qtnDias}`

    const [tarefas, setTarefas] = useState<tarefasListaResposta[]>([])
    const [tarefaSelecionada, setTarefaSelecionada] = useState<tarefasListaResposta>()
    const [resposaveis, setResponsaveis] = useState<usuariosRespostaPayload[]>([])
    const [modalTarefas, setModaltarefas] = useState(false)
    const [maisFiltros, setMaisFiltros] = useState(false)
    const [maisTarefas, setMaisTarefas] = useState(false)
    const [pagina, setPagina] = useState(2)
    const [cliente, setCliente] = useState('')
    const [responsavel, setResponsavel] = useState('')
    const [titulo, setTitulo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [dataInicio, setDataInicio] = useState(dataInicial)
    const [dataTermino, setDataTermino] = useState(dataFinal)
    const [situacao, setSituacao] = useState('')
    const [tipo] = useState('tarefa')
    const [processando, setProcessando] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [excluirTarefa, setExluirTarefa] = useState(false)
    const [erro, setErro] = useState(false)

    const paramsUrl = new URLSearchParams(search)

    const minhasSolicitacoes = paramsUrl.get('minhasSolicitacoes')
    const tarefasEscritorio = paramsUrl.get('escritorio')
    const tarefasAutomaticas = paramsUrl.get('automaticas')

    const exibirModaltarefas = () => exibirModal()

    const modalMaisFiltros = () => setMaisFiltros(!maisFiltros)

    const scrollInfinito = (e: any) => {

        const { scrollTop, clientHeight, scrollHeight } = e.target;

        const posicaoScroll = (scrollTop/(scrollHeight - clientHeight)*100)

        if (posicaoScroll >= 90) {

            setPagina(prevtState => prevtState + 1)

            const buscarMaisTarefas = async () => {

                setMaisTarefas(true)

                const params = {
                    pagina,
                    titulo,
                    descricao,
                    cliente,
                    situacao,
                    dataInicio,
                    dataTermino,
                    responsavel,
                    minhasSolicitacoes,
                    tarefasEscritorio,
                    tarefasAutomaticas
                }

                try {

                    const resposta = await listarTarefas(params)

                    if (resposta.status === 200) {
                        setTarefas(prevDate => [...prevDate, ...resposta.data])
                        return setMaisTarefas(false)
                    }
            
                } catch (error) {
                    setMaisTarefas(false)
                    setErro(true)

                    return setTimeout(() => {
                        setErro(false)
                    }, 2000)
                }
            }

            buscarMaisTarefas()
        }
    }

    const exibirModalDadosTarefas = (tarefa: tarefasListaResposta, idTarefa: string) => {

        if (tarefasEscritorio) {
            
            navigate(`${pathname}?escritorio=true&idTarefa=${idTarefa}`)
            exibirModal()

            return setTarefaSelecionada(tarefa)
        }

        if(tarefasAutomaticas) {
            navigate(`${pathname}?automaticas=true&idTarefa=${idTarefa}`)
            exibirModal()

            return setTarefaSelecionada(tarefa)

        }

        if(minhasSolicitacoes) {
            navigate(`${pathname}?minhasSolicitacoes=true&idTarefa=${idTarefa}`)
            exibirModal()

            return setTarefaSelecionada(tarefa)
        }

        navigate(`${pathname}?idTarefa=${idTarefa}`)
        exibirModal()

        return setTarefaSelecionada(tarefa)
    }

    const buscarTarefas = async (e: FormEvent) => {

        e.preventDefault()
        setMaisFiltros(false)
        setNenhumResultado(false)
        setProcessando(true)

        const params = {
            pagina: 1,
            titulo,
            descricao,
            cliente,
            situacao,
            dataInicio,
            dataTermino,
            responsavel,
            minhasSolicitacoes,
            tarefasEscritorio,
            tarefasAutomaticas
        }

        try {

            const resposta = await listarTarefas(params)

            if (resposta.data.length === 0) {
                setTarefas(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setTarefas(resposta.data)

                return setProcessando(false)
            }
            
        } catch (error) {
            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
        }
    }

    const deletarTask = async (idTarefa: string) => {

        setProcessando(true)

        try {

            const resposta = await deletarTarefa(idTarefa)

            if(resposta.status === 200) {
                setProcessando(false)
                setExluirTarefa(true)
                atualizarDados()

                return setTimeout (() => {
                    setExluirTarefa(false)
                }, 2000)

            }
            
        } catch (error) {

            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
            
        }

    }

    useEffect (() => {
        
        setModaltarefas(modalEstado)

        if(!modalEstado) setTarefaSelecionada(undefined)

    }, [modalEstado])

    useEffect(() => {
        
        const buscarTarefas = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            const params = {
                pagina: 1,
                titulo,
                descricao,
                cliente,
                situacao,
                responsavel,
                dataInicio: dataInicial,
                dataTermino: dataFinal,
                minhasSolicitacoes,
                tarefasEscritorio,
                tarefasAutomaticas
            }

            try {

                const resposta = await listarTarefas(params)

                if (resposta.data.length === 0) {
                    setTarefas(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {
                    setTarefas(resposta.data)

                    return setProcessando(false)
                }
                
            } catch (error) {
                
            }
        }

        buscarTarefas ()

    }, [dataInicial, dataFinal, atualizarInformacoes, tarefasAutomaticas, tarefasEscritorio, minhasSolicitacoes])

    useEffect(() => {
        
        const buscarResponsaveis = async () => {

            try {

                const params = {
                    termo: '',
                    situacao: 'ativo'
                }

                const resposta = await listarUsuariosOrganizacao(params)

                if (resposta.status === 200) {
                    return setResponsaveis(resposta.data)
                }
                
            } catch (error) {

                return alert('Falha ao Listar os Responsáveis')
                
            }
        }

        buscarResponsaveis ()

    }, [])


    return (
        <div id='paginaContainer'>
            {modalTarefas && <ModalTarefas tarefa={tarefaSelecionada}/>}
            {processando && <LoagindBarra />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {excluirTarefa && <Notificacoes icone={confirmacaoImg} texto='Tarefa Exclúida com Sucesso' cor='sucesso' />}
            {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação. Tente Novamente!' cor='erro'/>}
            <FiltrosDefault termo={cliente} situacao={situacao} placeholder='Pesquise pelo nome fantasia do cliente' tipo={tipo} onChangeTermo={(e) => setCliente(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={buscarTarefas} acaoBtn={exibirModaltarefas} listaSituacao={[{nome: 'Todas', valor: ''}, {nome: 'Pendentes', valor: 'pendente'}, {nome: 'Atrasadas', valor: 'atrasada'}, {nome: 'Concluídas', valor: 'concluída'}]} genero='feminino' maisFiltros={true} acaoMaisFiltro={modalMaisFiltros} isLoading={processando}/>

            {maisFiltros && <section id='maisOpcoesFiltrosTarefas'>
                <form id='maisOpcoesFiltrosTarefasContainer' onSubmit={buscarTarefas}>
                    <span id='filtrosContainerTitulo'>
                        <img id='filtrosAvancadosTarefas' src={maisFiltrosImg}  alt=''/>
                        <p>Filtros Avançados</p>
                    </span>
                    <section id='opcoesFiltrosTarefas'>
                        <section>
                            {search && <span>
                                <label htmlFor="responsaveltarefa">Responsável</label>
                                <select id='responsaveltarefa' value={responsavel} onChange={(e) => setResponsavel(e.target.value)}>
                                    <option value=""></option>
                                    {resposaveis.map((responsavel) => (
                                        <option key={responsavel.id} value={responsavel.id}>{responsavel.nome}</option>
                                    ))}
                                </select>
                            </span>}
                            { search !== '?automaticas=true' && <span>
                                <label htmlFor="dataInicialFiltrotarefas">Data Inicial</label>
                                <input 
                                    id="dataInicialFiltrotarefas"
                                    type="date" 
                                    value={dataInicio}
                                    onChange={(e) => setDataInicio(e.target.value)}/>
                            </span>}

                            {search !== '?automaticas=true' && <span>
                                <label htmlFor="dataFinalFiltrotarefas">Data Final</label>
                                <input 
                                    id="dataFinalFiltrotarefas"
                                    type="date" 
                                    value={dataTermino}
                                    onChange={(e) => setDataTermino(e.target.value)}/>
                            </span>}
                        </section>

                        <section>
                            <span>
                                <label htmlFor="clienteFiltroTarefas">Cliente</label>
                                <input 
                                    id='clienteFiltroTarefas'
                                    placeholder='Pesquise pelo nome Fantasia do Cliente'
                                    type="text" 
                                    value={cliente}
                                    onChange={(e) => setCliente(e.target.value)}/>
                            </span>
                        </section>

                        <section>
                            <span>
                                <label htmlFor="tituloTarefaFiltro">Título da Tarefa</label>
                                <input 
                                    id='tituloTarefaFiltro'
                                    type="text" 
                                    value={titulo}
                                    placeholder='Pesquise pelo título da tarefa'
                                    onChange={(e) => setTitulo(e.target.value)}/>
                            </span>

                            {search !== '?automaticas=true' && <span>
                                <label htmlFor="situacaoFiltroTarefa">Situação</label>
                                <select id="situacaoFiltroTarefa" value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                    <option value="">Todas</option>
                                    <option value="pendente">Pendentes</option>
                                    <option value="atrasada">Atrasadas</option>
                                    <option value="concluída">Conclúidas</option>
                                </select>
                            </span>}
                        </section>
                        
                        <section>
                            <span>
                                <label htmlFor="descricaoTarefaFiltro">Descrição da Tarefa</label>
                                <input
                                    id='descricaoTarefaFiltro' 
                                    type="text" 
                                    value={descricao}
                                    placeholder='Pesquise pela descrição da tarefa'
                                    onChange={(e) => setDescricao(e.target.value)}/>
                            </span>
                        </section>
                    </section>

                    <span id="botoesContainerFiltrosTarefas">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={modalMaisFiltros}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Buscar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>}

            <section id='fundoContainerPagina' onScroll={scrollInfinito}>
                <table id='tabelaListaContainer'>
                    <thead>
                        <tr>
                            <th id='ladoEsquerdoContent' align='left'>Cliente</th>
                            <th align='left'>Título</th>
                            <th id='responsavelTarefa' align='left'>Responsável</th>
                            <th id='tipoTarefa' align='left'>Tipo</th>
                            {tarefasAutomaticas !== 'true' ? <th id='prazoTarefa'>Prazo</th> : null}
                            {tarefasAutomaticas === 'true' ? <th align='center'>Recorrência</th>: null}
                            {tarefasAutomaticas === 'true' ? <th align='center'>Recorrência Dia</th>: null}
                            {tarefasAutomaticas === 'true' ? <th align='center'>Prazo</th>: null}
                            <th id='prioridadeTarefa' align='center'>Prioridade</th>
                            {tarefasAutomaticas !== 'true' ? <th align='center'>Situação</th>: null}
                            <Perfil perfil={['master']}>
                                <th>Ação</th>
                            </Perfil>
                        </tr>
                    </thead>
                    <tbody>
                        {tarefas.map((tarefa) => (
                            <tr key={tarefa.id}>
                                <td id='primeiraColuna' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>{tarefa.cliente.nomeFantasia}</td>
                                <td onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>{tarefa.titulo}</td>
                                <td id='usuarioResponsavelTarefaContent' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>
                                    <img id='usuarioResponsavelTarefa' src={tarefa.usuarioResponsavel.avatar ? tarefa.usuarioResponsavel.avatar : avatarDefault} alt='' />
                                    {tarefa.usuarioResponsavel.nome}
                                </td>
                                <td id='tipoTarefa' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>{`${tarefa.tipo.charAt(0).toUpperCase()}${tarefa.tipo.substring(1)}`}</td>
                                {tarefasAutomaticas !== 'true' ? <td id='prazoTarefa' align='center' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>{`${tarefa.dataEntrega.slice(8,10)}-${tarefa.dataEntrega.slice(5,7)}-${tarefa.dataEntrega.slice(0,4)}`}</td> : null }
                                {tarefasAutomaticas === 'true' ? <td id='recorrenciaTarefa' align='center' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>{`${tarefa.recorrencia?.charAt(0).toUpperCase()}${tarefa.recorrencia?.substring(1)}`}</td> : null }
                                {tarefasAutomaticas === 'true' ? <td id='recorrenciaTarefa' align='center' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>{tarefa.recorrenciaDia?.toString().padStart(2, '0')}</td> : null}
                                {tarefasAutomaticas === 'true' ? <td id='recorrenciaTarefa' align='center' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>{tarefa.prazo?.toString().padStart(2,'0')}</td> : null}
                                <td id='prioridadeTarefa' align='center' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>
                                    <DivSituacao texto={`${tarefa.prioridade.charAt(0).toUpperCase()}${tarefa.prioridade.substring(1)}`}cor={tarefa.prioridade} />
                                </td>
                                {tarefasAutomaticas !== 'true' ? <td align='center' onClick={() => exibirModalDadosTarefas(tarefa, tarefa.id)}>
                                    <DivSituacao texto={`${tarefa.situacao.charAt(0).toUpperCase()}${tarefa.situacao.substring(1)}`} cor={tarefa.situacao}/>
                                </td> : null    }
                                <Perfil perfil={['master']}>
                                    <td id='ladoDireitoContent' align='center'>
                                        {tarefa.situacao !== 'concluída' ? <img id='apagarIconeTarefa' title='Excluir Tarefa' src={apagarImg} alt='' onClick={() => deletarTask(tarefa.id)}/> : null}
                                    </td>
                                </Perfil>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {maisTarefas && <div id='buscarMaisDados'>
                    <LoadingCirculo />
                </div>}
            </section>
        </div>
    )
}

export default Tasks
