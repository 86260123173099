import axios  from 'axios'
import { verificarTokenAutenticacao } from '../../middleware/Token'


const baseApiHg = axios.create ({
    baseURL: process.env.REACT_APP_API_GESTTOR,
    headers: {
        'Content-Type': 'multipart/form-data',
    }

})

baseApiHg.interceptors.request.use (async config => {
    const token = verificarTokenAutenticacao ()

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

export default  baseApiHg