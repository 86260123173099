import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import Centros from "../../components/Centros";

function CentrosPage () {

    useEffect (() => {
        document.title = 'Centros | Gesttor'
    }, [])


    return (
        <DadosContainerDefault>
            <Centros />
        </DadosContainerDefault>
    )
}

export default CentrosPage