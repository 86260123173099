import { useState, FormEvent, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { comentariosListaResposta, ModalTarefasDados } from '../../interfaces/tasks'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { ProcessosContext } from '../../contexts/Processos'
import { anexarArquivo, buscarAnexos, deletarAnexo, atualizarTarefa, postarComentario, listarComentarios } from '../../services/tasks'
import { listarCadastros } from '../../services/cadastros'
import { novaTarefa } from '../../services/tasks'
import { novaSubTarefa, listarSubtarefas, atualizarSubTarefa, deletarSubTarefa } from '../../services/subTarefas'
import { listarUsuariosOrganizacao } from '../../services/usuarios'
import { cadastrosRespostaPayload } from '../../interfaces/cadastros'
import { usuariosRespostaPayload } from '../../interfaces/usuarios'
import { anexosListaResposta } from '../../interfaces/tasks'
import { cadastrarSubTarefaLocal, subTarefasResposta } from '../../interfaces/subTarefas'
import { Button, Loader } from '../Styles'
import Perfil from '../../middleware/perfil'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import apagarImg from '../../assets/img/excluir.png'
import erroImg from '../../assets/img/erroFechar.png'
import taskImg from '../../assets/img/tarefas.png'
import './tarefas.css'
import '../../styles/modal.css'


function ModalTarefas ({ tarefa }: ModalTarefasDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { exibirModal, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext)
    const { exibirModalTarefasProcesso, atualizarDadosProcesso } = useContext(ProcessosContext)

    const { pathname, search } = location

    const [tipo, setTipo] = useState(tarefa?.tipo)
    const [cliente, setCliente] = useState (tarefa?.cliente.id)
    const [clientes, setClientes] = useState<cadastrosRespostaPayload[]>([])
    const [prioridade, setPrioridade] = useState(tarefa?.prioridade)
    const [situacao, setSituacao] = useState(tarefa?.situacao)
    const [titulo, setTitulo] = useState(tarefa?.titulo)
    const [descricao, setDescricao] = useState(tarefa?.descricao)
    const [responsavel, setResponsavel] = useState(tarefa?.usuarioResponsavel.id)
    const [resposaveis, setResponsaveis] = useState<usuariosRespostaPayload[]>([])
    const [dataEntrega, setDataEntrega] = useState(tarefa?.dataEntrega)
    const [resultado, setResultado] = useState(tarefa?.resultado)
    const [automacao, setAutomacao] = useState<boolean | undefined>(tarefa?.automacao ?? false)
    const [recorrencia, setRecorrencia] = useState<string | null | undefined>(tarefa?.recorrencia)
    const [recorrenciaDia, setRecorrenciaDia] = useState<string | number | undefined | null>(tarefa?.recorrenciaDia)
    const [recorrenciaMes, setRecorrenciaMes] = useState<string | number | undefined | null>(tarefa?.recorrenciaMes)
    const [prazo, setPrazo] = useState(tarefa?.prazo)
    const [inicioAutomacao, setInicioAutomacao] = useState(tarefa?.inicioAutomacao)
    const [subTarefas, setSubtarefas] = useState<subTarefasResposta[]>([])
    const [subTarefasLocal, setSubTarefasLocal] = useState<cadastrarSubTarefaLocal[]>([])
    const [arquivo, setArquivo] = useState< File | null >(null)
    const [comentario, setComentario] = useState<string | null>(null)
    const [comentarios, setComentarios] = useState<comentariosListaResposta []>([])
    const [anexos, setAnexos] = useState<anexosListaResposta[]>([])
    const [opcoesTarefas, setOpcoesTarefas] = useState('descricao')
    const [processando, setProcessando] = useState (false)
    const [processandoAnexo, setProcessandoAnexo] = useState(false)
    const [processandoComentario, setProcessandoComentario] = useState(false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [confirmacaoAnexo, setConfirmacaoAnexo] = useState(false)
    const [erro, setErro] = useState(false)

    const params = new URLSearchParams(search)
    const automaticas = params.get('automaticas')
    const perfilLogado = localStorage.getItem('perfilGesttor')
    const usuarioLogado = localStorage.getItem('usuarioGesttor')

    const exibirOpcoestarefas = (opcao: string) => {

        const opcoes = Array.from(document.querySelectorAll('.opcoesTarefasLabel'))

        opcoes.forEach((label) => {
            label.addEventListener('click', () => {

                opcoes.forEach((element) => {
                    element.classList.remove('labelOpcoesTarefaAtiva')
                })
                
                label.classList.add('labelOpcoesTarefaAtiva')
                
            })
        })

        return setOpcoesTarefas(opcao)
    }

    const adicionarSubTarefaLocal = () => {

        const subTarefaLocal = window.prompt('Insira sua Subtarefa')

        if(subTarefaLocal ===  null) {
            return
        }

        const subTarefa = {
            descricao: subTarefaLocal
        }

        return setSubTarefasLocal(itens => [...itens, subTarefa])
    }


    const removerSubTarefaLocal = (posicao: number) =>{

        setSubTarefasLocal(itens => [...itens])

        return subTarefasLocal.splice(posicao, 1)
    }


    function ocultarModal () {

        const escritorio = params.get('escritorio')
        const idProcesso = params.get('idProcesso')
        const minhasSolicitacoes = params.get('minhasSolicitacoes')

        if(idProcesso && escritorio) {

            navigate(`${pathname}?escritorio=true&idProcesso=${idProcesso}`)
            exibirModal()
            exibirModalTarefasProcesso()

            return
        }
        if (escritorio) {
            
            navigate(`${pathname}?escritorio=true`)
            exibirModal()
            exibirModalTarefasProcesso()

            return
        }

        if(automaticas) {

            navigate(`${pathname}?automaticas=true`)
            exibirModal()
            exibirModalTarefasProcesso()

            return
        }


        if(idProcesso) {

            navigate(`${pathname}?idProcesso=${idProcesso}`)
            exibirModal()
            exibirModalTarefasProcesso()

            return
        }

        if(minhasSolicitacoes) {

            navigate(`${pathname}?minhasSolicitacoes=true`)
            exibirModal()
            exibirModalTarefasProcesso()

            return
        }

        navigate(`${pathname}`)

        return exibirModal ()
    }

    const documentoSelecionado = (e:any) => {

        const arquivo = e.target.files[0]

        return setArquivo(arquivo)
    }


    const visualizarAnexoTarefa = async (url: string) => {

        setProcessando(true)

        try {

            const baixarAnexo = await fetch(url)

            const blob = await baixarAnexo.blob()

            const anexo = URL.createObjectURL(blob)

            const link = document.createElement('a')

            link.href = anexo

            link.download = `${nomeArquivo(url)}`

            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)

            return setProcessando(false)
            
        } catch (error) {

            setProcessando(fail)

            return alert ('Falha ao Baixar o Arquivo')
            
        }

    }

    const cadastrarTarefa = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const idProcesso = params.get('idProcesso')

        if(automacao === undefined) {
            setProcessando(false)
            return
        }

        try {


            const payload = {
                idCliente: cliente,
                idResponsavel: responsavel,
                titulo,
                descricao,
                dataEntrega,
                prioridade,
                tipo,
                automacao,
                recorrencia,
                recorrenciaDia,
                recorrenciaMes,
                prazo,
                inicioAutomacao
            }

            if(idProcesso) {
                Object.assign(payload, {
                    idProcesso: idProcesso
                })
            }

            if(subTarefasLocal.length > 0) {
                Object.assign(payload, {
                    subtarefas: subTarefasLocal
                })
            }

            const resposta = await novaTarefa(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                atualizarDadosProcesso()
                setConfirmacao(true)

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
            
            return setTimeout (() => {
                setErro(false)
            }, 5000)
        }

    }

    const enviarComentario = async (e: FormEvent) => {

        e.preventDefault()
        setProcessandoAnexo(true)

        const idTarefa = params.get('idTarefa')

        if(idTarefa === null) {
            setProcessandoComentario(false)
            return
        }

        if(comentario === null) {
            setProcessandoComentario(false)
            return alert('Preencha o Comentário')
        }

        try {

            const payload = {
                comentario
            }

            const resposta = await postarComentario(idTarefa, payload)

            if(resposta.status === 201) {
                setProcessandoComentario(false)
                atualizarDados()
                setConfirmacao(true)

                return setTimeout(() => {
                    setConfirmacaoAnexo(false)
                }, 5000)
            }
            
        } catch (error) {
            
            setProcessandoComentario(false)
            setErro(true)
            
            return setTimeout (() => {
                setErro(false)
            }, 5000)
        }

    }
    
    const anexarDocumento = async (e: FormEvent) => {

        e.preventDefault()
        setProcessandoAnexo(true)

        const idTarefa = params.get('idTarefa')

        if(idTarefa === null) {
            setProcessandoAnexo(false)
            return
        }

        if(arquivo === null) {
            setProcessandoAnexo(false)
            return alert('Nenhum Arquivo Anexado')
        }

        try {

            const payload = {
                arquivo
            }

            const resposta = await anexarArquivo(idTarefa, payload)

            if(resposta.status === 201) {
                setProcessandoAnexo(false)
                atualizarDados()
                setConfirmacaoAnexo(true)

                return setTimeout(() => {
                    setConfirmacaoAnexo(false)
                }, 5000)
            }
            
        } catch (error) {
            
            setProcessandoAnexo(false)
            setErro(true)
            
            return setTimeout (() => {
                setErro(false)
            }, 5000)
        }

    }

    const apagarAnexo = async (e: FormEvent, idAnexo: string) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const resposta = await deletarAnexo(idAnexo)

            if(resposta.status === 200) {
                setProcessando(false)
                atualizarDados()
                setConfirmacao(true)

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
            
            return setTimeout (() => {
                setErro(false)
            }, 5000)
        }

    }

    const nomeArquivo = (url: string) => {

        const nomeArray = url.split('/')

        const nomeNumero = nomeArray[nomeArray.length - 1].split('.')

        const nome = nomeNumero.slice(1).join('') 

        return nome
    }

    const updateTarefa = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const idTarefa = paramsUrl.get('idTarefa')

        if (idTarefa === null || cliente === undefined || responsavel === undefined || titulo === undefined || descricao === undefined || dataEntrega === undefined || prioridade === undefined || tipo === undefined || automacao === undefined || recorrencia === undefined || resultado === undefined || recorrenciaDia === undefined || recorrenciaMes === undefined || prazo === undefined || situacao === undefined) {
            return setProcessando(false)
        }

        if (situacao === 'concluída' && resultado === null) {
            alert('A tarefa não pode ser finalizada sem um resultado')

            return setProcessando(false)
        }

        const conclusaoSubTarefas = subTarefas.some(subtarefa => subtarefa.finalizada === false)

        if(conclusaoSubTarefas && situacao === 'concluída') {
            alert('A tarefa não pode ser finalizada com subtarefas pendentes')

            return setProcessando(false)
        }

        const payload = {
            idCliente: cliente,
            idResponsavel: responsavel,
            titulo,
            descricao,
            prioridade,
            tipo,
            dataEntrega,
            automacao,
            inicioAutomacao,
            recorrencia,
            recorrenciaDia,
            recorrenciaMes,
            prazo,
            resultado,
            situacao
        }

        try {

            const resposta = await atualizarTarefa(idTarefa, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()
                atualizarDadosProcesso()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
        }
    }

    const cadastrarNovaSubTarefa = async () => {

        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const idTarefa = paramsUrl.get('idTarefa')

        const descricaoSubTarefa = window.prompt('Insira sua Subtarefa')

        if(idTarefa === null || descricaoSubTarefa === null) {
            return setProcessando(false)
        }

        try {

            const payload = {
                descricao: descricaoSubTarefa
            }

            const resposta = await novaSubTarefa(Number(idTarefa), payload)

            if(resposta.status === 201) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()
                atualizarDadosProcesso()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout (() => {
                setErro(false)
            }, 5000)
            
        }
    }

    const updateSubTarefa = async (idSubTarefa: number, finalizada: boolean) => {

        setProcessando(true)

        try {

            const payload = {
                finalizada: !finalizada
            }

            const resposta = await atualizarSubTarefa(idSubTarefa, payload)

            if(resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()
                atualizarDadosProcesso()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout (() => {
                setErro(false)
            }, 5000)
            
        }
    }

    const deleteSubTarefa = async (idSubTarefa: number) => {

        setProcessando(true)

        try {

            const resposta = await deletarSubTarefa(idSubTarefa)

            if(resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()
                atualizarDadosProcesso()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout (() => {
                setErro(false)
            }, 5000)
            
        }
    }

    useEffect(() => {

        if (recorrencia === 'semanal' && inicioAutomacao !== undefined && inicioAutomacao !== null) {

            const diaAnterior = new Date(inicioAutomacao)

            const dia = new Date(diaAnterior.setDate(diaAnterior.getDate() + 1))

            setRecorrenciaDia(dia.getDay())

        }

        if ((recorrencia === "mensal" || recorrencia === 'trimestral' || recorrencia === "semestral" || recorrencia === "anual") && inicioAutomacao !== undefined && inicioAutomacao !== null) {

            const data = new Date(inicioAutomacao)

            const dia = new Date (data.setDate(data.getDate() + 1))

            setRecorrenciaDia(dia.getDate())
    
        }

        if ((recorrencia === "anual" || recorrencia === 'trimestral' || recorrencia === "semestral") && inicioAutomacao !== undefined && inicioAutomacao !== null) {

            const data = new Date(inicioAutomacao)

            const mes = new Date (data.setMonth(data.getMonth() + 1))

            setRecorrenciaMes(mes.getMonth())

        }

    }, [recorrencia, inicioAutomacao])

    useEffect(() => {

        const buscarClientes = async () => {

            try {

                const params = {
                    tipo: 'cliente',
                    termo: '',
                    pagina: 1,
                    situacao: 'ativo'
                }

                const resposta = await listarCadastros(params)

                if (resposta.status === 200) {
                    return setClientes(resposta.data)
                }
                
            } catch (error) {

                return alert('Erro ao Listar os Clientes')
                
            }
        }

        buscarClientes ()

    }, [])

    useEffect(() => {
        
        const buscarResponsaveis = async () => {

            try {

                const params = {
                    termo: '',
                    situacao: 'ativo'
                }

                const resposta = await listarUsuariosOrganizacao(params)

                if (resposta.status === 200) {
                    return setResponsaveis(resposta.data)
                }
                
            } catch (error) {

                return alert('Falha ao Listar os Responsáveis')
                
            }
        }

        buscarResponsaveis ()

    }, [])

    useEffect(() => {

        const buscarSubTarefas = async () => {

            const paramsUrl = new URLSearchParams(search)
            const idTarefa = paramsUrl.get('idTarefa')

            if(idTarefa === null) {
                return
            }

            try {

                const resposta = await listarSubtarefas(idTarefa)

                if(resposta.status === 200) {
                    setSubtarefas(resposta.data)
                }
                
            } catch (error) {
                
            }
        }

        buscarSubTarefas()

    }, [atualizarInformacoes, search])


    useEffect(() => {

        const anexosTarefas = async () => {

            const paramsUrl = new URLSearchParams(search)
            const idTarefa = paramsUrl.get('idTarefa')

            if(idTarefa === null) {
                return
            }

            try {

                const requsicoes = [
                    buscarAnexos(idTarefa),
                    listarComentarios(idTarefa)
                ]

                const resposta = await Promise.all(requsicoes)

                const anexos = resposta[0]
                const comentarios = resposta[1]

                if(anexos.status === 200) {
                    setAnexos(anexos.data)
                }

                if(comentarios.status === 200) {
                    setComentarios(comentarios.data)
                }
                
            } catch (error) {
                
            }
        }

        anexosTarefas ()

    }, [atualizarInformacoes, search])


    return (
        <div id = 'fundoModal'>
            {confirmacao && <Notificacoes texto= {tarefa ? 'Dados atualizados com sucesso' : 'Dados Salvos com Sucesso'} icone={confirmacaoImg} cor='sucesso'/>}
            {confirmacaoAnexo && <Notificacoes texto= {'Documento Anexado com Sucesso'} icone={confirmacaoImg} cor='sucesso'/>}
            {erro && <Notificacoes texto='Ocorreu um erro ao processar a solicitação' icone={erroImg} cor='erro'/>}
            <section id={tarefa ? 'containerModalNovo' : ''} className={tarefa ? 'containerModalTarefa': 'containerModalCentro'}>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={taskImg} alt='' />
                    <p>{!tarefa ? 'Nova Tarefa': 'Vizualizar Tarefa'}</p>
                </span>
                <form id='formModalNovo' onSubmit={tarefa ? updateTarefa : cadastrarTarefa}>
                    <section>
                        <span>
                            <label htmlFor="clienteNovaTarefa">Cliente</label>
                            <select id='clienteNovaTarefa' value={cliente} onChange={(e) =>setCliente(e.target.value)} required>
                                <option value=""></option>
                                {clientes.map((cliente) => (
                                    <option key={cliente.id} value={cliente.id}>{cliente.nomeFantasia}</option>
                                ))}
                            </select>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="tipoNovaTarefa">Tipo</label>
                            <select id='tipoNovaTarefa' value={tipo} onChange={(e) => setTipo(e.target.value)} required>
                                <option value=""></option>
                                <option value="atendimento">Atendimento</option>
                                <option value="chamado">Chamado</option>
                                <option value="tarefa">Tarefa</option>
                            </select>
                        </span>

                        <span>
                            <label htmlFor="prioridadeNonaTarefa">Prioridade</label>
                            <select id='prioridadeNonaTarefa' value={prioridade} onChange={(e) => setPrioridade(e.target.value)} required>
                                <option value=""></option>
                                <option value="urgente">Urgente</option>
                                <option value="alta">Alta</option>
                                <option value="baixa">Baixa</option>
                            </select>
                        </span>

                        {!tarefa && <span>
                            <label htmlFor="dataEntrega">Data da Entrega</label>
                            <input 
                                id='dataEntrega'
                                type='date'
                                value={dataEntrega}
                                onChange={(e) => setDataEntrega(e.target.value)} 
                                required/>
                        </span>}

                        {tarefa && <Perfil perfil={['master']}>
                            <span>
                                <label htmlFor="dataEntrega">Data da Entrega</label>
                                <input 
                                    id='dataEntrega'
                                    type='date'
                                    value={dataEntrega}
                                    onChange={(e) => setDataEntrega(e.target.value)} 
                                    required/>
                            </span>
                        </Perfil>}

                        {tarefa && <span>
                            <label htmlFor="situacaoTarefa">Situacao</label>
                            <select id='situacaoTarefa' value={situacao} onChange={(e) => setSituacao(e.target.value)} required>
                                <option value="pendente">Pendente</option>
                                <option value="atrasada">Atrasada</option>
                                <option value="concluída">Concluída</option>
                            </select>
                        </span>}
                    </section>

                    <section>
                        <span>
                            <label htmlFor="tituloTarefa">Título</label>
                            <input
                                id='tituloTarefa'
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)} 
                                type='text' 
                                required/>
                        </span>
                        <span>
                            <label htmlFor="responsaveltarefa">Responsável</label>
                            <select id='responsaveltarefa' value={responsavel} onChange={(e) => setResponsavel(e.target.value)} required>
                                <option value=""></option>
                                {resposaveis.map((responsavel) => (
                                    <option key={responsavel.id} value={responsavel.id}>{responsavel.nome}</option>
                                ))}
                            </select>
                        </span>
                    </section>

                    <div className='opcoesTarefasContainer'>
                        <label className='opcoesTarefasLabel' htmlFor="descricaoTarefa" onClick={() => exibirOpcoestarefas('descricao')}>Descrição da Tarefa</label>
                        {tarefa && <label className='opcoesTarefasLabel' htmlFor="subTarefaTarefa" onClick={() => exibirOpcoestarefas('subtarefas')}>SubTarefas</label>}
                        {tarefa && <label className='opcoesTarefasLabel' htmlFor="tarefasComentarios" onClick={() => exibirOpcoestarefas('comentarios')}>Comentários</label>}
                        {tarefa && <label className='opcoesTarefasLabel' htmlFor="tarefasAnexos" onClick={() => exibirOpcoestarefas('anexos')}>Anexos</label>}
                        {!tarefa && <label className='opcoesTarefasLabel' htmlFor="subTarefaTarefa" onClick={() => exibirOpcoestarefas('subtarefasLocal')}>SubTarefas</label>}
                        {tarefa && automaticas !== 'true' && <label className='opcoesTarefasLabel' htmlFor="subTarefaTarefa" onClick={() => exibirOpcoestarefas('resultado')}>Resultado</label>}
                        <label className='opcoesTarefasLabel' htmlFor="inicioAutomacao" onClick={() => exibirOpcoestarefas('automacao')}>Automação</label>
                    </div>

                    {opcoesTarefas === 'descricao' && <section>
                        <span>
                            <textarea 
                                id='descricaoTarefa'
                                name="descricaoTarefa"
                                value={descricao}
                                onChange={(e) => setDescricao(e.target.value)}
                                placeholder='Descreva aqui o que precisará ser realizado nesta tarefa'
                                rows={10}
                                required />
                        </span>
                    </section>}

                    {opcoesTarefas === 'subtarefas' && <section className='subTarefasContainer'>
                        <p id='adicionarSubtarefasContent' onClick={() => cadastrarNovaSubTarefa()}>Adicionar Subtarefa</p>
                        <table id = 'tabelaListaContainer'>
                            <tbody>
                                {subTarefas.map((subtarefa) => (
                                    <tr key={Math.random()}>
                                        <td id='primeiraColuna' onClick={() => updateSubTarefa(subtarefa.id, subtarefa.finalizada)}>
                                            <input
                                                className='toggleSubTarefas'
                                                id='toggleSubTarefas'  
                                                type="checkbox"
                                                readOnly
                                                checked={subtarefa.finalizada}
                                                />
                                            <label id='toggleSubTarefas' htmlFor="toggleSubTarefas"></label>
                                        </td>
                                        <td>{subtarefa.descricao}</td>
                                        <td id='usuarioResponsavelTarefaContent'>
                                            <img id='usuarioResponsavelTarefa' src={subtarefa.finalizadoPor?.avatar} alt=''/>
                                            <p>{subtarefa.finalizadoPor?.nome}</p>
                                        </td>
                                        <td id='ladoDireitoContent' align='center'>
                                            {subtarefa.finalizada === false && <img id='apagarIconeTarefa' title='Excluir SubTarefa' src={apagarImg} alt='' onClick={() => deleteSubTarefa(subtarefa.id)}/>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </section>}

                    {opcoesTarefas === 'subtarefasLocal' && <section className='subTarefasContainer'>
                        <p id='adicionarSubtarefasContent' onClick={() => adicionarSubTarefaLocal()}>Adicionar Subtarefa</p>
                        <table id = 'tabelaListaContainer'>
                            <tbody>
                                {subTarefasLocal.map((subtarefa, index) => (
                                    <tr key={Math.random()}>
                                        <td id='primeiraColuna'>{subtarefa.descricao}</td>
                                        <td id='ladoDireitoContent' align='right'>
                                            <img id='apagarIconeTarefa' title='Excluir SubTarefa' src={apagarImg} alt='' onClick={() => removerSubTarefaLocal(index)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </section>}

                    {tarefa && opcoesTarefas === 'comentarios' && <section>
                        <span>
                            <div className='tarefaComentarioContainer'>
                                <input 
                                type="text"
                                placeholder='Digite seu comentário'
                                onChange={(e) => setComentario(e.target.value)} 
                                />
                                <Button type='button' cor='salvar' isLoading={processandoComentario} onClick={enviarComentario}>
                                    {!processandoComentario && 'Postar'}
                                    {processandoComentario && <Loader isLoading={processandoComentario}/>}
                                </Button>
                            </div>
                            <br/>
                            {comentarios.length > 0 && <p className='tarefasAnexos'>Comentários</p>}
                            <div className='comentarioTarefaContainer'>
                                {comentarios.map((comentario) => (
                                    <div className='comentarioTarefaContent'>
                                        <img src={comentario.usuario.avatar} alt="" />
                                        <p>{comentario.comentario}</p>
                                    </div>
                                ))}
                            </div>
                        </span>
                    </section>}

                    {tarefa && opcoesTarefas === 'anexos' && <section>
                        <span>
                            <div className='tarefaAnexarArquivoContainer'>
                                <input 
                                type="file"
                                onChange={(e) => documentoSelecionado(e)} 
                                />
                                <Button type='button' cor='salvar' isLoading={processandoAnexo} onClick={anexarDocumento}>
                                    {!processandoAnexo && 'Anexar Arquivo'}
                                    {processandoAnexo && <Loader isLoading={processandoAnexo}/>}
                                </Button>
                            </div>
                            <br/>
                            {anexos.length > 0 && <p className='tarefasAnexos'>Arquivos Anexados</p>}
                            <div className='anexosTarefaContainer'>
                                {anexos.map((arquivo) => (
                                    <div className='apagarAnexoContent'>
                                        <p className='tarefasAnexosLink' onClick={() => visualizarAnexoTarefa(arquivo.anexo)}>{nomeArquivo(arquivo.anexo)}</p>
                                        <span className='apagarAnexoUsuario'>
                                            <span>
                                                <img src={arquivo.usuario.avatar} alt="" />
                                                <p>{arquivo.usuario.nome}</p>
                                            </span>
                                            {(perfilLogado === 'master' || usuarioLogado === arquivo.usuario.id) && <img src={apagarImg} alt='' title='Apagar Anexo' onClick={(e) => apagarAnexo(e, arquivo.id)}/>}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </span>
                    </section>}

                    {tarefa && automaticas !== 'true' && opcoesTarefas === 'resultado' && <section>
                        <span>
                            <textarea
                                className='resultadoTarefa' 
                                id='resultadoTarefa'
                                name="resultadoTarefa"
                                value={resultado === null ? '' : resultado}
                                onChange={(e) => setResultado(e.target.value)}
                                placeholder='Qual foi a conclusão dessa tarefa'
                                rows={12}/>
                        </span>
                    </section>}
                    
                    { opcoesTarefas === 'automacao' && <div className='tarefasRecorrentes'>
                        <input
                            checked={automacao}
                            className='toggleAutomacaoTarefas'
                            id='toggleAutomacaoTarefas'
                            type="checkbox"
                            onChange={() => setAutomacao(!automacao)}/>
                        <label id='toggleAutomacaoTarefas' htmlFor='toggleAutomacaoTarefas'></label>
                        <p>Recorrência</p>
                    </div>}
                    {automacao && opcoesTarefas === 'automacao' && <section className='automacaoTarefas'>
                        <span>
                            <label htmlFor="inicioAutomacao">Início da Automação</label>
                            <input 
                                id='inicioAutomacao'
                                type='date'
                                value={inicioAutomacao !== null ? inicioAutomacao : ''}
                                onChange={(e) => setInicioAutomacao(e.target.value)} 
                                required/>
                        </span>
                        <span>
                            <label htmlFor="">Frequência</label>
                            <select value={recorrencia !== null ? recorrencia : ''} onChange={(e) => setRecorrencia(e.target.value)} required>
                                <option value=""></option>
                                <option value="diaria">Diária</option>
                                <option value="semanal">Semanal</option>
                                <option value="mensal">Mensal</option>
                                <option value="trimestral">Trimestral</option>
                                <option value="semestral">Semestral</option>
                                <option value="anual">Anual</option>
                            </select>
                        </span>
                        <span>
                            <label htmlFor="prazoAutomacao">Prazo</label>
                            <input 
                                id='prazoAutomacao'
                                type='number'
                                value={prazo}
                                placeholder='Prazo em Dias'
                                onChange={(e) => setPrazo(parseInt(e.target.value))} 
                                required/>
                        </span>
                    </section>}
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalTarefas
