import baseUrlAuth from '../api/authConfig'
import baseUrl from '../api/config'
import baseUrlFormDate from '../api/formData'
import { cadastrarOrganizacaoPayload, paramsOrganizacao, organizacoesAtualizarPayload, organizacaoGestaoAtualizar, atualizarAvatar } from '../../interfaces/organizacao'

export function novaOrganizacao (payload: cadastrarOrganizacaoPayload) {
    return baseUrlAuth.post('/organizacao', payload)
}

export function dadosOrganizacao () {
    return baseUrl.get ('/organizacao')
}

export function atualizarOrganizacao (payload: organizacoesAtualizarPayload) {
    return baseUrl.patch('/organizacao', payload)
}

export function atualizarAvatarOrganizacao (payload: atualizarAvatar) {
    return baseUrlFormDate.patch('/organizacao/avatar', payload)
}

export function clientesGesttor (params: paramsOrganizacao) {
    return baseUrl.get(`/organizacao/clientes?termo=${params.termo}`)
}

export function licencasGesttor (idOrganizacao: string, payload: organizacaoGestaoAtualizar) {
    return baseUrl.patch(`/organizacao/clientes/${idOrganizacao}`, payload)
}
