import { configureStore } from "@reduxjs/toolkit"
import alertas from "./modules/Alertas"
import modalMovoUsuario from "./modules/modalMovoUsuario"
import modalRecuperarSenha from "./modules/modalRecuperarSenha"
import atualizarDados from "./modules/atualizarDados"

const store = configureStore ({
    reducer: {
        alertas,
        atualizarDados,
        modalMovoUsuario,
        modalRecuperarSenha
    }
})

export type RootStore = ReturnType <typeof store.getState>

export default store