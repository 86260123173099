import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import BancoTalentos from "../../components/BancoTalentos"

function BancoTalento () {
  
  useEffect(()=>{
    document.title = "Banco de Talentos | Gesttor"
  },[])

  return (
    <DadosContainerDefault>
      <BancoTalentos/>
    </DadosContainerDefault>
  )
}

export default BancoTalento