import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts'
import { graficoFaturamentoDespesa } from '../../interfaces/graficos'
import { faturamentoDespesas } from '../../services/dashboardFinanceiro'
import Perfil from '../../middleware/perfil'
import LoadingCirculo from '../LoadingCirculo'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import './faturamentodespesa.css'

function GraficoFaturamentoDespesa () {

    const navigate = useNavigate()

    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [dados, setDados] = useState<graficoFaturamentoDespesa[]>([])
    const [valorMaximo, setValorMaximo] = useState(0)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const faturamento = async () => {

            try {
                const resposta = await faturamentoDespesas ()
                setCarregando (false)
                setDados (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setGrafico (true)

            } catch (error:any) {

                if (error.status === 401) {
                    return navigate('/');
                }
            }
        }

        faturamento ()

    }, [navigate])

    useEffect (() => {

        let valorMaximoReceita = 0
        let valorMaximoDespesa = 0


        dados.forEach (valor => {

            if (Number(valor.receitaMes) > Number(valorMaximoReceita)) {
                valorMaximoReceita = Number(valor.receitaMes)
            }
        })

        dados.forEach (valor => {
            if (Number(valor.despesaMes) > valorMaximoDespesa) {
                valorMaximoDespesa = Number(valorMaximoDespesa)
            }
        })

        return setValorMaximo(Number((Math.max(valorMaximoReceita, valorMaximoDespesa)*1.05).toFixed(0)))

    }, [dados])


    return (

        <Perfil perfil={['master']}>
            <div id = 'graficoFaturamentoDespesa'>
                <p id='graficoFaturamentoDespesaP'>FATURAMENTO x DESPESAS</p>
                {carregando && <LoadingCirculo/>}
                {grafico && <ResponsiveContainer>
                    <AreaChart data={dados} margin={{ top: 10, right: 40, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="receitaMes" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#25AE88" stopOpacity={1}/>
                            <stop offset="95%" stopColor="#25AE88" stopOpacity={0.4}/>
                        </linearGradient>
                        <linearGradient id="despesaMes" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#E21B1B" stopOpacity={1}/>
                            <stop offset="95%" stopColor="#E21B1B" stopOpacity={0.4}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="competência" />
                    <YAxis domain={[0, valorMaximo]} allowDecimals={false}/>
                    <CartesianGrid strokeDasharray="6 6" />
                    <Tooltip />
                    <Area type="monotone" dataKey="receitaMes" strokeWidth={2} stroke="#25AE88"  activeDot = {{r: 8}} fillOpacity={1} fill="url(#receitaMes)" />
                    <Area type="monotone" dataKey="despesaMes" strokeWidth={2} stroke="#E21B1B"  activeDot = {{r: 8}} fillOpacity={1} fill="url(#despesaMes)" />
                    </AreaChart>
                </ResponsiveContainer>}
                {semDados && <NenhumDadoGrafico texto='Sem Dados para' subtexto='exibir o gráfico'/>}
            </div>
        </Perfil>
        
    )
}

export default GraficoFaturamentoDespesa