import BASEAPI from '../api/config'
import { cadastrarContratoPayload, paramsListarContrato } from '../../interfaces/contratos'


export function cadastrarContrato (payload: cadastrarContratoPayload) {
    return BASEAPI.post('/contratos', payload)
}

export function listarContratos (params: paramsListarContrato) {
    return BASEAPI.get(`/contratos?situacao=${params.situacao}&termo=${params.termo}`)
}

export function dadosContratos (idContrato: string) {
    return BASEAPI.get(`/contratos/dados/${idContrato}`)
}


