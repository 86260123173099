const validarCpfCnpj = (valor: any) => {

    if (valor.length === 16) {
        let cpf = valor.trim();
     
        cpf = cpf.replace(/[^\d]+/g,'');
        cpf = cpf.split('');
        
        let v1 = 0;
        let v2 = 0;
        let aux = false;
        
        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] !== cpf[i]) {
                aux = true;   
            }
        } 
        
        if (aux === false) {
            return false; 
        } 
        
        for (var i = 0, p = 10; i < 9; i++, p--) {
            v1 += cpf[i] * p; 
        } 
        
        v1 = ((v1 * 10) % 11);
        
        if (v1 === 10) {
            v1 = 0; 
        }
        
        if (v1.toString() !== cpf[9]) {
            return false; 
        } 
        
        for (var i = 0, p = 11; i < 10; i++, p--) {
            v2 += cpf[i] * p; 
        } 
        
        v2 = ((v2 * 10) % 11);
        
        if (v2 === 10) {
            v2 = 0; 
        }
        
        if (v2.toString() !== cpf[10]) {
            return false; 
        } else {   
            return true; 
        }
    } else if (valor.length === 20) {
        var cnpj = valor.trim();
        
        cnpj = cnpj.replace(/[^\d]+/g,'');
        cnpj = cnpj.split(''); 
        
        var v1 = 0;
        var v2 = 0;
        var aux = false;
        
        for (var i = 1; cnpj.length > i; i++) { 
            if (cnpj[i - 1] !== cnpj[i]) {  
                aux = true;   
            } 
        } 
        
        if (aux === false) {  
            return false; 
        }
        
        for (var i = 0, p1 = 5, p2 = 13; i < 12; i++, p1--, p2--) {
            if (p1 >= 2) {  
                v1 += cnpj[i] * p1;  
            } else {  
                v1 += cnpj[i] * p2;  
            } 
        } 
        
        v1 = (v1 % 11);
        
        if (v1 < 2) { 
            v1 = 0; 
        } else { 
            v1 = (11 - v1); 
        } 
        
        if (v1.toString() !== cnpj[12]) {  
            return false; 
        } 
        
        for (var i = 0, p1 = 6, p2 = 14; i < 13 ; i++, p1--, p2--) { 
            if (p1 >= 2) {  
                v2 += cnpj[i] * p1;  
            } else {   
                v2 += cnpj[i] * p2; 
            } 
        }
        
        v2 = (v2 % 11); 
        
        if (v2 < 2) {  
            v2 = 0;
        } else { 
            v2 = (11 - v2); 
        } 
        
        if (v2.toString() !== cnpj[13]) {   
            return false; 
        } else {  
            return true; 
        }
    } else {
        return false;
    }
}

export default validarCpfCnpj