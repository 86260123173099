import { useState, useEffect } from 'react'
import { propsToogle } from '../../interfaces/propsToogle'
import './toogle.css'

function ToogleDefault (props: propsToogle) {

    const [check, setChecked] = useState(false)

    useEffect(() => {
        const check = props.checked

        return setChecked(check)
        
    }, [props.checked])

    return (
        <span>
            <input
                checked={check}
                className='toggleListaUsuarios'
                id={props.id}
                type="checkbox"
                readOnly
                />
                <label id='labelToggleListaUsuarios' htmlFor={props.id}></label>
        </span>
    )
}

export default ToogleDefault