import { useState } from 'react'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import Perfil from '../../middleware/perfil'
import '../../styles/menuopcoes.css'

function MenuFinanceiro (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (
        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
            <div>
                <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                    {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                    <p id = 'tituloMenu'>{props.titulo}</p>
                </div>
                <div id = 'submenuOpcoes'>
                    {subMenu && <SubMenuOpcoes redirect = {'/lancamentos?tipo=receita'} texto = 'Receitas' />}
                    <Perfil perfil={['master', 'administrativo']}>
                        <>
                            {subMenu && <SubMenuOpcoes redirect = {'/lancamentos?tipo=despesa'} texto = 'Despesas' />}
                            {subMenu && <SubMenuOpcoes redirect = {'/lancamentos?tipo=receita&recorrencia=true'} texto = 'Receitas Recorrentes' />}
                            {subMenu && <SubMenuOpcoes redirect = {'/lancamentos?tipo=despesa&recorrencia=true'} texto = 'Despesas Recorrentes' />}
                            {subMenu && <SubMenuOpcoes redirect = {'/lancamentos?tipo=receita&inadimplencia=true'} texto = 'Clientes Inadimplentes' />}
                            {subMenu && <SubMenuOpcoes redirect = {'/contas'} texto = 'Contas Bancárias' />}
                        </>
                    </Perfil>
                </div>
            </div>
        </Perfil>
    )
}

export default MenuFinanceiro