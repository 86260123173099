import baseApi from "../api/config"
import { novaCobrancaPayload } from '../../interfaces/cobrancas'

export function novaCobranca (idLancamento: number, payload: novaCobrancaPayload ) {
    return baseApi.post(`/cobrancas/${idLancamento}`, payload)
}

export function visualizarCobranca (idCobranca: number) {
    return baseApi.get(`/cobrancas/${idCobranca}`, {
        responseType: 'blob'
    })
}

export function gerarQrCode (idCobranca: number) {
    return baseApi.get(`/cobrancas/qrcode/${idCobranca}`)
}

export function cancelarCobranca (idCobranca: number) {
    return baseApi.put(`/cobrancas/${idCobranca}`)
}

export function enviarCobrancaWa (idCobranca: number) {
    return baseApi.post(`/cobrancas/wa/${idCobranca}`)
}