import { createContext, useState } from 'react'
import { propsProvider } from '../../interfaces/propsProvider'
import { propsContextProcessos } from '../../interfaces/propsContextProcessos'

const ProcessosContext = createContext<propsContextProcessos>({
    modalProcessoEstado: false,
    modalTarefasProcesso: false,
    atualizarInformacoesProcesso: false,
    exibirModalProcesso: () => {},
    exibirModalTarefasProcesso: () => {},
    atualizarDadosProcesso: () => {},
})

const ProcessosProvider = ({ children }: propsProvider) => {

    const [modalProcessoEstado, setModalProcessoEstado] = useState(false)
    const [modalTarefasProcesso, setModalTarefasProcesso] = useState(false)
    const [atualizarInformacoesProcesso, setAtualizarInformacoesProcesso] = useState(false)

    const exibirModalProcesso = () => setModalProcessoEstado(!modalProcessoEstado)
    const exibirModalTarefasProcesso = () => setModalTarefasProcesso(!modalTarefasProcesso)
    const atualizarDadosProcesso = () => setAtualizarInformacoesProcesso(!atualizarInformacoesProcesso)

    return (
        <ProcessosContext.Provider value={{ modalProcessoEstado, modalTarefasProcesso, exibirModalProcesso, exibirModalTarefasProcesso, atualizarDadosProcesso, atualizarInformacoesProcesso }}>
            {children}
        </ProcessosContext.Provider>
    )
}

export { ProcessosContext, ProcessosProvider }