import { useState, FormEvent, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ProcessosContext } from '../../contexts/Processos'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { ModalProcessoDados } from '../../interfaces/processos'
import { usuariosRespostaPayload } from '../../interfaces/usuarios'
import { modelosProcessosListaResposta } from '../../interfaces/modelosProcessos'
import { tarefasListaResposta } from '../../interfaces/tasks'
import { cadastrosRespostaPayload } from '../../interfaces/cadastros'
import { listarUsuariosOrganizacao } from '../../services/usuarios'
import { atualizarProcesso, novoProcesso, tarefasProcesso } from '../../services/processos'
import { deletarTarefa } from '../../services/tasks'
import { listarCadastros } from '../../services/cadastros'
import { listarModelosProcessos } from '../../services/modelosProcessos'
import { Button, Loader } from '../Styles'
import DivSituacao from '../DivSituacao'
import Perfil from '../../middleware/perfil'
import ModalTarefas from '../ModalTarefas'
import avatarDefault from '../../assets/img/avatarDefault.png'
import ModalAlerta from '../ModalAlerta'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import processoImg from '../../assets/img/processos.png'
import apagarImg from '../../assets/img/excluir.png'
import '../../styles/modal.css'
import './modalprocessos.css'


function ModalProcessos ({ processo }: ModalProcessoDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { modalExibir } = useContext(ModalEstadoContext)
    const { modalTarefasProcesso, exibirModalTarefasProcesso, atualizarDadosProcesso, atualizarInformacoesProcesso } = useContext(ProcessosContext)

    const { pathname, search } = location

    const [processosModelos, setProcessosModelos] = useState<modelosProcessosListaResposta[]>([])
    const [tarefasProcessos, setTarefasProcesso] = useState<tarefasListaResposta[]>([])
    const [tarefaSelecionada, setTarefaSelecionada] = useState<tarefasListaResposta>()
    const [processoModelo, setProcessoModelo] = useState('')
    const [responsavel, setResponsavel] = useState(processo?.responsavelProcesso.id)
    const [resposaveis, setResponsaveis] = useState<usuariosRespostaPayload[]>([])
    const [clientes, setClientes] = useState<cadastrosRespostaPayload[]>([])
    const [cliente, setCliente] = useState('')
    const [titulo, setTitulo] = useState(processo?.titulo)
    const [situacao, setSituacao] = useState(processo?.situacao)
    const [dataInicio, setDataInicio] = useState('')
    const [previsaoTermino, setPrevisaoTermino] = useState(processo?.previsaoTermino)
    const [modalTarefa, setModalTarefa] = useState(false)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [confirmacaoExclusao, setConfirmacaoExclusao] = useState(false)
    const [excluirTarefa, setExluirTarefa] = useState(false)
    const [erro, setErro] = useState(false)

    const paramsUrl = new URLSearchParams(search)
    const processosEscritorio = paramsUrl.get('escritorio')
    const idProcesso = paramsUrl.get('idProcesso')

    function ocultarModalProcessos () {

        if (processosEscritorio) {
            
            navigate(`${pathname}?escritorio=true`)
            modalExibir('')

            return
        }

        navigate(pathname)
        modalExibir('')

        return
    }

    const exibirModalTarefaProcesso = () => {
        exibirModalTarefasProcesso()
    }
    

    const exibirModalDadosTarefa = (tarefa: tarefasListaResposta, idTarefa: string) => {

        if(processosEscritorio) {
            navigate(`${pathname}?escritorio=true&idProcesso=${idProcesso}&idTarefa=${idTarefa}`)
            exibirModalTarefasProcesso()
            return setTarefaSelecionada(tarefa)
        }
        
        navigate(`${pathname}?idProcesso=${idProcesso}&idTarefa=${idTarefa}`)
        exibirModalTarefasProcesso()

        return setTarefaSelecionada(tarefa)
    }

    function ocultarModalAlerta () {
        
        setConfirmacaoExclusao(false)
        setConfirmacao(false)
        setErro(false)

        return modalExibir ('')
    }

    const deletarTask = async (idTarefa: string) => {

        setProcessando(true)

        try {

            const resposta = await deletarTarefa(idTarefa)

            if(resposta.status === 200) {
                setProcessando(false)
                setExluirTarefa(true)
                atualizarDadosProcesso()

                return setTimeout (() => {
                    setExluirTarefa(false)
                }, 2000)

            }
            
        } catch (error) {

            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
            
        }

    }

    const cadastrarProcesso = async (e:FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        if(responsavel === undefined || previsaoTermino === undefined || titulo === undefined) {
            return
        }

        try {

            const payload = {
                idModeloProcesso: processoModelo,
                idCliente: cliente,
                idResponsavel: responsavel,
                titulo,
                dataInicio,
                previsaoTermino,
                automacao: false,
                recorrencia: null,
                recorrenciaDia: null,
                recorrenciaMes: null
            }

            const resposta = await novoProcesso(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDadosProcesso()
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
            
        }
    }

    const updateProcesso = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const idProcesso = paramsUrl.get('idProcesso')

        if (idProcesso === null || responsavel === undefined || situacao === undefined || previsaoTermino === undefined || titulo === undefined) {
            return setProcessando(false)
        }

        const payload = {
            responsavel,
            titulo,
            situacao,
            previsaoTermino
        }

        try {

            const resposta = await atualizarProcesso(idProcesso, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDadosProcesso()
                return
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
        }
    }

    useEffect(() => {
        setModalTarefa(modalTarefasProcesso)

        if(!modalTarefasProcesso) setTarefaSelecionada(undefined)

    }, [modalTarefasProcesso])

    useEffect(() => {
        
        const buscarResponsaveis = async () => {

            try {

                const params = {
                    termo: '',
                    situacao: 'ativo'
                }

                const resposta = await listarUsuariosOrganizacao(params)

                if (resposta.status === 200) {
                    return setResponsaveis(resposta.data)
                }
                
            } catch (error) {

                return alert('Falha ao Listar os Responsáveis')
                
            }
        }

        buscarResponsaveis ()

    }, [])

    useEffect(() => {

        const buscarClientes = async () => {

            try {

                const params = {
                    tipo: 'cliente',
                    termo: '',
                    pagina: 1,
                    situacao: 'ativo'
                }

                const resposta = await listarCadastros(params)

                if (resposta.status === 200) {
                    return setClientes(resposta.data)
                }
                
            } catch (error) {

                return alert('Erro ao Listar os Clientes')
                
            }
        }

        buscarClientes ()

    }, [])

    useEffect(() => {

        const buscarModelosProcessos = async () => {

            try {

                const params = {
                    termo: '',
                    situacao: 'ativo'
                }

                const resposta = await listarModelosProcessos(params)

                if (resposta.status === 200) {
                    return setProcessosModelos(resposta.data)
                }
                
            } catch (error) {

                return alert('Erro ao Listar os Modelos de Processos')
                
            }
        }

        buscarModelosProcessos ()

    }, [])

    useEffect(() => {

        const buscarTarefas = async () => {

            const params = new URLSearchParams(search)
            const idProcesso = params.get('idProcesso')

            if(!idProcesso) {
                return
            }

            try {

                const resposta = await tarefasProcesso(idProcesso)

                if (resposta.status === 200) {
                    setTarefasProcesso(resposta.data)
                    setProcessando(false)
                    return
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return
            }
        }

        buscarTarefas ()

    }, [atualizarInformacoesProcesso, search])


    return (

        <div id = 'fundoModal'>
            {modalTarefa && <ModalTarefas tarefa={tarefaSelecionada} />}
            {excluirTarefa && <Notificacoes icone={confirmacaoImg} texto='Tarefa Exclúida com Sucesso' cor='sucesso' />}
            {confirmacao && <ModalAlerta texto= {processo ? 'Dados atualizados com sucesso' : 'Dados Salvos com Sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModalAlerta}/>}
            {confirmacaoExclusao && <ModalAlerta texto='Dados Atualizados com Sucesso' btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModalAlerta} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            <section id={processo ? 'containerModalNovo': ''} className={!processo ? 'containerModalCentro' : ''}>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={processoImg} alt='' />
                    <p>{!processo ? 'Novo Processo': 'Vizualizar Processo'}</p>
                </span>
                <form id='formModalNovo' onSubmit={processo ? updateProcesso : cadastrarProcesso}>
                    {processo && <section>
                        <span>
                            <label htmlFor="clienteProcesso">Cliente</label>
                            <input 
                                value={processo?.clienteProcesso.nomeFantasia}
                                disabled
                                type="text" />
                        </span>

                        <span>
                            <label htmlFor="tituloProcesso">Processo</label>
                            <input
                                id='tituloModeloProcesso'
                                value={processo?.modelosProcesso.titulo}
                                disabled
                                type="text" />
                        </span>

                        <span>
                            <label htmlFor="tituloProcesso">Título do Processo</label>
                            <input 
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)}
                                type="text"
                                required />
                        </span>

                        <span>
                            <label htmlFor="situacaoModeloProcesso">Situação</label>
                            <select id="situacaoModeloProcesso" value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                <option value="ativo">Ativo</option>
                                <option value="cancelado">Cancelado</option>
                                <option value="concluído">Concluído</option>
                            </select>
                        </span>
                        
                    </section>}
                    {!processo && <section>
                        <span>
                            <label htmlFor="clienteProcesso">Selecione o Cliente</label>
                            <select id='clienteProcesso' value={cliente} onChange={(e) =>setCliente(e.target.value)} required>
                                <option value=""></option>
                                {clientes.map((cliente) => (
                                    <option key={cliente.id} value={cliente.id}>{cliente.nomeFantasia}</option>
                                ))}
                            </select>
                        </span>
                    </section>}

                    {!processo && <section>
                        <span>
                            <label htmlFor="modeloProcessoLista">Selecione o Processo</label>
                            <select id="modeloProcessoLista" value={processoModelo} onChange={(e) => setProcessoModelo(e.target.value)} required>
                                <option value=""></option>
                                {processosModelos.map((processo) => (
                                    <option key={processo.id} value={processo.id}>{processo.titulo}</option>
                                ))}
                            </select>
                        </span>

                        <span>
                            <label htmlFor="tituloProcesso">Título do Processo</label>
                            <input 
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)}
                                type="text"
                                required />
                        </span>
                    </section>}

                    <section>
                        <span>
                            <label htmlFor="responsavelProcesso">Responsavel</label>
                            <select id='responsavelProcesso' value={responsavel} onChange={(e) => setResponsavel(e.target.value)} required>
                                <option value=""></option>
                                {resposaveis.map((responsavel) => (
                                    <option key={responsavel.id} value={responsavel.id}>{responsavel.nome}</option>
                                ))}
                            </select>
                        </span>
                        {!processo && <span>
                            <label htmlFor="dataInicioProcesso">Data de Início</label>
                            <input 
                                id='dataInicioProcesso'
                                type="date"
                                value={dataInicio}
                                required
                                onChange={(e) => setDataInicio(e.target.value)}/>
                        </span>}
                        <span>
                            <label htmlFor="prazoProcessoTermino">Previsão de Término</label>
                            <input 
                                id='prazoProcessoTermino'
                                type="date"
                                value={previsaoTermino ? previsaoTermino : ''}
                                required
                                onChange={(e) => setPrevisaoTermino(e.target.value)}/>
                        </span>
                    </section>
                    {processo && <div id='tabelaProcessosTarefas'>
                        <div className='tarefasProcessosAdicionarTarefa'>
                            <p>Tarefas do Processo</p>
                            <Button type='button' cor='salvar' isLoading={processando} onClick={exibirModalTarefaProcesso}>Adicionar Tarefa ao Processo</Button>
                        </div>
                        <div className='tabelaListaTarefasProcessos'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th id='ladoEsquerdoContent' align='left'>Título</th>
                                        <th id='tipoTarefaProcesso' align='left'>Tipo</th>
                                        <th align='left'>Responsável</th>
                                        <th id='prazoTarefaProcesso' align='center'>Prazo</th>
                                        <th id='situacaoTarefaProcesso' align='center'>Situção</th>
                                        <Perfil perfil={['master']}>
                                            <th align='center'>Ação</th>
                                        </Perfil>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tarefasProcessos.map((tarefa) => (
                                        <tr key={tarefa.id}>
                                            <td id='primeiraColuna' onClick={() => exibirModalDadosTarefa(tarefa, tarefa.id)}>{tarefa.titulo}</td>
                                            <td id='tipoTarefaProcesso' onClick={() => exibirModalDadosTarefa(tarefa, tarefa.id)}>{`${tarefa.tipo.charAt(0).toUpperCase()}${tarefa.tipo.substring(1)}`}</td>
                                            <td className='usuarioResponsavelContent' onClick={() => exibirModalDadosTarefa(tarefa, tarefa.id)}>
                                                {<img className='usuarioResponsavel' src={tarefa.usuarioResponsavel.avatar ? tarefa.usuarioResponsavel.avatar : avatarDefault} alt=''/>}
                                                {tarefa.usuarioResponsavel.nome}
                                            </td>
                                            <td id='prazoTarefaProcesso' align='center' onClick={() => exibirModalDadosTarefa(tarefa, tarefa.id)}>{`${tarefa.dataEntrega.slice(8,10)}-${tarefa.dataEntrega.slice(5,7)}-${tarefa.dataEntrega.slice(0,4)}`}</td>
                                            <td id='situacaoTarefaProcesso' align='center' onClick={() => exibirModalDadosTarefa(tarefa, tarefa.id)}>
                                                <DivSituacao texto={`${tarefa.situacao.charAt(0).toUpperCase()}${tarefa.situacao.substring(1)}`} cor={tarefa.situacao}/>
                                            </td>
                                            <Perfil perfil={['master']}>
                                                <td id='ladoDireitoContent' align='center'>
                                                    {tarefa.situacao !== 'concluída' ? <img id='apagarIconeTarefa' title='Excluir Tarefa' src={apagarImg} alt='' onClick={() => deletarTask(tarefa.id)}/> : null}
                                                </td>
                                            </Perfil>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>}
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModalProcessos}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalProcessos
