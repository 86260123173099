import { useState, useEffect, FormEvent } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { setExibirModalNovoUsuario } from '../../store/modules/modalMovoUsuario'
import { listarUsuariosOrganizacao, atualizarUsuario } from '../../services/usuarios'
import { usuariosRespostaPayload } from '../../interfaces/usuarios'
import FiltrosDefault from '../FiltrosDefault'
import avatarDefault from '../../assets/img/avatarDefault.png'
import Perfil from '../../middleware/perfil'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import ModalAlerta from '../ModalAlerta'
import ModalUsuario from '../ModalUsuario'
import LoagindBarra from '../LoadingBarra'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import '../../styles/paginaListaDados.css'
import './usuarios.css'

function Usuarios() {

    const atualizarDados = useSelector((store: RootStore) => store.atualizarDados)
    const modalNovoUsuario = useSelector((store: RootStore) => store.modalMovoUsuario)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname } = location

    const [usuarios, setUsuarios] = useState<usuariosRespostaPayload[]>([])
    const [termo, setTermo] = useState('')
    const [situacao, setSituacao] = useState('ativo')
    const [modalUsuario, setModalUsuario] = useState(false)
    const [processando, setProcessando] = useState(false)
    const [sucessoAtualizarUsuario, setSucessoAtualizarUsuario] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [erroListarUsuarios, setErroListarUsuarios] = useState(false)
    const [erroAtualizarUsuario, setErroAtualuzarUsuario] = useState(false)
    const [usuarioSelecionado, setUsuarioSelecionado] = useState<usuariosRespostaPayload>()
    const [limiteUsuarios, setLimiteUsuario] = useState(false)

    const exibirModalNovoUsuario = () => {

        dispatch(setExibirModalNovoUsuario({
            exibir: true
        }))

        return
    }

    const exibirModalDadosUsuario = (usuario: usuariosRespostaPayload, idUsuario: string) => {

        navigate(`${pathname}?idUsuario=${idUsuario}`)

        setUsuarioSelecionado(usuario)

        return dispatch(setExibirModalNovoUsuario({
            exibir: true
        }))
    }

    const ocultarModalErro = () => {

        setErroListarUsuarios(false)
        setErroAtualuzarUsuario(false)

        return
    }

    const ocultarModalSucesso = () => {

        setSucessoAtualizarUsuario(false)

        return
    }

    function ocultarModalAlertaLimiteUsuario () {

        setLimiteUsuario(false)

        return
    }

    const pesquisarUsuario = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)
        setNenhumResultado(false)

        try {

            const params = {
                termo,
                situacao
            }

            const resposta = await listarUsuariosOrganizacao(params)

            if (resposta.status === 200) {
                setProcessando(false)

                if (resposta.data.length === 0) {
                    setNenhumResultado(true)
                }

                return setUsuarios(resposta.data)
            }

        } catch (error) {

            setProcessando(false)

            return setErroListarUsuarios(true)
        }
    }

    const toggleUsuario = async (idUsuario: string, situacao: string, e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                situacao: situacao === 'ativo' ? 'inativo' : 'ativo'
            }

            const resposta = await atualizarUsuario(idUsuario, payload)

            if (resposta.status === 200) {
                setProcessando(false)

                dispatch(setAtualizarDados({
                    atualizar: (atualizarDados.atualizar === true ? false : true)
                }))

                return setSucessoAtualizarUsuario(true)
            }

        } catch (error: any) {

            if(error.response.status === 408) {
                setProcessando(false)
                setLimiteUsuario (true)

                return
            }

            setProcessando(false)

            return setErroAtualuzarUsuario(true)
        }
    }

    useEffect(() => {
        setModalUsuario(modalNovoUsuario.exibir)
        if (!modalNovoUsuario.exibir) setUsuarioSelecionado(undefined)

    }, [modalNovoUsuario])

    useEffect(() => {

        const listarUsuarios = async () => {

            setProcessando(true)

            try {

                const params = {
                    termo: '',
                    situacao: 'ativo'
                }

                const resposta = await listarUsuariosOrganizacao(params)


                return setUsuarios(resposta.data)


            } catch (error) {

                return setErroListarUsuarios(true)

            } finally {
                setProcessando(false);
            }
        }

        listarUsuarios()

    }, [navigate, atualizarDados])

    return (
        <Perfil perfil={['master', 'administrativo']}>
            <div id='paginaContainer'>
                {modalUsuario && <ModalUsuario usuario={usuarioSelecionado} />}
                {processando && <LoagindBarra />}
                {sucessoAtualizarUsuario && <ModalAlerta texto='Dados do Usuário atualizados com Sucesso' icone={confirmacaoImg} btnTitulo='Continuar' cor='continuarSucesso' acao={ocultarModalSucesso} />}
                {erroListarUsuarios && <ModalAlerta texto='Ocorreu um erro ao listar os usuários da sua empresa, favor tente novamente!' icone={erroImg} btnTitulo='Tentar Novamente' cor='continuarErro' acao={ocultarModalErro} />}
                {erroAtualizarUsuario && <ModalAlerta texto='Ocorreu um erro ao tentar atualizar os dados do usuário, favor tente novamente!' icone={erroImg} btnTitulo='Tentar Novamente' cor='continuarErro' acao={ocultarModalErro} />}
                {nenhumResultado && <NenhumResultadoEncontrado />}
                {limiteUsuarios && <ModalAlerta texto='Limite de Usuários Ativos do Plano Atingido.'  icone={erroImg} cor='continuarErro' acao={ocultarModalAlertaLimiteUsuario} btnTitulo='Continuar'/>}
                <FiltrosDefault termo={termo} situacao={situacao} tipo='usuário' placeholder='Pesquise pelo nome do usuário' onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={pesquisarUsuario} acaoBtn={() => exibirModalNovoUsuario()} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Ativos', valor: 'ativo'}, {nome: 'Inativos', valor: 'inativo'}]} genero='masculino' isLoading={processando}/>

                <section id='fundoContainerPagina'>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th id='ladoEsquerdoContent' align='left'>Nome</th>
                                <th id='emailUsuario' align='left'>E-mail</th>
                                <th id='perfilUsuario' align='left'>Perfil</th>
                                <th align='center'>Avatar</th>
                                <th align='center'>Situação</th>
                                <th id='tabelaAcaoUsuario' align='center'>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuarios.map((usuario) => (
                                <tr key={usuario.id}>
                                    <td id='primeiraColuna' align='left' onClick={() => exibirModalDadosUsuario(usuario, usuario.id)}>{usuario.nome}</td>
                                    <td id='emailUsuario' align='left' onClick={() => exibirModalDadosUsuario(usuario, usuario.id)}>{usuario.email}</td>
                                    <td id='perfilUsuario' align='left' onClick={() => exibirModalDadosUsuario(usuario, usuario.id)}>{usuario.perfil[0].toUpperCase() + usuario.perfil.substring(1)}</td>
                                    <td id='avatarListaUsuario' align='center' onClick={() => exibirModalDadosUsuario(usuario, usuario.id)}>
                                        <img id='avatarUsuarioGesttor' src={usuario.avatar ? usuario.avatar : avatarDefault} alt='' />
                                    </td>
                                    <td align='center' onClick={() => exibirModalDadosUsuario(usuario, usuario.id)}>{usuario.situacao[0].toUpperCase() + usuario.situacao.substring(1)}</td>
                                    <td id='ladoDireitoContent' align='center' onClick={(e) => toggleUsuario(usuario.id, usuario.situacao, e)}>
                                        <input
                                            checked={usuario.situacao === 'ativo' ? true : false}
                                            className='toggleListaUsuarios'
                                            id={usuario.id}
                                            type="checkbox"
                                            readOnly
                                        />
                                        <label id='labelToggleListaUsuarios' htmlFor={usuario.id}></label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
        </Perfil>
    )
}

export default Usuarios;