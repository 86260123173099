import { createContext, useState } from 'react'
import { propsProvider } from '../../interfaces/propsProvider'
import { propsContextUsuario } from '../../interfaces/propsContextAuth'

const AuthContext = createContext<propsContextUsuario>({
    auth: false,
    isAuth: () => {}
})

const AuthProvider = ({ children }: propsProvider) => {

    const [auth, setAuth] = useState(false)
    
    const isAuth = () => {

        setAuth(!auth)

        return
    }

    return (
        <AuthContext.Provider value={{ auth, isAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }