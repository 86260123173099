import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import VisualizarProcessoSeletivo from "../../components/VisualizarProcessoSeletivo"

function VisualizarProcessosSeletivos() {

  useEffect(() => {
    document.title = "Processo Seletivo | Gesttor"
  }, [])

  return (
    <DadosContainerDefault>
      <VisualizarProcessoSeletivo />
    </DadosContainerDefault>
  )
}

export default VisualizarProcessosSeletivos