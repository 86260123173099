import BASEAPI from '../api/config'
import { paramsFiltrarCandidato, payloadAdicionarPergunta, payloadAtualizarPergunta } from '../../interfaces/BancoTalentos'

export function listarCandidatos (params?: paramsFiltrarCandidato) {
    return BASEAPI.get(`/listarCandidatos?nomeCompleto=${params?.nomeCompleto}&situacaoCadastral=${params?.situacaoCadastral}`)
}

export function adicionarPergunta (payload: payloadAdicionarPergunta) {
    return BASEAPI.post(`/discPergunta`, payload)
}

export function atualizarPergunta (idPergunta: string, idAlternativa: string, payload: payloadAtualizarPergunta) {
    return BASEAPI.patch(`/atualizarDiscPergunta/${idPergunta}/${idAlternativa}`, payload)
}

export function listarPerguntas () {
    return BASEAPI.get(`/listaDiscPerguntas`)
}