import { useEffect, useState, FormEvent } from 'react'
import { dre } from '../../services/relatorios'
import { lisatarCategorias } from '../../services/categorias'
import { lisatarCentros } from '../../services/centros'
import { listaCentrosRespostaPayload } from '../../interfaces/centros'
import { listaCategoriasResposta } from '../../interfaces/categorias'
import { dadosDreResposta } from '../../interfaces/relatorios'
import { centroSelecionadoDre } from '../../interfaces/dre'
import { Button, Loader } from '../Styles'
import Notificacoes from '../Notificacoes'
import LoadingBarra from '../LoadingBarra'
import erroImg from '../../assets/img/erroFechar.png'
import Perfil from '../../middleware/perfil'
import './dre.css'

function Dre () {

    const data = new Date()
    const ano = data.getFullYear()

    const [dadosDre, setDadosDre] = useState<dadosDreResposta[]>([])
    const [centros, setCentros] = useState<listaCentrosRespostaPayload[]>([])
    const [categorias, setCategorias] = useState<listaCategoriasResposta[]>([])
    const [centro, setCentro] = useState("")
    const [anoDre, setAnoDre] = useState<string | number>(ano)
    const [processando, setProcessando] = useState(false)
    const [erro, setErro] = useState(false)
    const [exibirCentroResultado, setExibirCentroResultado] = useState(false)
    const [exibirCentroCusto, setExibirCentroCusto] = useState(false)
    const [exibirCategoriasInvestimento, setExibirCategoriasInvestimento] = useState(false)
    const [exibirCategoriasParticipacoes, setExibirCategoriasParticipacoes] = useState(false)
    const [centroResultadoSelecionado, setCentroResultadoSelecionado] = useState<centroSelecionadoDre>({})
    const [centroCustoSelecionado, setCentroCustoSelecionado] = useState<centroSelecionadoDre>({})

    
    const exibirCategoriasDreInvestimento = () => setExibirCategoriasInvestimento(!exibirCategoriasInvestimento)
    const exibirCategoriasDreParticipacoes = () => setExibirCategoriasParticipacoes(!exibirCategoriasParticipacoes)
    
    const exibirCentroDreResultado = () => {

        setExibirCentroResultado(!exibirCentroResultado)
        setCentroResultadoSelecionado({})

        return
    }
    const exibirCentroDreCutso = () => { 
        
        setExibirCentroCusto(!exibirCentroCusto)
        setCentroCustoSelecionado({})

        return
    }

    const exibirCategoriasReceitasDre = (index: number) => {

        setCentroResultadoSelecionado((prevetState) => ({
            ...prevetState,
            [index]: !prevetState[index] 
        }))
    }

    const exibirCategoriasDespesasDre = (index: number) => {

        setCentroCustoSelecionado((prevetState) => ({
            ...prevetState,
            [index]: !prevetState[index] 
        }))
    }

    const buscarDadosDre = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const params = {
                ano: anoDre,
                idCentro: centro
            }

            const resposta = await  dre(params)

            if(resposta.status === 200) {
                setProcessando(false)

                return setDadosDre(resposta.data)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
        }
    }


    useEffect(() => {

        const buscarDadosDre = async () => {

            setProcessando(true)

            try {

                const params = {
                    ano,
                    idCentro: ''
                }

                const resposta = await  dre(params)

                if(resposta.status === 200) {
                    setProcessando(false)

                    return setDadosDre(resposta.data)
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 2000)
            }
        }

        buscarDadosDre()

    }, [ano])

    useEffect(() => {

        const buscarCentros = async () => {

            setProcessando(true)

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativo'
                }

                const resposta = await lisatarCentros(params)

                if(resposta.status === 200) {
                    setCentros(resposta.data)
                    setProcessando(false)

                    return
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 2000)
            }
        }

        buscarCentros()

    }, [])

    useEffect(() => {

        const buscarCategorias = async () => {

            setProcessando(true)

            try {

                const params = {
                    descricao: '',
                    situacao: ''
                }

                const resposta = await lisatarCategorias(params)

                if(resposta.status === 200) {
                    setCategorias(resposta.data)
                    setProcessando(false)

                    return
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 2000)
            }
        }

        buscarCategorias()

    }, [])

    return (
        <Perfil perfil={['master']}>
            <div id='paginaDre'>
                {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação' cor='erro' />}
                {processando && <LoadingBarra />}
                <section id='tituloDreContainer'>
                    <h1>Demostração de Resultado</h1>
                    <div>
                        <form onSubmit={buscarDadosDre}>
                            <span>
                                <label htmlFor="anoDre">Filtrar pelo Ano</label>
                                <input
                                    id='anoDre'
                                    value={anoDre}
                                    onChange={(e) => setAnoDre(e.target.value)}
                                    placeholder='Ex.: 2023'
                                    maxLength={4}
                                    minLength={4}
                                    pattern="^[0-9]{4}$" 
                                    type="text" 
                                    required/>
                            </span>
                            <span>
                                <label htmlFor="centroResultado">Filrar pelo Centro de Resultado</label>
                                <select id="centroResultado" value={centro} onChange={(e) => setCentro(e.target.value)}>
                                    <option value="">Todos</option>
                                    {centros.filter(centro => centro.tipo === 'Centro de Resultado').map((centro) => (
                                        <option key={centro.id} value={centro.id}>{centro.descricao}</option>
                                    ))}
                                </select>
                            </span>
                            <span>
                                <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Filtar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>
                            </span>
                        </form>
                    </div>
                </section>

                <section id='tabelaDreContainer'>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th className='ladoEsquerdoContent' align='left'></th>
                                <th align='right'>Jan</th>
                                <th align='right'>Fev</th>
                                <th align='right'>Mar</th>
                                <th align='right'>Abr</th>
                                <th align='right'>Mai</th>
                                <th align='right'>Jun</th>
                                <th align='right'>Jul</th>
                                <th align='right'>Ago</th>
                                <th align='right'>Set</th>
                                <th align='right'>Out</th>
                                <th align='right'>Nov</th>
                                <th align='right'>Dez</th>
                                <th className='ladoDireitoContent' align='right'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='grupoDre receitaOperacionalDre' key={Math.random()} onClick={() => exibirCentroDreResultado()}>
                                <td className='primeiraColuna primeiraColunaDre'>Receita Bruta</td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `02/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `03/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `04/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `05/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `06/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `07/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `08/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `09/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `10/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `11/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `12/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td className='ladoDireitoContent' align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia >= `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                           </tr>
                           {centros.filter(centro => centro.tipo === 'Centro de Resultado').map((centro, index) => (
                                <>
                                    {exibirCentroResultado && <tr key={Math.random()} className='centroDre' onClick={() => exibirCategoriasReceitasDre(index)}>
                                        <td className='centroDre primeiraColunaDre'>{centro.descricao}</td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `01/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `02/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `03/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `04/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `05/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `06/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `07/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `08/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `09/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `10/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `11/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `12/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right' className='ladoDireitoContent'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.idCentro === centro.id && dado.lancamento.tipo === 'receita' && dado.lancamento.competencia >= `01/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                    </tr>}
                                    {categorias.filter(categoria => categoria.tipo === 'receita' && categoria.centroResultado?.id === centro.id).map((categoria) => (
                                        <>
                                            { centroResultadoSelecionado[index] && <tr key={Math.random()}>
                                                <td className='categoriaDre primeiraColunaDre'>{categoria.descricao}</td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `01/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `02/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `03/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `04/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `05/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `06/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `07/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `08/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `09/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `10/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `11/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `12/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                                <td align='right' className='ladoDireitoContent'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia >= `01/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma.toFixed()

                                                    })()}
                                                </td>
                                            </tr>}
                                        </>
                                    ))}
                                </>  
                           ))}
                         
                           <tr className='grupoDre' key={Math.random()} onClick={() => exibirCentroDreCutso()}>
                                <td className='primeiraColuna primeiraColunaDre'>Despesas Operacionais</td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `01/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `02/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `03/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `04/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `05/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `06/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `07/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `08/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `09/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `10/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `11/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `12/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right' className='ladoDireitoContent'>
                                    {(() => {
                                        const soma = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia >= `01/${anoDre}` && dado.categoria.centroCusto?.descricao !== "Participações" && dado.categoria.centroCusto?.descricao !== "Investimentos")
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return soma.toFixed()

                                    })()}
                                </td>
                           </tr>
                           {centros.filter(centro => centro.tipo === 'Centro de Custo').map((centro, index) => (
                                <>
                                    {exibirCentroCusto && <tr key={Math.random()} className='centroDre' onClick={() => exibirCategoriasDespesasDre(index)}>
                                        <td className='centroDre primeiraColunaDre'>{centro.descricao}</td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `01/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `02/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `03/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `04/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `05/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `06/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `07/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `08/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `09/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `10/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `11/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia === `12/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                         <td align='right' className='ladoDireitoContent'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.centroCusto?.id === centro.id && dado.lancamento.competencia >= `01/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                         </td>
                                    </tr>}
                                    {categorias.filter(categoria => categoria.tipo === 'despesa' && categoria.centroCusto?.id === centro.id).map((categoria) => (
                                        <>
                                            { centroCustoSelecionado[index] && <tr key={Math.random()}>
                                                <td className='categoriaDre primeiraColunaDre'>{categoria.descricao}</td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `01/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `02/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `03/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `04/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `05/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `06/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `07/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `08/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `09/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `10/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `11/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `12/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                                <td align='right' className='ladoDireitoContent'>
                                                    {(() => {
                                                        const soma = dadosDre
                                                        .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia >= `01/${anoDre}`)
                                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                        return soma

                                                    })()}
                                                </td>
                                            </tr>}
                                        </>
                                    ))}
                                </>
                           ))}
                          
                          
                           <tr className='grupoDre lucroOperacionalDre' key={Math.random()}>
                                <td className='primeiraColuna primeiraColunaDre'>Lucro Operacional</td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `02/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `02/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `02/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `02/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `03/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `03/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `03/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `03/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `04/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `04/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `04/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `04/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `05/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `05/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `05/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `05/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `06/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `06/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `06/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `06/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `07/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `07/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `07/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `07/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `08/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `08/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `08/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `08/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `09/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `09/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `09/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `09/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `10/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `10/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `10/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `10/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `11/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `11/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `11/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `11/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `12/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `12/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `12/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `12/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                                <td align='right' className='ladoDireitoContent'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia >= `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia >= `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const investimentos = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia >= `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const participacoes = dadosDre
                                        .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia >= `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);


                                        return (receita - despesa + investimentos + participacoes).toFixed()

                                    })()}
                                </td>
                           </tr>
                           
                           <tr className='grupoDre' key={Math.random()} onClick={() => exibirCategoriasDreInvestimento()}>
                                <td className='primeiraColuna primeiraColunaDre'>Investimentos</td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `01/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `02/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `03/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `04/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `05/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `06/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `07/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `08/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `09/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `10/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `11/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia === `12/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right' className='ladoDireitoContent'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Investimentos' && dado.lancamento.competencia >= `01/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                           </tr>

                           {categorias.filter(categoria => categoria.centroCusto?.descricao === 'Investimentos').map((categoria) => (
                                <>
                                    {exibirCategoriasInvestimento && <tr key={Math.random()}>
                                        <td className='categoriaDre primeiraColunaDre'>{categoria.descricao}</td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `01/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `02/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `03/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `04/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `05/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `06/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `07/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `08/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `09/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `10/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `11/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `12/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right' className='ladoDireitoContent'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia >= `01/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                    </tr>}
                                </>
                           ))}

                           <tr className='grupoDre' key={Math.random()} onClick={() => exibirCategoriasDreParticipacoes()}>
                                <td className='primeiraColuna primeiraColunaDre'>Participações</td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `01/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `02/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `03/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `04/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `05/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `06/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `07/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `08/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `09/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `10/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `11/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia === `12/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                                <td align='right' className='ladoDireitoContent'>
                                    {(() => {
                                            const soma = dadosDre
                                            .filter(dado => dado.categoria.centroCusto?.descricao === 'Participações' && dado.lancamento.competencia >= `01/${anoDre}`)
                                            .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                            return soma.toFixed()

                                    })()}
                                </td>
                           </tr>

                           {categorias.filter(categoria => categoria.centroCusto?.descricao === 'Participações').map((categoria) => (
                                <>
                                    { exibirCategoriasParticipacoes && <tr key={Math.random()}>
                                        <td className='categoriaDre primeiraColunaDre'>{categoria.descricao}</td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `01/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `02/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `03/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `04/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `05/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `06/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `07/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `08/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `09/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `10/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `11/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia === `12/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                        <td align='right' className='ladoDireitoContent'>
                                            {(() => {
                                                const soma = dadosDre
                                                .filter(dado => dado.categoria.id === categoria.id && dado.lancamento.competencia >= `01/${anoDre}`)
                                                .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                                return soma.toFixed()

                                            })()}
                                        </td>
                                    </tr>}
                                </>
                           ))}

                           <tr className='grupoDre lucroLiquidoDre' key={Math.random()}>
                                <td className='primeiraColuna primeiraColunaDre'>Lucro Líquido</td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `02/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `02/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `03/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `03/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `04/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `04/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `05/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `05/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `06/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `06/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `07/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `07/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `08/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `08/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `09/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `09/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `10/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `10/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `11/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `11/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia === `12/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia === `12/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                                <td align='right' className='ladoDireitoContent'>
                                    {(() => {
                                        const receita = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'receita' && dado.lancamento.competencia >= `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        const despesa = dadosDre
                                        .filter(dado => dado.lancamento.tipo === 'despesa' && dado.lancamento.competencia >= `01/${anoDre}`)
                                        .reduce((acc, dado) => acc + Number(dado.totalMes), 0);

                                        return (receita - despesa).toFixed()

                                    })()}
                                </td>
                           </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </Perfil>
    )
}

export default Dre