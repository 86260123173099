import { useState, FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { cadastrarUsuario, atualizarUsuario } from '../../services/usuarios'
import { setOcultarModalNovoUsuario } from '../../store/modules/modalMovoUsuario'
import { telefoneMascara } from '../../helpers'
import { ModalUsuarioDados } from '../../interfaces/usuarios'
import { Button, Loader } from '../Styles'
import Organizacao from '../../middleware/organizacao'
import ModalAlerta from '../ModalAlerta'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import adicionarIcone from '../../assets/img/novoUsuario.png'
import atualizarIcone from '../../assets/img/usuario.png'
import '../../styles/modal.css'


function ModalUsuario ({ usuario }: ModalUsuarioDados) {

    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const dispatch = useDispatch ()
    const navigate = useNavigate ()
    const location = useLocation()
    const { pathname, search } = location

    const [nome, setNome] = useState(usuario?.nome || '')
    const [email, setEmail] = useState(usuario?.email || '')
    const [perfil, setPerfil] = useState(usuario?.perfil || 'administrativo')
    const [telefone, setTelefone] = useState(usuario?.telefone || null)
    const [situacao, setSituacao] = useState(usuario?.situacao || 'ativo')
    const [senha, setSenha] = useState('')
    const [confirmarSenha, setConfirmarSenha] = useState('')
    const [senhasDiferentes, setSenhasDiferentes] = useState(false)
    const [usuarioExiste, setUsuarioExiste] = useState (false)
    const [form, setForm] = useState(true)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [limiteUsuarios, setLimiteUsuario] = useState(false)
    const [erro, setErro] = useState(false)

    function ocultarModalNovoUsuario () {

        navigate(pathname)

        dispatch(setOcultarModalNovoUsuario({
            exibir: false
        }))
    }

    function ocultarModalAlertaUsuarioExiste () {

        setUsuarioExiste (false)
        setForm(true)

        return
    }

    function ocultarModalAlertaLimiteUsuario () {

        setLimiteUsuario(false)
       
        dispatch(setOcultarModalNovoUsuario({
            exibir: false
        }))

        return
    }

    function ocultarModalAlertaErro () {

        setErro (false)
        setForm(true)

        return
    }

    function conferirSenha () {

        if (senha !== confirmarSenha) {

            setSenha ('')
            setConfirmarSenha('')
            setSenhasDiferentes (true)

            return setTimeout (() => {
                setSenhasDiferentes (false)
            }, 3500)
        }
    }

    const changeTelefone = (e: any) => {
        return setTelefone(telefoneMascara(e))
    }

    const novoUsuario = async (e: FormEvent) => {

        e.preventDefault()
        setForm(false)
        setProcessando(true)

        try {

            const payload = {
                nome,
                email,
                perfil,
                senha,
                telefone
            }

            const resposta = await cadastrarUsuario(payload)

            if (resposta.status === 201) {

                dispatch(setAtualizarDados({
                    atualizar: (atualizarDados.atualizar === true ? false : true)
                }))
                setProcessando(false)
                return setConfirmacao (true)
            }
            
        } catch (error:any) {

            if(error.response.status === 405) {
                setProcessando(false)
                setUsuarioExiste(true)
            }

            if(error.response.status === 408) {
                setProcessando(false)
                setLimiteUsuario (true)
            }

            if(error.response.status === 400) {
                setProcessando(false)
                setErro (true)
            }
        }
    }

    const atualizarDadosUsuario = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)
        
        const queryString = search
        const parms = new URLSearchParams(queryString)
        const idUsuario = parms.get('idUsuario')

        if (!idUsuario) {
            return
        }

        const payload = {
            nome,
            telefone,
            perfil,
            situacao
        }

        try {

            const resposta = await atualizarUsuario(idUsuario, payload)

            if(resposta.status === 200) {

                setProcessando(true)
                
                setConfirmacao(true)

                dispatch(setAtualizarDados({
                    atualizar: (atualizarDados.atualizar === true ? false : true)
                }))
            }
            
        } catch (error: any) {

            if(error.response.status === 408) {
                setProcessando(false)
                setLimiteUsuario (true)

                return
            }

            setProcessando (false)

            return setErro (true)
            
        }
    }


    return (
        <div id = 'fundoModal'>
            {senhasDiferentes && <Notificacoes icone={erroImg} texto='As senhas não conferem. Digite novamente!' cor='erro'/>}
            {confirmacao && <ModalAlerta texto= {usuario ? 'Dados atualizados com sucesso' : 'Usuário Cadastrado com Sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModalNovoUsuario} />}
            {usuarioExiste && <ModalAlerta texto='Já Existe um Usuário com esse E-mail!' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlertaUsuarioExiste} />}
            {limiteUsuarios && <ModalAlerta texto='Limite de Usuários Ativos do Plano Atingido.'  icone={erroImg} cor='continuarErro' acao={ocultarModalAlertaLimiteUsuario} btnTitulo='Continuar'/>}
            {erro && <ModalAlerta texto='Ocorreu um erro ao tentar salvar os dados!' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlertaErro} />}
            {form && <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={!usuario ? adicionarIcone : atualizarIcone} alt='' />
                    <p>{!usuario ? 'Novo Usário': 'Atualizar Dados'}</p>
                </span>
                <form id='formModalNovo' onSubmit={!usuario ? novoUsuario : atualizarDadosUsuario}>
                    <section>
                        <span>
                            <label htmlFor="nomeUsario">Nome</label>
                            <input 
                                id='nomeUsario' 
                                type="text" 
                                value={nome}
                                placeholder='Digite o nome e sobre nome do usuário'
                                onChange={(e) => setNome(e.target.value)}
                                required/>
                        </span>
                    </section>

                    <section>
                        {!usuario && <span>
                            <label htmlFor="emailUsario">E-mail</label>
                            <input 
                                id='emailUsario' 
                                type="email" 
                                value={email}
                                placeholder='Digite o e-mail de acesso ao sistema'
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </span>}
                        <span>
                            <label htmlFor="telefoneUsario">Telefone</label>
                            <input 
                                id="telefoneUsuario" 
                                type="text" 
                                value={telefone === null ? '': telefone}
                                placeholder="(00) 0.0000 - 0000"
                                minLength={18}
                                maxLength={18}
                                onChange={(e) => changeTelefone(e.target.value)}
                                required/>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="perfilUsario">Perfil do Usuário</label>
                            <select id="perfilUsario" value={perfil} onChange={(e) => setPerfil(e.target.value)} required>
                                <option value="master">Master</option>
                                <option value="administrativo">Administrativo</option>
                                <option value="atendimento">Atendimento</option>
                                <option value="comercial">Comercial</option>
                                <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                                    <>
                                        <option value="consultor">Consultor</option>
                                        <option value="desenvolvedor">Desenvolvedor</option>
                                    </>
                                </Organizacao>
                                <option value="suporte">Suporte</option>
                            </select>
                        </span>

                        {usuario && <span>
                            <label htmlFor="situacaoUsario">Situação</label>
                            <select id="situacaoUsario" value={situacao} onChange={(e) => setSituacao(e.target.value)} required>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                        </span>}
                    </section>

                    {!usuario && <section>
                        <span>
                            <label htmlFor="senhaUsuario">Senha</label>
                            <input 
                                type="password"
                                id="senhaUsuario"
                                value={senha}
                                placeholder='Digite uma senha com 08 dígitos' 
                                minLength={8}
                                maxLength={8}
                                required
                                onChange={(e) => setSenha(e.target.value)}/>
                        </span>
                        <span>
                            <label htmlFor="confirmarSenhaUsuario">Confirmar Senha</label>
                            <input 
                                type="password"
                                id="confirmarSenhaUsuario"
                                value={confirmarSenha}
                                placeholder='Digite uma senha novamente' 
                                minLength={8}
                                maxLength={8}
                                required
                                onChange={(e) => setConfirmarSenha(e.target.value)}
                                onBlur={conferirSenha}/>
                        </span>
                    </section>}
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModalAlertaLimiteUsuario}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>}
        </div>
    )
}

export default ModalUsuario
