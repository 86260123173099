import BASEAPI from '../api/config'
import { cadastrarSuportePayload, paramsListaSuporte,  paramsListaSuporteOrganizacoes, comentarSuportePayload, atualizarSuportePayload } from '../../interfaces/suporte'


export function novoSuporte (payload: cadastrarSuportePayload) {
    return BASEAPI.post('/suporte', payload)
}

export function listarSuporte (params: paramsListaSuporte) {
    return BASEAPI.get (`/suporte?tipo=${params.tipo}&pagina=${params.pagina}&termo=${params.termo}&status=${params.status}`)
}

export function listarSuporteOrganizacoes (params: paramsListaSuporteOrganizacoes) {
    return BASEAPI.get (`/organizacao/suporte?tipo=${params.tipo}&pagina=${params.pagina}&termo=${params.termo}&status=${params.status}`)
}

export function comentarSuporte (idSuporte: string, payload: comentarSuportePayload) {
    return BASEAPI.post(`/suporte/comentarios?idSuporte=${idSuporte}`, payload)
}

export function listarComentariosSuporte (idSuporte: string) {
    return BASEAPI.get(`/suporte/comentarios?idSuporte=${idSuporte}`)
}

export function atualizarSuporte (idSuporte: string, payload: atualizarSuportePayload) {
    return BASEAPI.patch(`/suporte?idSuporte=${idSuporte}`, payload)
}



