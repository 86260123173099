import { useState, FormEvent, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { licencasGesttor } from '../../services/organizacao'
import { ModalOrganizacaoDados } from '../../interfaces/organizacao'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { Button, Loader } from '../Styles'
import ModalAlerta from '../ModalAlerta'
import licencaImg from '../../assets/img/licenca.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import '../../styles/modal.css'


function ModalGerenciarLicenca ({ organizacao }: ModalOrganizacaoDados) {

    const { exibirModal, atualizarDados } = useContext(ModalEstadoContext)

    const navigate = useNavigate ()
    const location = useLocation()
    const { pathname, search } = location

    const [usuariosAdicionais, setUsuariosAdicionais] = useState<number | null | undefined>(organizacao?.usuariosAdicionais)
    const [validadeLicenca, setValidadeLicenca] = useState<string | undefined>(organizacao?.validadeLicenca)

    const [form, setForm] = useState(true)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState(false)

    function ocultarModal () {

        navigate(pathname)
        exibirModal()
    }

    function ocultarModalAlertaErro () {

        setErro (false)
        setForm(true)

        return
    }

    const atualizarLicencas = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)
        setForm(false)
    
        const parms = new URLSearchParams(search)
        const id = parms.get('idCliente')

        if(validadeLicenca == null || validadeLicenca === undefined || id === null || usuariosAdicionais === undefined) {
            return setProcessando(false)
        }

        const payload = {
            validadeLicenca,
            usuariosAdicionais
        }

        try {

            const resposta = await licencasGesttor(id, payload)

            if(resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return
            }
            
        } catch (error: any) {

            setProcessando (false)
            setForm(true)

            return setErro (true)
            
        }
    }


    return (
        <div id = 'fundoModal'>
            {confirmacao && <ModalAlerta texto= {'Dados atualizados com sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModal} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao tentar salvar os dados!' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlertaErro} />}
            {form && <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={licencaImg} alt='' />
                    <p>Gerenciar Licenças</p>
                </span>
                <form id='formModalNovo' onSubmit={atualizarLicencas}>
                    <section>
                        <span>
                            <label htmlFor="nomeFantasia">Nome Fantasia</label>
                            <input 
                                id='nomeFantasia' 
                                type="text" 
                                value={organizacao?.nomeFantasia}
                                disabled/>
                        </span>
                        <span>
                            <label htmlFor="razaoSocial">Razão Social</label>
                            <input 
                                id='razaoSocial' 
                                type="text" 
                                value={organizacao?.razaoSocial === null ? '' : organizacao?.razaoSocial}
                                disabled/>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="usuariosAdicionais">Usuários Adicionais</label>
                            <input 
                                id="usuariosAdicionais"
                                type="number"
                                min={0}
                                value={usuariosAdicionais === null ? 0 : usuariosAdicionais}
                                onChange = {(e) => setUsuariosAdicionais(parseInt(e.target.value))} 
                                required/>
                        </span>
                        <span>
                            <label htmlFor="validadeLicenca">Validade da Licença</label>
                            <input 
                                id="validadeLicenca"
                                type="date"
                                value={validadeLicenca}
                                onChange={(e) => setValidadeLicenca(e.target.value)}
                                required/>
                        </span>
                    </section>
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>}
        </div>
    )
}

export default ModalGerenciarLicenca
