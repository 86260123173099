import { payloadAtualizarProcessoSeletivo, payloadCriarProcessoSeletivo, payloadProcessoInscritos, payloadProcessoEtapa } from '../../interfaces/processoSeletivo'
import BASEAPI from '../api/config'

export function listarProcessoSeletivo () {
    return BASEAPI.get(`/listarProcessoSeletivo`)
}

export function listarProcessoSeletivoPorId (idProcessoSeletivo:string) {
    return BASEAPI.get(`/listarProcessoSeletivo/${idProcessoSeletivo}`)
}

export function criarProcessoSeletivo (payload: payloadCriarProcessoSeletivo) {
    return BASEAPI.post(`/processoSeletivo`, payload)
}

export function atualizarProcessoSeletivo (payload: payloadAtualizarProcessoSeletivo, idProcessoSeletivo: string) {
    return BASEAPI.patch(`/atualizarProcessoSeletivo/${idProcessoSeletivo}`, payload)
}

export function atualizarProcessoInscrito (payload: payloadProcessoInscritos, idProcessoInscrito: string) {
    return BASEAPI.patch(`/processoInscrito/${idProcessoInscrito}`, payload)
}

export function criarProcessoEtapas (payload: payloadProcessoEtapa, idProcessoSeletivo: string) {
    return BASEAPI.post(`/processoEtapas/${idProcessoSeletivo}`, payload)
}

export function deletarEtapa (idEtapa: string) {
    return BASEAPI.delete(`/processoEtapas/${idEtapa}`)
}