import { dadosContratoResposta } from '../../interfaces/contratos'
import { moedaMascara } from '../../helpers'

function imprimirContato (contrato: dadosContratoResposta) {

    const { descricaoServico, valorContrato, descricaoPagamento, observacoes, organizaco, cadastro, contratosModelo } = contrato

    let topicos = ''

    contratosModelo.contratosTopicos.forEach( topico => {

        const novoTopico = `
            <b><p>${topico.descricao.toUpperCase()}</p></b>
        `
        topicos += novoTopico

        topico.contratosClausulas.forEach(clausula => {
            
            const novaClausula = `
                <p>${clausula.descricao}</p>
            `
            topicos += novaClausula
        })

    })

    return `
        <!DOCTYPE html>
            <html lang="pt-BR">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Contrato de Serviços</title>
                    <style>

                        @import url('https://fonts.googleapis.com/css2? família= Poppins:wght@400;500;700;900 & display=swap');

                        body{
                            font-family: 'Poppins', sans-serif;
                            line-height: 18px;
                             font-size: 12px;
                             width: 21cm;
                             margin: 0 auto;
                        }

                        header {
                            text-align: right;
                        }

                        h1 {
                            text-align: center;
                            font-size: 16px;
                        }

                        h2, h3 {
                            text-align: left;
                            margin-bottom: 20px;
                        }

                        p {
                            text-align: justify;
                        }

                        table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-bottom: 20px;
                        }

                        table, th, td {
                            border: 1px solid #000;
                        }

                        th, td {
                            padding: 8px;
                            text-align: left;
                        }

                        th {
                            background-color: #f2f2f2;
                        }

                        .cabecalhoTabela {
                            background-color: #6A6C72;
                        }

                        .section-title {
                            font-size: 1.2em;
                            margin-top: 30px;
                            text-decoration: underline;
                        }

                        .contract-section {
                            margin-bottom: 40px;
                        }

                        .description {
                            font-style: italic;
                        }

                        @media print {
                            .cabecalhoTabela {
                                background-color: #6A6C72;
                            }
                        }

                    </style>
                </head>
                <body>
                    <header>
                        <img src="https://homologacao.gesttor.co/static/media/logo.9508905048e8942a8171.png" alt="" width="130px">
                    </header>

                    <h1>CONTRATO DE PRESTAÇÃO DE SERVIÇOS</h1>

                    <div class="contract-section">
                        <table>
                            <tr >
                                <th colspan="2" class="cabecalhoTabela">
                                    IDENTIFICAÇÃO DO CONTRATADO
                                </th>
                            </tr>
                            <tr>
                                <th>Nome Fantasia</th>
                                <td>${organizaco.nomeFantasia.toUpperCase()}</td>
                            </tr>
                            <tr>
                                <th>CNPJ</th>
                                <td>${organizaco.cnpjCpf}</td>
                            </tr>
                            <tr>
                                <th>Razão Social</th>
                                <td>${organizaco.razaoSocial ? organizaco.razaoSocial.toUpperCase() : ''}</td>
                            </tr>
                            <tr>
                                <th>Endereço</th>
                                <td>${organizaco.rua ? organizaco.rua.toUpperCase() : ''} ${organizaco.numero ? `, ${organizaco.numero}` : ''} ${organizaco.bairro ? `, ${organizaco.bairro.toUpperCase()}` : ''} ${organizaco.cidade ? `, ${organizaco.cidade.toUpperCase()}` : ''} ${organizaco.estado ? `, ${organizaco.estado.toUpperCase()}` : ''}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="contract-section">
                        <table>
                            <tr>
                                <th colspan="2" class="cabecalhoTabela">
                                    IDENTIFICAÇÃO DO CONTRATANTE
                                </th>
                            </tr>
                            <tr>
                                <th>Nome Fantasia</th>
                                <td>${cadastro.nomeFantasia.toUpperCase()}</td>
                            </tr>
                            <tr>
                                <th>CNPJ</th>
                                <td>${cadastro.cnpjCpf}</td>
                            </tr>
                            <tr>
                                <th>Razão Social</th>
                                <td>${cadastro.razaoSocial.toUpperCase()}</td>
                            </tr>
                            <tr>
                                <th>Endereço</th>
                                <td>${cadastro.rua ? cadastro.rua.toUpperCase() : ''} ${cadastro.numero ? `, ${cadastro.numero}` : ''} ${cadastro.bairro ? `, ${cadastro.bairro.toUpperCase()}` : ''} ${cadastro.cidade ? `, ${cadastro.cidade.toUpperCase()}` : ''} ${cadastro.estado ? `, ${cadastro.estado.toUpperCase()}` : ''}</td>
                            </tr>
                            <tr>
                                <th>Telefone</th>
                                <td>${cadastro.telefone}</td>
                            </tr>
                            <tr>
                                <th>E-mail</th>
                                <td>${cadastro.email}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="contract-section">
                        <table>
                            <tr>
                                <th colspan="2" class="cabecalhoTabela">
                                    DOS SERVIÇOS CONTRATADOS
                                </th>
                            </tr>
                            <tr>
                                <th>Descrição</th>
                                <td>${descricaoServico}</td>
                            </tr>
                            <tr>
                                <th>Valor Total</th>
                                <td>${moedaMascara(valorContrato)}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="contract-section">
                        <table>
                            <tr>
                                <th colspan="2" class="cabecalhoTabela">
                                    DO PAGAMENTO
                                </th>
                            </tr>
                            <tr>
                                <th>Descrição</th>
                                <td>${descricaoPagamento}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="contract-section">
                        <table>
                            <tr>
                                <th colspan="2" class="cabecalhoTabela">
                                    INFORMAÇÕES ADICIONAIS
                                </th>
                            </tr>
                            <tr>
                                <th>Descrição</th>
                                <td>${observacoes ? observacoes.toUpperCase() : ''}</td>
                            </tr>
                        </table>
                    </div>

                    <p>Pelo presente instrumento particular de Contrato de Prestação de Serviços, celebrado, entre o contratado e o contratante acima mencionado e descriminado estabelecem de comum acordo, as seguintes disposições:</p>
                    
                    ${topicos}
                    
                </body>
            </html>

    `

}

export default imprimirContato
