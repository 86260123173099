import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import propsOrganizacao from "../../interfaces/propsOrganizacao"

const useOrganizacao =  () => {

    const [organizacao, setOrganizacao] = useState <string> ('')
    const navigate = useNavigate ()

    useEffect (() => {

        const organizacaoLogada = () => {
        
            try {
                const resposta = localStorage.getItem("organizacaoGesttor")

                if (resposta === null || resposta === undefined) {
                    return navigate('/')
                }

                setOrganizacao(resposta)
        
            } catch (error: any) {
                return alert (error)
            }
        }

        organizacaoLogada ()
        
    }, [setOrganizacao, navigate])

    return organizacao
}

const Organizacao = (props: propsOrganizacao) =>  {

    const organizacaoPerfil = useOrganizacao ()

    if (props.organizacao.some (organizacao => organizacaoPerfil.includes (organizacao))) {
        return props.children
    }

    return null
}

export default Organizacao