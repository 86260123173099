import './loading.css'

function LoadingCirculo () {
    return (
        <div id = 'loadingContainer'>
            <span id = "loadingCirculo"></span>
        </div>
    )
}

export default LoadingCirculo