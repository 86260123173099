import BASEAPI from "../api/config"
import { contaCadastrar, extratosParamsPayload, paramsListaContas, transferenciaPayload, atualizarContaPayload } from "../../interfaces/contas"

export function cadsatrarConta (payload: contaCadastrar) {
    return BASEAPI.post('/contas', payload)
}

export function atualizarConta (id: string, payload: atualizarContaPayload) {
    return BASEAPI.patch(`/contas/${id}`, payload)
}

export function listarContas (params: paramsListaContas) {
    return BASEAPI.get(`/contas?descricao=${params.descricao}&situacao=${params.situacao}`)
}

export function transferenciaBancaria (payload: transferenciaPayload) {
    return BASEAPI.post('/contas/transferencia', payload)
}

export function extratosBancarios (params: extratosParamsPayload) {
    return BASEAPI.get(`/contas/extrato?idConta=${params.idConta}&pagina=${params.pagina}&dataInicial=${params.dataInicial}&dataFinal=${params.dataFinal}`)
}