import baseApiHg from "../api/config"
import baseApiHgFormData from "../api/formData"
import { dadosInterCliente, certificadoInter, chaveInter} from '../../interfaces/inter'

export function statusIntegracao () {
    return baseApiHg.get('/inter/status')
}

export function novoDadosInter (payload: dadosInterCliente) {
    return baseApiHg.post('/inter/dados', payload)
}

export function novoCertificado (pauload: certificadoInter) {
    return baseApiHgFormData.post('/inter/certificado', pauload)
}

export function novaChave (payload: chaveInter) {
    return baseApiHgFormData.post('/inter/chave', payload)
}

export function gerarTokenInter () {
    return baseApiHg.post('/inter/token')
}

export function habilitarWebHook () {
    return baseApiHg.put('/inter/webhook')
}