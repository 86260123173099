import { FormEvent, useContext, useEffect, useState } from "react"
import { payloadCriarProcessoSeletivo, ModalProcessoSeletivoProps, payloadAtualizarProcessoSeletivo } from "../../interfaces/processoSeletivo"
import { Button } from "../Styles"
import { ModalEstadoContext } from "../../contexts/ModalEstado"
import { cepMascara } from "../../helpers"
import { atualizarProcessoSeletivo, criarProcessoSeletivo, listarProcessoSeletivoPorId } from "../../services/processosSeletivo"
import Notificacoes from "../Notificacoes"
import erroImg from "../../assets/img/erroFechar.png"
import confirmacao from "../../assets/img/confirmacao.png"
import { useParams } from "react-router-dom"
import { listarCadastros } from "../../services/cadastros"
import { listarUsuariosOrganizacao } from "../../services/usuarios"
import { usuariosRespostaPayload } from "../../interfaces/usuarios"
import { cadastrosRespostaPayload } from "../../interfaces/cadastros"
import dinheiroMascara from "../../helpers/dinheiroMascara"
import Perfil from "../../middleware/perfil"

function ModalProcessoSeletivo({ acaoBtn }: ModalProcessoSeletivoProps) {
  const { modalExibir } = useContext(ModalEstadoContext)
  const { idProcesso } = useParams();

  const [processando, setProcessando] = useState(false)
  const [erro, setErro] = useState(false)
  const [processoAdicionado, setProcessoAdicionado] = useState(false)
  const [erroData, setErroData] = useState(false)

  const [clientes, setClientes] = useState<cadastrosRespostaPayload[]>([])
  const [salario, setSalario] = useState("")

  const [resposaveis, setResponsaveis] = useState<usuariosRespostaPayload[]>([])

  const [processoSeletivo, setProcessoSeletivo] = useState<payloadCriarProcessoSeletivo>({
    titulo: "",
    descricaoCargo: "",
    principaisAtribuicao: "",
    salario: salario,
    beneficios: "",
    requisitos: "",
    qtnVagas: 1,
    area: "",
    cep: "",
    diferenciais: "",
    formacaoAcademica: "",
    perfilComportamental: "",
    modalidadeTrabalho: "",
    jornadaTrabalho: "",
    regimeContratacao: "",
    cargoLideranca: false,
    tipoProcesso: "",
    tempoProcesso: null,
    publicarVaga: null,
    repescagem: null,
    motivoRepescagem: null,
    dataInicio: "",
    inscricaoTermino: "",
    previsaoTermino: "",
    idResponsavel: "",
    idCliente: "",
    situacao: ""
  })

  const fecharModal = () => {
    modalExibir("")
  }

  const listarProcesso = async () => {
    try {
      if (idProcesso && processoSeletivo) {
        const encodedIdProcesso = encodeURIComponent(idProcesso);
        const processoSeletivoListado = await listarProcessoSeletivoPorId(encodedIdProcesso)
        setProcessoSeletivo(processoSeletivoListado.data)
        setSalario(processoSeletivoListado.data.salario)
      } else {
        return null
      }

    } catch (error) {
      setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const filtroPayload = (payload: payloadCriarProcessoSeletivo): payloadCriarProcessoSeletivo => {
    return Object.fromEntries(
      Object.entries(payload).filter(
        ([_, valor]) => valor !== null && valor !== undefined && valor !== ""
      )
    ) as payloadCriarProcessoSeletivo;
  };

  const buscarClientes = async () => {
    try {
      const params = {
        tipo: 'cliente',
        termo: '',
        pagina: 1,
        situacao: 'ativo'
      }
      const resposta = await listarCadastros(params)
      if (resposta.status === 200) {
        return setClientes(resposta.data)
      }

    } catch (error) {
      setProcessando(false)
      setErro(true)
      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const buscarResponsaveis = async () => {
    try {
      const params = {
        termo: '',
        situacao: 'ativo'
      }
      const resposta = await listarUsuariosOrganizacao(params)
      if (resposta.status === 200) {
        return setResponsaveis(resposta.data)
      }

    } catch (error) {
      setProcessando(false)
      setErro(true)
      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    if (processoSeletivo.dataInicio > (processoSeletivo.inscricaoTermino && processoSeletivo.previsaoTermino)) {
      setErroData(true)
      return setTimeout(() => {
        setErroData(false)
        setProcessando(false)
      }, 5000)
    }

    try {
      if (acaoBtn.toLowerCase() === "editar") {
        const payload: payloadAtualizarProcessoSeletivo = processoSeletivo;
        if (idProcesso) {
          const payloadFormatado = {
            ...payload,
            salario: salario.replace(/[^\d,]/g, '').replace(',', '.').slice(0, -2),
          };
          const idProcessoSeletivo = encodeURIComponent(idProcesso)

          await atualizarProcessoSeletivo(payloadFormatado, idProcessoSeletivo)

          listarProcesso()
          modalExibir("")
        }
      }
      if (acaoBtn.toLowerCase() === "criar") {
        const payload: payloadCriarProcessoSeletivo = filtroPayload(processoSeletivo);
        const payloadFormatado = {
          ...payload,
          salario: salario.replace(/[^\d,]/g, '').replace(',', '.').slice(0, -2),
        };
        await criarProcessoSeletivo(payloadFormatado)
        setProcessoAdicionado(true)
        listarProcesso()
        modalExibir("")
        return setTimeout(() => {
          setProcessoAdicionado(false)
        }, 5000)
      }

      setProcessando(false)
    } catch (error: any) {
      setProcessando(false)
      setErro(true)
      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  useEffect(() => {
    listarProcesso()
    buscarClientes()
    buscarResponsaveis()
  }, [])

  return (
    <div id="fundoModal">
      {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação. Tente Novamente!' cor='erro' />}
      {processoAdicionado && <Notificacoes icone={confirmacao} texto='Processo Seletivo adicionado com sucesso.' cor='sucesso' />}
      {erroData && <Notificacoes icone={erroImg} texto="A Data de inicio não pode ser maior que a data de termino e a data final." cor='erro' />}
      <section
        className="containerModalCentro overflow-auto"
      >
        <span id="novoContainerTitulo">
          <h1>Processo Seletivo</h1>
        </span>
        <form id="formModalNovo" onSubmit={(e) => handleSubmit(e)}>
          <section>
            <span>
              <label>Cliente</label>
              <select value={processoSeletivo.idCliente || ""} onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, idCliente: e.target.value }))} required>
                <option value="" disabled>Selecione uma opção</option>
                {clientes.map((cliente) => (
                  <option key={cliente.id} value={cliente.id}>{cliente.nomeFantasia}</option>
                ))}
              </select>
            </span>
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                <>
                    <span>
                    <label>Responsavel</label>
                    <select value={processoSeletivo.idResponsavel || ""} onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, idResponsavel: e.target.value }))} required>
                    <option value="" disabled>Selecione uma opção</option>
                    {resposaveis.map((responsavel) => (
                        <option key={responsavel.id} value={responsavel.id}>{responsavel.nome}</option>
                    ))}
                    </select>
                    </span>
                    {acaoBtn.toLowerCase() === "editar" && <span>
                    <label htmlFor="">Publicar vaga</label>
                    <select onChange={(e) => { setProcessoSeletivo((prev: any) => ({ ...prev, publicarVaga: e.target.value === "true" })) }} value={(processoSeletivo.publicarVaga !== null && processoSeletivo.publicarVaga).toString()} required>
                        <option value="" disabled>Selecione uma opção</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                    </span>}
                    {acaoBtn.toLowerCase() === "editar" &&
                    (<span>
                        <label htmlFor="">Situação</label>
                        <select onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, situacao: e.target.value }))} value={processoSeletivo.situacao} required>
                        <option value="" disabled>Selecione uma opção</option>
                        <option value="Aguardando Aprovação" >Aguardando Aprovação</option>
                        <option value="Aguardando Inscritos">Aguardando Inscritos</option>
                        <option value="Recrutando">Recrutando</option>
                        <option value="Atrasado">Atrasado</option>
                        <option value="Repescagem">Repescagem</option>
                        <option value="nps">nps</option>
                        <option value="Finalizado">Finalizado</option>
                        </select>
                    </span>
                    )}
                </>
            </Perfil>
          </section>
          <section>
            <span>
              <label htmlFor="titulo">Nome do Cargo</label>
              <input type="text" placeholder='Titulo do Cargo' onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, titulo: e.target.value }))} defaultValue={processoSeletivo.titulo} maxLength={100} required />
            </span>
            <span>
              <label htmlFor="">Regime</label>
              <select onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, regimeContratacao: e.target.value }))} value={processoSeletivo.regimeContratacao} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Estágio">Estágio</option>
                <option value="Clt">Clt</option>
                <option value="Contrato">Contrato</option>
                <option value="Diarista">Diarista</option>
                <option value="Outros">Outros</option>
              </select>
            </span>
            <span>
              <label htmlFor="">Tipo de Processo</label>
              <select onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, tipoProcesso: e.target.value }))} value={processoSeletivo.tipoProcesso} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Simplificado" disabled={processoSeletivo.tipoProcesso === "Personalizado"}>Simplificado</option>
                <option value="Personalizado">Personalizado</option>
              </select>
            </span>
          </section>
          <section>
            <span>
              <label htmlFor="salario">Salário</label>
              <input type="text" placeholder='Valor do Salário' onChange={(e) => setSalario(dinheiroMascara(e.target.value))} value={salario} maxLength={12} />
            </span>
            <span>
              <label htmlFor="">Quantidade de vagas</label>
              <input type="number" placeholder='Insira quantas vagas' onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, qtnVagas: parseInt(e.target.value) }))} min={1} value={processoSeletivo.qtnVagas} required />
            </span>
            <span>
              <label htmlFor="">Jornada de Trabalho</label>
              <input type="text" placeholder='Jornada de Trabalho' onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, jornadaTrabalho: e.target.value }))} value={processoSeletivo.jornadaTrabalho} maxLength={20} required />
            </span>
            <span>
              <label htmlFor="Modalidade de Trabalho">Modalidade de Trabalho</label>
              <select onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, modalidadeTrabalho: e.target.value }))} value={processoSeletivo.modalidadeTrabalho} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Presencial">Presencial</option>
                <option value="Home-Office">Home-Office</option>
                <option value="Híbrido">Híbrido</option>
              </select>
            </span>
          </section>
          <section>
            <span>
              <label htmlFor="">PerFil Comportamental</label>
              <select onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, perfilComportamental: e.target.value }))} value={processoSeletivo.perfilComportamental} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Dominante">Dominante</option>
                <option value="Influente">Influente</option>
                <option value="Estável">Estável</option>
                <option value="Condescendente">Condescendente</option>
              </select>
            </span>
            <span>
              <label htmlFor="">Formação Acadêmica</label>
              <select onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, formacaoAcademica: e.target.value }))} value={processoSeletivo.formacaoAcademica} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Nível Fundamental">Nível Fundamental</option>
                <option value="Nível Médio">Nível Médio</option>
                <option value="Nível Superior">Nível Superior</option>
                <option value="Pós - Graduação">Pós - Graduação</option>
                <option value="Mestrado">Mestrado</option>
                <option value="Doutorado">Doutorado</option>
                <option value="Phd">Phd</option>
              </select>
            </span>
            <span>
              <label htmlFor="">Área</label>
              <select onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, area: e.target.value }))} value={processoSeletivo.area} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Tecnologia Da Informação">Tecnologia Da Informação</option>
                <option value="Saúde">Saúde</option>
                <option value="Engenharia">Engenharia</option>
                <option value="Finanças e Contabilidade">Finanças e Contabilidade</option>
                <option value="Administrativo">Administrativo</option>
                <option value="Atendimento">Atendimento</option>
                <option value="Marketing">Marketing</option>
                <option value="Vendas">Vendas</option>
                <option value="Educação">Educação</option>
                <option value="Recursos Humanos">Recursos Humanos</option>
                <option value="Construção Civil">Construção Civil</option>
                <option value="Logística">Logística</option>
              </select>
            </span>
            <span>
              <label htmlFor="">Cargo liderança?</label>
              <select onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, cargoLideranca: e.target.value === "true" }))} defaultValue={acaoBtn.toLowerCase() === "editar" ? (processoSeletivo.cargoLideranca).toString() : ""} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </span>
          </section>

          <section>
            <span>
              <label htmlFor="">Data de Inicio Do Processo</label>
              <input type="date" name="" id="" onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, dataInicio: e.target.value }))} disabled={acaoBtn.toLowerCase() === "editar"} value={processoSeletivo.dataInicio ? new Date(processoSeletivo.dataInicio).toISOString().split("T")[0] : ""} maxLength={8} required />
            </span>
            <span>
              <label htmlFor="">Término da Inscrição</label>
              <input type="date" name="" id="" onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, inscricaoTermino: e.target.value }))} disabled={acaoBtn.toLowerCase() === "editar"} value={processoSeletivo.inscricaoTermino ? new Date(processoSeletivo.inscricaoTermino).toISOString().split("T")[0] : ""} required min={processoSeletivo.dataInicio}/>
            </span>
            <span>
              <label htmlFor="">Previsão de termino do Processo:</label>
              <input type="date" name="" id="" onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, previsaoTermino: e.target.value }))} disabled={acaoBtn.toLowerCase() === "editar"} value={processoSeletivo.previsaoTermino ? new Date(processoSeletivo.previsaoTermino).toISOString().split("T")[0] : ""} required  min={processoSeletivo.inscricaoTermino}/>
            </span>
          </section>
          <section>
            {acaoBtn.toLowerCase() !== "editar" && <span>
              <label htmlFor="">CEP</label>
              <input type="text" placeholder='Digite o CEP da localização da vaga' minLength={11} maxLength={11} onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, cep: cepMascara(e.target.value) }))} value={processoSeletivo.cep} required />
            </span>}
          </section>

          <section>
            <span>
              <label htmlFor="">Descrição do Cargo</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, descricaoCargo: e.target.value }))} defaultValue={processoSeletivo.descricaoCargo} required></textarea>
            </span>
            <span>
              <label htmlFor="">Principais Atribuições</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, principaisAtribuicao: e.target.value }))} defaultValue={processoSeletivo.principaisAtribuicao} maxLength={500} required></textarea>
            </span>
          </section>

          <section>
            <span>
              <label htmlFor="">BeneFicios</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, beneficios: e.target.value }))} defaultValue={processoSeletivo.beneficios} maxLength={500}></textarea>
            </span>
            <span>
              <label htmlFor="">Requisitos</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, requisitos: e.target.value }))} defaultValue={processoSeletivo.requisitos} maxLength={500}></textarea>
            </span>
            <span>
              <label htmlFor="">Diferenciais</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setProcessoSeletivo((prev: any) => ({ ...prev, diferenciais: e.target.value }))} defaultValue={processoSeletivo.diferenciais} maxLength={500}></textarea>
            </span>
          </section>

          <span id="botoesContainer">
            <Button type="button" cor="cancelar" onClick={fecharModal} isLoading={processando}>
              Cancelar
            </Button>
            <Button type="submit" cor="enviar" isLoading={processando}>
              Adicionar Processo
            </Button>
          </span>
        </form>
      </section>
    </div>
  )
}

export default ModalProcessoSeletivo