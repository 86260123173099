import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import Dre from "../../components/Dre";

function DrePage () {

    useEffect (() => {
        document.title = 'Demostração de Resultado | Gesttor'
    }, [])


    return (
        <DadosContainerDefault>
            <Dre />
        </DadosContainerDefault>
    )
}

export default DrePage