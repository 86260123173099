import { useState, FormEvent, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { novaCategoria, atualizarCategoria } from '../../services/categorias'
import { lisatarCentros } from '../../services/centros'
import { ModalCategoriaDados } from '../../interfaces/categorias'
import { listaCentrosRespostaPayload } from '../../interfaces/centros'
import { Button, Loader } from '../Styles'
import ModalAlerta from '../ModalAlerta'
import contasGerenciaisImg from '../../assets/img/contasGerenciais.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import '../../styles/modal.css'


function ModalCategoria ({ categoria }: ModalCategoriaDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { exibirModal, atualizarDados } = useContext(ModalEstadoContext)

    const { pathname, search } = location

    const [centros, setCentros] = useState<listaCentrosRespostaPayload[]>([])
    const [descricao, setDescricao] = useState(categoria?.descricao)
    const [tipo, setTipo] = useState(categoria?.tipo)
    const [centroResultado, setCentroResultado] = useState(categoria?.centroResultado?.id)
    const [centroCusto, setCentroCusto] = useState(categoria?.centroCusto?.id)
    const [situacao, setSituacao] = useState(categoria?.situacao)
    const [form, setForm] = useState(true)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState(false)

    function ocultarModal () {

        navigate(`${pathname}`)

        return exibirModal ()
    }

    function ocultarModalAlerta () {
        
        setForm(true)
        return setErro(false)
    }

    const cadastrarCategoria = async (e: FormEvent) => {

        e.preventDefault()
        setForm(false)
        setProcessando(true)

        if( descricao === undefined || tipo === undefined){
            setForm(true)
            return setProcessando(false)
        }

        try {

            const payload = {
                idCentroResultado: centroResultado,
                idCentroCusto: centroCusto,
                descricao,
                tipo
            }

            const resposta = await novaCategoria(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                setConfirmacao(true)

                return
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
            
            return
        }

    }

    const updateCategoria = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const id = paramsUrl.get('id')

        if (id === null) {
            return setProcessando(false)
        }

        if(descricao === undefined || tipo === undefined || situacao === undefined) {
            return setProcessando(false)
        }

        
        try {
            
            const payload = {
                idCentroResultado: centroResultado,
                idCentroCusto: centroCusto,
                descricao,
                tipo,
                situacao
            }

            const resposta = await atualizarCategoria(id, payload)

            if (resposta.status === 200) {
                setForm (false)
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()
                return
            }
            
        } catch (error) {

            setProcessando(false)
            setForm(false)
            setErro(true)

            return
        }
    }

    useEffect(() => {

        const buscarCentros = async () => {

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativo'
                }

                const resposta = await lisatarCentros(params)

                if(resposta.status === 200) {
                    setCentros(resposta.data)
                    setProcessando(false)

                    return
                }
                
            } catch (error) {
                
                return alert('Falha ao Listar os Centros')
            }
        }

        buscarCentros()

    }, [])


    return (
        <div id = 'fundoModal'>
            {confirmacao && <ModalAlerta texto= {categoria ? 'Dados atualizados com sucesso' : 'Dados Salvos com Sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModal} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            {form && <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={contasGerenciaisImg} alt='' />
                    <p>{!categoria ? 'Nova Categoria': 'Editar Categoria'}</p>
                </span>
                <form id='formModalNovo' onSubmit={categoria ? updateCategoria : cadastrarCategoria}>
                    <section>
                        <span>
                            <label htmlFor="descricaoCentro">Descrição</label>
                            <input
                                id='descricaoCentro'
                                value={descricao} 
                                onChange={(e) => setDescricao(e.target.value)}
                                type="text"
                                required/>
                        </span>

                        {categoria && <span>
                            <label htmlFor="situacaoCento">Situacao</label>
                            <select id="situacaoCento" value={situacao} onChange={(e) => setSituacao(e.target.value)} required>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                        </span>}
                    </section>

                    <section>
                        <span>
                            <label htmlFor="tipoCento">Tipo</label>
                            <select id="tipoCentro" value={tipo} onChange={(e) => setTipo(e.target.value)} required>
                                <option value=""></option>
                                <option value="receita">Receita</option>
                                <option value="despesa">Despesa</option>
                            </select>
                        </span>
                    </section>

                    <section>
                        {tipo === 'receita' ? <span>
                            <label htmlFor="centroResultado">Centro de Resultado</label>
                            <select id="centroResultado" value={centroResultado} onChange={(e) => setCentroResultado(e.target.value)} required>
                                <option value=""></option>
                                {centros.filter(centro => centro.tipo === 'Centro de Resultado').map((centro) => (
                                    <option key={centro.id} value={centro.id}>{centro.descricao}</option>
                                ))}
                            </select>
                        </span> : null}
                        {tipo === 'despesa' ? <span>
                            <label htmlFor="centroCusto">Centro de Custo</label>
                            <select id="centroCusto" value={centroCusto} onChange={(e) => setCentroCusto(e.target.value)} required>
                                <option value=""></option>
                                {centros.filter(centro => centro.tipo === 'Centro de Custo' || centro.tipo === 'Investimentos' || centro.tipo === 'Participações').map((centro) => (
                                    <option key={centro.id} value={centro.id}>{centro.descricao}</option>
                                ))}
                            </select>
                        </span> : null}
                    </section>
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>}
        </div>
    )
}

export default ModalCategoria
