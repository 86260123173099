import BASEAPI from '../api/config'
import BASEAPIFORMDATA from '../api/formData'
import { paramsUsuarioPayload, usuariosCadastrarPayload, usuariosAtualizarPayload, atualizarAvatarUsuario } from '../../interfaces/usuarios'

export function cadastrarUsuario (payload: usuariosCadastrarPayload) {
    return BASEAPI.post('/usuario', payload)
}

export function listarUsuariosOrganizacao (params: paramsUsuarioPayload) {
    return BASEAPI.get (`/usuario?termo=${params.termo}&situacao=${params.situacao}`)
}

export function atualizarUsuario (idUsuario: string, payload: usuariosAtualizarPayload) {
    return BASEAPI.patch (`/usuario/${idUsuario}`, payload)
}

export function dadosUsuario () {
    return BASEAPI.get ('/usuario/dados')
}

export function atualizarAvatar (payload: atualizarAvatarUsuario) {
    return BASEAPIFORMDATA.put('/usuario/avatar', payload)
}

