import { useEffect } from "react"
import SuportePage from "../../components/SuporteGesttor"
import { DadosContainerDefault } from "../../components/Styles"

function SuporteGesttor () {

    useEffect (() => {
        document.title = 'Suporte Gesttor | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <SuportePage />
        </DadosContainerDefault>
    )
}

export default SuporteGesttor