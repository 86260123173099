import { useEffect } from "react"
import Login from "../../components/Auth"

function Auth () {

    useEffect (() => {
        document.title = 'Login | Gesttor'
    }, [])
    
    return <Login />
}

export default Auth
