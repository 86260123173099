import { AppProvider } from "./providers"
import Routes from "./routes/Routes"
import "./styles/global.css"

function App() {

  return (
    <div className="App">
      <AppProvider>
        <Routes />
      </AppProvider>
    </div>
  )
}

export default App;
