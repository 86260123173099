import { useState, FormEvent, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ModelosProcessosContext } from '../../contexts/ModelosProcessos'
import { novaTarefaModelo, atualizarTarefaModelo } from '../../services/modelosTarefas'
import { ModalTarefaModeloDados } from '../../interfaces/modelosTarefas'
import { listarUsuariosOrganizacao } from '../../services/usuarios'
import { usuariosRespostaPayload } from '../../interfaces/usuarios'
import { Button, Loader } from '../Styles'
import ModalAlerta from '../ModalAlerta'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import processoImg from '../../assets/img/processos.png'
import '../../styles/modal.css'


function ModalTarefaModelo ({ tarefaModelo }: ModalTarefaModeloDados) {

    const { exibirModalTarefasProcesso, inserirTarefaModeloLocal, atualizarDadosProcesso } = useContext(ModelosProcessosContext)

    const location = useLocation()

    const { search } = location

    const [tipo, setTipo] = useState(tarefaModelo?.tipo)
    const [titulo, setTitulo] = useState(tarefaModelo?.titulo)
    const [prioridade, setPrioridade] = useState(tarefaModelo?.prioridade)
    const [descricao, setDescricao] = useState(tarefaModelo?.descricao)
    const [responsavel, setResponsavel] = useState(tarefaModelo?.responsavel.id)
    const [automacao, setAutomacao] = useState<boolean | undefined>(tarefaModelo?.automacao ?? false)
    const [recorrencia, setRecorrencia] = useState<string | null | undefined>(tarefaModelo?.recorrencia)
    const [prazo, setPrazo] = useState(tarefaModelo?.prazo)
    const [resposaveis, setResponsaveis] = useState<usuariosRespostaPayload[]>([])
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState(false)

    function ocultarModal () {
        return exibirModalTarefasProcesso ()
    }

    function ocultarModalAlerta () {
        return setErro(false)
    }

    const vincularTarefaModeloLocal = async (e: FormEvent) => {

        e.preventDefault()

        if(responsavel === undefined || titulo === undefined || descricao === undefined || prioridade === undefined || tipo === undefined || prazo === undefined || automacao === undefined) {
            return
        }

        const tarefaModelo = {
            idResponsavel: responsavel,
            titulo,
            prioridade,
            descricao,
            tipo,
            automacao,
            recorrencia,
            prazo
        }

        inserirTarefaModeloLocal(tarefaModelo)
        exibirModalTarefasProcesso()

        return tarefaModelo

    }

    const cadastrarTarefaModelo = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const parms = new URLSearchParams(search)
        const idModelo = parms.get('idModelo')

        if(!idModelo) {
            setProcessando(false)
            return vincularTarefaModeloLocal(e)
        }

        if(prazo === null || prazo === undefined || automacao === undefined) {
            return setProcessando(false)
        }

        try {

            const payload = {
                idModeloProcesso: idModelo,
                idResponsavel: responsavel,
                titulo,
                descricao,
                prioridade,
                tipo,
                automacao,
                recorrencia,
                prazo
            }

            const resposta = await novaTarefaModelo(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                exibirModalTarefasProcesso()
                atualizarDadosProcesso()
                setConfirmacao(true)

                return
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
            
            return
        }

    }

    const updateTarefaModelo = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const idModeloTarefa = location.hash.substring(1)


        if (!idModeloTarefa || responsavel === undefined || titulo === undefined || descricao === undefined || prioridade === undefined || tipo === undefined || prazo === undefined || automacao === undefined) {
            return setProcessando(false)
        }

        const payload = {
            idResponsavel: responsavel,
            titulo,
            descricao,
            prioridade,
            tipo,
            automacao,
            recorrencia,
            prazo
        }

        try {

            const resposta = await atualizarTarefaModelo(idModeloTarefa, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                exibirModalTarefasProcesso()
                return
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

            return
        }
    }


    useEffect(() => {
        
        const buscarResponsaveis = async () => {

            try {

                const params = {
                    termo: '',
                    situacao: 'ativo'
                }

                const resposta = await listarUsuariosOrganizacao(params)

                if (resposta.status === 200) {
                    return setResponsaveis(resposta.data)
                }
                
            } catch (error) {

                return alert('Falha ao Listar os Responsáveis')
                
            }
        }

        buscarResponsaveis ()

    }, [])


    return (
        <div id = 'fundoModal'>
            {confirmacao && <ModalAlerta texto= {tarefaModelo ? 'Dados atualizados com sucesso' : 'Dados Salvos com Sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModal} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={processoImg} alt='' />
                    <p>{!tarefaModelo ? 'Nova Tarefa Modelo': 'Vizualizar Tarefa Modelo'}</p>
                </span>
                <form id='formModalNovo' onSubmit={tarefaModelo ? updateTarefaModelo : cadastrarTarefaModelo}>
                    <section>
                        <span>
                            <label htmlFor="tituloTarefaModelo">Título</label>
                            <input
                                id='tituloTarefaModelo'
                                value={titulo}
                                onChange={((e) => setTitulo(e.target.value))} 
                                placeholder='Digite o título da tarefa'
                                type="text"
                                required />
                        </span>

                    </section>

                    <section>
                        <span>
                            <label htmlFor="tipoTarefaModelo">Tipo</label>
                            <select id='tipoTarefaModelo' value={tipo} onChange={(e) => setTipo(e.target.value)} required>
                                <option value=""></option>
                                <option value="atendimento">Atendimento</option>
                                <option value="chamado">Chamado</option>
                                <option value="tarefa">Tarefa</option>
                            </select>
                        </span>

                        <span>
                            <label htmlFor="prioridadeNonaTarefa">Prioridade</label>
                            <select id='prioridadeNonaTarefa' value={prioridade} onChange={(e) => setPrioridade(e.target.value)} required>
                                <option value=""></option>
                                <option value="urgente">Urgente</option>
                                <option value="alta">Alta</option>
                                <option value="baixa">Baixa</option>
                            </select>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="descricaoTarefa">Descrição da Tarefa</label>
                            <textarea 
                                id='descricaoTarefa'
                                name="descricaoTarefa"
                                value={descricao}
                                onChange={(e) => setDescricao(e.target.value)}
                                placeholder='Descreva aqui o que precisará ser realizado nesta tarefa'
                                rows={5}
                                required />
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="responsaveltarefa">Responsável</label>
                            <select id='responsaveltarefa' value={responsavel} onChange={(e) => setResponsavel(e.target.value)} required>
                                <option value=""></option>
                                {resposaveis.map((responsavel) => (
                                    <option key={responsavel.id} value={responsavel.id}>{responsavel.nome}</option>
                                ))}
                            </select>
                        </span>

                        <span>
                            <label htmlFor="prazoTarefaModelo">Prazo | Em Dias</label>
                            <input
                                id='prazoTarefaModelo'
                                value={prazo}
                                onChange={((e) => setPrazo(parseInt(e.target.value)))} 
                                placeholder='Do início do processo'
                                min={0}
                                type="number"
                                required/>
                        </span>
                    </section>

                    <div className='tarefasRecorrentes'>
                        <input
                            checked={automacao}
                            className='toggleAutomacaoTarefas'
                            id='toggleAutomacaoTarefas'
                            type="checkbox"
                            onChange={() => setAutomacao(!automacao)}/>
                        <label id='toggleAutomacaoTarefas' htmlFor='toggleAutomacaoTarefas'></label>
                        <p>Recorrência</p>
                    </div>

                    {automacao && <section className='automacaoTarefas'>
                        <span>
                            <label htmlFor="">Frequência</label>
                            <select value={recorrencia !== null ? recorrencia : ''} onChange={(e) => setRecorrencia(e.target.value)} required>
                                <option value=""></option>
                                <option value="diaria">Diária</option>
                                <option value="semanal">Semanal</option>
                                <option value="mensal">Mensal</option>
                                <option value="trimestral">Trimestral</option>
                                <option value="semestral">Semestral</option>
                                <option value="anual">Anual</option>
                            </select>
                        </span>
                        <span>
                            <label htmlFor="prazoAutomacao">Prazo</label>
                            <input 
                                id='prazoAutomacao'
                                type='number'
                                value={prazo}
                                placeholder='Prazo em Dias'
                                onChange={(e) => setPrazo(parseInt(e.target.value))} 
                                required/>
                        </span>
                    </section>}
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalTarefaModelo
