import BASEAPI from '../api/config'
import { baixarLancamentoPayload } from '../../interfaces/baixas'

export function baixarLancamento (payload: baixarLancamentoPayload) {
    return BASEAPI.post('/lancamentos/baixa', payload)
}

export function listarBaixas (id: string) {
    return BASEAPI.get (`/lancamentos/baixa/${id}`)
}

export function apagarBaixa (id: string) {
    return BASEAPI.delete(`/lancamentos/baixa/${id}`)
}