import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import propsPerfil from "../../interfaces/propsPerfil"

const useUsuarioPerfil =  () => {

    const [perfil, setPerfil] = useState <string> ('')
    const navigate = useNavigate ()

    useEffect (() => {

        const perfilLogado = () => {
        
            try {
                const resposta = localStorage.getItem("perfilGesttor")

                if (resposta === null || resposta === undefined) {
                    return navigate('/')
                }

                setPerfil(resposta)
        
            } catch (error: any) {
                return alert (error)
            }
        }

        perfilLogado ()
        
    }, [setPerfil, navigate])

    return perfil
}

const Perfil = (props: propsPerfil) =>  {

    const usuarioPerfil = useUsuarioPerfil ()

    if (props.perfil.some (perfil => usuarioPerfil.includes (perfil))) {
        return props.children
    }

    return null
}

export default Perfil