import BASEAPI from '../api/config'

import { atualizarContratoModeloPayload, cadastrarContratoModeloPayload, paramsListarContratoModelo, cadastrarContratoTopicoPayload, cadastrarClausulaPayload } from '../../interfaces/contratosModelos'

export function contratoModelo (payload: cadastrarContratoModeloPayload) {
    return BASEAPI.post('/contratos/modelos', payload)
} 

export function atualizarContratoModelo (idContrato: string, payload: atualizarContratoModeloPayload) {
    return BASEAPI.patch(`/contratos/modelos?idContratoModelo=${idContrato}`, payload)
}

export function listarContratoModelo (params: paramsListarContratoModelo) {
    return BASEAPI.get(`/contratos/modelos?situacao=${params.situacao}&termo=${params.termo}`)
} 

export function cadastrarTopico (idContrato: string, payload: cadastrarContratoTopicoPayload) {
    return BASEAPI.post(`/contratos/topicos/${idContrato}`, payload)
} 

export function listarTopicos (idContratoTopico: string) {
    return BASEAPI.get(`/contratos/topicos/${idContratoTopico}`)
} 

export function cadastrarClausula (payload: cadastrarClausulaPayload) {
    return BASEAPI.post('/contratos/clausulas', payload)
} 

export function listarClausulas (idContrado: string) {
    return BASEAPI.get(`/contratos/clausulas/${idContrado}`)
} 

