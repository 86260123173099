import BASEAPI from '../api/config'
import { cadastrarSubTarefaPayload, atualizarSubTarefaPayload } from '../../interfaces/subTarefas'


export function novaSubTarefa (idTarefa: number, payload: cadastrarSubTarefaPayload) {
    return BASEAPI.post(`/subtarefas/${idTarefa}`, payload)
} 

export function listarSubtarefas (idTarefa: string) {
    return BASEAPI.get (`/subtarefas/${idTarefa}`)
}

export function atualizarSubTarefa (idSubTarefa: number, payload: atualizarSubTarefaPayload) {
    return BASEAPI.patch(`/subtarefas/${idSubTarefa}`, payload)
}

export function deletarSubTarefa (idSubTarefa: number) {
    return BASEAPI.delete(`/subtarefas/${idSubTarefa}`)
}

