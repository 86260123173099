import { useState, FormEvent, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { atualizarAvatar } from '../../services/usuarios'
import { Button, Loader } from '../Styles'
import ModalAlerta from '../ModalAlerta'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import atualizarIcone from '../../assets/img/usuario.png'
import '../../styles/modal.css'


function ModalAtualizarAvatarUsuario () {

    const { modalExibir } = useContext(ModalEstadoContext)

    const [avatarSelecionado, setAvatarSelecionado] = useState<File | null>(null)
    const [avatarPreview, setAvatarPreview] = useState<string | ArrayBuffer | null>(null)
    const [form, setForm] = useState(true)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState(false)

    const ocultarModal = () => {
        modalExibir('')
        window.location.reload()
    }

    const atualizarAvatarLocal = (e:any) => {
        const arquivo = e.target.files[0]

        if(arquivo) {
            const extensaoPermitida = ['.png', '.jpg', '.jpeg']
            const extensaoArquivo = arquivo.name.split('.').pop().toLowerCase()

            if(extensaoPermitida.includes(`.${extensaoArquivo}`)) {

                const rendenrizar = new FileReader()
        
                rendenrizar.onloadend = () => {
                    setAvatarPreview(rendenrizar.result)
                }
        
                rendenrizar.readAsDataURL(arquivo)

                return setAvatarSelecionado(arquivo)
            } else {
                setAvatarSelecionado(null)
                return alert('Tipo de Imagem não Permitida')
            }
        }
    }

    function ocultarModalAlertaErro () {

        setErro (false)
        setForm(true)

        return
    }

    const enviarImagem = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(!avatarSelecionado) {
            return
        }

        const payload = {
            avatar: avatarSelecionado
        }

        try {

            const resposta = await atualizarAvatar(payload)

            if (resposta.status === 200) {

                localStorage.setItem("usuarioGesttorAvatar", `${resposta.data}`)
                setProcessando(false)
                setConfirmacao(true)
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
        }
        
    }


    return (
        <div id = 'fundoModal'>
            {confirmacao && <ModalAlerta texto= {'Dados atualizados com sucesso'} btnTitulo='Continuar' icone={confirmacaoImg} cor='continuarSucesso' acao={ocultarModal} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao tentar salvar os dados!' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlertaErro} />}
            {form && <section className='containerModalCentro'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={atualizarIcone} alt='' />
                    <p>Atualizar Foto de Perfil</p>
                </span>
                <form id='formModalNovo' onSubmit={enviarImagem}>
                    {avatarPreview && <section>
                        <span>
                            <img
                                src={avatarPreview as string}
                                alt=''
                                style={{ 
                                    width: '300px', 
                                    height: '300px', 
                                    objectFit: 'cover', 
                                    borderRadius: '50%', 
                                    padding: '10px'
                                }}
                            />
                        </span>
                    </section>}
                    <section>
                        <span>
                            <label htmlFor="nomeUsario">Selecione a Imagem</label>
                            <input
                                id='imagemAvatar' 
                                type="file" 
                                onChange={atualizarAvatarLocal} 
                                accept="image/*"
                                required 
                            />
                        </span>

                    </section>
                    
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>}
        </div>
    )
}

export default ModalAtualizarAvatarUsuario
