import { ProviderCompose, propsProvider } from "../interfaces/propsProvider"

export const ProvidersCompose = (providers: ProviderCompose) => {
    return ({ children }: propsProvider) => 
    
        providers.reduceRight((child, [Provider, props = {}]) => (

            <Provider {...props}>{child}</Provider>

        ), children )
}