import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import Lancamentos from "../../components/Lancamentos"

function LancamentosPage () {

    useEffect (() => {
        document.title = 'Lançamentos | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <Lancamentos />
        </DadosContainerDefault>
    )
}

export default LancamentosPage
