const cpfCnpjMascara = (valor: string) => {

    const cpfCnpj = valor.replace(/\D/g, '')

    if (cpfCnpj.length === 14) {

        valor = valor.replace(/\D/g, '')
        valor = valor.replace(/^(\d{2})(\d)/, "$1.$2")
        valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        valor = valor.replace(/\.(\d{3})(\d)/, ".$1/$2")
        valor = valor.replace(/(\d{4})(\d)/, "$1 - $2")
    
        return valor
        
    } else {

        valor = valor.replace (/\D/g, '')
        valor = valor.replace(/(\d{3})(\d)/, "$1.$2")
        valor = valor.replace(/(\d{3})(\d)/, "$1.$2")
        valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1 - $2")

        return valor
    }

}

export default cpfCnpjMascara