import { useState, FormEvent, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { lisatarCategorias, atualizarCategoria } from '../../services/categorias'
import { listaCategoriasResposta } from '../../interfaces/categorias'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import ModalCategoria from '../ModalCategoria'
import FiltrosDefault from '../FiltrosDefault'
import erroImg from '../../assets/img/erroFechar.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import Notificacoes from '../Notificacoes'
import ModalAlerta from '../ModalAlerta'
import LoagindBarra from '../LoadingBarra'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import DivSituacao from '../DivSituacao'
import ToogleDefault from '../ToogleDafault'
import './categorias.css'
import '../../styles/paginaListaDados.css'

function Categorias () {

    const { atualizarDados, atualizarInformacoes, exibirModal, modalEstado } = useContext(ModalEstadoContext)

    const navigate = useNavigate()
    const location = useLocation ()
    const { pathname } = location

    const [categorias, setCategorias] = useState<listaCategoriasResposta[]>([])
    const [categoriaSelecionada, setCategoriaSelecionada] = useState<listaCategoriasResposta>()
    const [novaConta, setNovaConta] = useState(false)
    const [descricao, setDescricao] = useState('')
    const [situacao, setSituacao] = useState('ativa')
    const [processando, setProcessando] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [confirmacao, setConfirmacao] = useState(false)
    const [erro, setErro] = useState(false)


    const ocultarModal = () => {

        return setErro(false)
    }

    const exibirModalNovoLead = () => exibirModal()


    const exibirModalDadosCategoria = (categoria: listaCategoriasResposta, id: string) => {

        exibirModal()
        navigate(`${pathname}?id=${id}`)

        return setCategoriaSelecionada(categoria)
    }

    const filtrarContasGerenciais = async (e: FormEvent) => {
        
        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        try {

            const params = {
                descricao,
                situacao: situacao
            }

            const resposta = await lisatarCategorias(params)

            if (resposta.data.length === 0) {
                setCategorias(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if(resposta.status === 200) {
                setCategorias(resposta.data)
                setProcessando(false)

                return
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)
            
            return
        }

    }

    const toggleCategoria = async (id: string, situacao: string, e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                situacao: situacao === 'ativa' ? 'inativa' : 'ativa'
            }

            const resposta = await atualizarCategoria(id, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDados()
                setConfirmacao(true)

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 2500)
            }

        } catch (error) {

            setProcessando(false)

            return setErro(true)
        }
    }

    useEffect(() => {

        if(!modalEstado) setCategoriaSelecionada(undefined)
        return setNovaConta(modalEstado)

    }, [modalEstado])

    useEffect(() => {

        const buscarContasGerenciais = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativa'
                }

                const resposta = await lisatarCategorias(params)

                if (resposta.data.length === 0) {
                    setCategorias(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if(resposta.status === 200) {
                    setCategorias(resposta.data)
                    setProcessando(false)

                    return
                }
                
            } catch (error) {

                setProcessando(false)
                setErro(true)
                
                return
            }
        }

        buscarContasGerenciais()

    }, [atualizarInformacoes])

    return (
        <div id='paginaContainer'>
            {novaConta && <ModalCategoria categoria={categoriaSelecionada} />}
            {processando && <LoagindBarra />}
            {confirmacao && <Notificacoes icone={confirmacaoImg} texto='Dados Atualizados com Sucesso' cor='sucesso' />}
            {nenhumResultado && <NenhumResultadoEncontrado />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação, favor tente novamente!' icone={erroImg} btnTitulo='Tentar Novamente' cor='continuarErro' acao={ocultarModal} />}
            <FiltrosDefault placeholder='Pesquise pela descrição da categoria' termo={descricao} situacao={situacao} onChangeTermo={(e) => setDescricao(e.target.value)} tipo='conta' onChangeSituacao={(e) => setSituacao(e.target.value)} listaSituacao={[{nome: 'Todas', valor: ''}, {nome: 'Ativas', valor: 'ativa'}, {nome: 'Inativas', valor: 'inativa'}]} onSubmit={filtrarContasGerenciais} acaoBtn={exibirModalNovoLead} genero='feminino' isLoading={processando}/>

            <section id='fundoContainerPagina'>
                <section id='centrosContainer'>
                    <span>
                        <h1>Categorias de Receitas</h1>
                        <section className='centrosContainer'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th align='left' className='ladoEsquerdoContent'>Descrição</th>
                                        <th align='left' className='centroResultadoCusto'>Cento de Resultado</th>
                                        <th align='center'>Situação</th>
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categorias.filter(categoria => categoria.tipo === 'receita').map((categoria) => (
                                        <tr key={categoria.id}>
                                            <td className='primeiraColuna' onClick={() => exibirModalDadosCategoria(categoria, categoria.id)}>{categoria.descricao}</td>
                                            <td className='centroResultadoCusto' onClick={() => exibirModalDadosCategoria(categoria, categoria.id)}>{categoria.centroResultado?.descricao}</td>
                                            <td align='center' onClick={() => exibirModalDadosCategoria(categoria, categoria.id)}>
                                                <DivSituacao texto={`${categoria.situacao.charAt(0).toUpperCase()}${categoria.situacao.substring(1)}`}  cor={categoria.situacao}/>
                                            </td>
                                            <td id='ladoDireitoContent' align='center' onClick={(e) => toggleCategoria(categoria.id, categoria.situacao, e)}>
                                                <ToogleDefault id={categoria.id} checked={() => categoria.situacao === 'ativa' ? true : false}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </span>
                    <span>
                        <h1>Categorias de Despesas</h1>
                        <section className='centrosContainer'>
                            <table id='tabelaListaContainer'>
                                <thead>
                                    <tr>
                                        <th align='left' className='ladoEsquerdoContent'>Descrição</th>
                                        <th align='left' className='centroResultadoCusto'>Centro de Custo</th>
                                        <th align='center'>Situação</th>
                                        <th align='center'>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categorias.filter(categoria => categoria.tipo === 'despesa').map((categoria) => (
                                        <tr key={categoria.id}>
                                            <td className='primeiraColuna' onClick={() => exibirModalDadosCategoria(categoria, categoria.id)}>{categoria.descricao}</td>
                                            <td className='centroResultadoCusto' onClick={() => exibirModalDadosCategoria(categoria, categoria.id)}>{categoria.centroCusto?.descricao}</td>
                                            <td align='center' onClick={() => exibirModalDadosCategoria(categoria, categoria.id)}>
                                                <DivSituacao texto={`${categoria.situacao.charAt(0).toUpperCase()}${categoria.situacao.substring(1)}`}  cor={categoria.situacao}/>
                                            </td>
                                            <td id='ladoDireitoContent' align='center' onClick={(e) => toggleCategoria(categoria.id, categoria.situacao, e)}>
                                                <ToogleDefault id={categoria.id} checked={() => categoria.situacao === 'ativa' ? true : false}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </span>
                </section>
            </section>
        </div>
    )
}

export default Categorias