import { createContext, useState } from 'react'
import { propsProvider } from '../../interfaces/propsProvider'
import { propsContextModelosProcessos } from '../../interfaces/propsContextModelosProcessos'
import { cadastrarTarefaModeloLocal } from '../../interfaces/modelosTarefas'

const ModelosProcessosContext = createContext<propsContextModelosProcessos>({
    modalProcessoEstado: false,
    modalTarefasProcesso: false,
    atualizarInformacoesProcesso: false,
    tarefaModeloLocal: undefined,
    exibirModalProcesso: () => {},
    exibirModalTarefasProcesso: () => {},
    atualizarDadosProcesso: () => {},
    inserirTarefaModeloLocal: (tarefa: cadastrarTarefaModeloLocal) => {},
})

const ModelosProcessosProvider = ({ children }: propsProvider) => {

    const [modalProcessoEstado, setModalProcessoEstado] = useState(false)
    const [modalTarefasProcesso, setModalTarefasProcesso] = useState(false)
    const [atualizarInformacoesProcesso, setAtualizarInformacoesProcesso] = useState(false)
    const [tarefaModeloLocal, setTarefaModeloLocal] = useState<cadastrarTarefaModeloLocal>()

    const exibirModalProcesso = () => setModalProcessoEstado(!modalProcessoEstado)
    const exibirModalTarefasProcesso = () => setModalTarefasProcesso(!modalTarefasProcesso)
    const atualizarDadosProcesso = () => setAtualizarInformacoesProcesso(!atualizarInformacoesProcesso)
    const inserirTarefaModeloLocal = (tarefa: cadastrarTarefaModeloLocal) => setTarefaModeloLocal(tarefa)

    return (
        <ModelosProcessosContext.Provider value={{ modalProcessoEstado, modalTarefasProcesso, exibirModalProcesso, exibirModalTarefasProcesso, atualizarDadosProcesso, atualizarInformacoesProcesso, inserirTarefaModeloLocal, tarefaModeloLocal }}>
            {children}
        </ModelosProcessosContext.Provider>
    )
}

export { ModelosProcessosContext, ModelosProcessosProvider }