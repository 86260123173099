import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import { ProcessosProvider } from "../../contexts/Processos"
import ProcessosPagina from "../../components/Processos"

function Proccessos () {

    useEffect (() => {
        document.title = 'Processos | Gesttor'
    }, [])
    
    return (
        <ProcessosProvider>
            <DadosContainerDefault>
                <ProcessosPagina />
            </DadosContainerDefault>
        </ProcessosProvider>
    )
}

export default Proccessos
