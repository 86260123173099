import BASEAPI from "../api/config"

export function saldoContas () {
    return BASEAPI.get('/dashboard/financeiro/saldocontas')
}

export function faturamentoMes () {
    return BASEAPI.get('/dashboard/financeiro/faturamento')
}

export function despesasMes () {
    return BASEAPI.get('/dashboard/financeiro/despesas')
}

export function faturamentoDespesas () {
    return BASEAPI.get('/dashboard/financeiro/faturamentodespesa')
}

export function lucroMes () {
    return BASEAPI.get('/dashboard/financeiro/lucro')
}

export function ticketMedio () {
    return BASEAPI.get('/dashboard/financeiro/ticket')
}

export function dreMes () {
    return BASEAPI.get('/dashboard/financeiro/dremes')
}

export function dreAno () {
    return BASEAPI.get('/dashboard/financeiro/dreano')
}