import processandoSolicitacao from '../../interfaces/processandoSolicitacao'
import './processandosolicitacao.css'

function ProcessandoSolicitacao (props: processandoSolicitacao) {

    return (
        <section id = 'processandoSolicitacaoContiener'>
            <p>{props.textoSuperior}</p>
            <span id='processandoSolicitacao' />
            <p>{props.textoInferior}</p>
        </section>
    )

}

export default ProcessandoSolicitacao