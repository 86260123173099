export const verificarTokenAutenticacao = () => {
    
    const cookie = document.cookie.indexOf('authGesttor')

    if (cookie < 0 ) {

        return  window.location.href = '/'

    } else {
        const authHg =  document.cookie.split('; ').find(token => token.startsWith ('authGesttor')).split('=')[1]
        return authHg
    }
}

export const Autenticacao = () => {

    const cookie = document.cookie.indexOf('authGesttor')

    if (cookie < 0) {
        
        return false
    }
    
    return true
}