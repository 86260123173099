import { useEffect } from "react"
import { DadosContainerDefault } from "../../components/Styles"
import DashboardPage from "../../components/Dashboard"

function Dashboard () {

    useEffect (() => {
        document.title = 'Dashboard | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <DashboardPage />
        </DadosContainerDefault>
    )
}

export default Dashboard
