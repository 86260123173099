import { useContext } from 'react'
import { MdEdit } from "react-icons/md";
import './dadosProcesso.css'
import { ModalEstadoContext } from '../../contexts/ModalEstado';

function ModalDadosProcesso({ processoSeletivoDetalhes }: any) {
  const { modalExibir } = useContext(ModalEstadoContext)

  const abrirModalEditarProcesso = async() => {
    modalExibir("");
    modalExibir("abrirModalEditarProcessoSeletivo");
  };

  return (
    <section className='fundoModal'>
      <section className='containerModalCentro containerDetalhesProcesso'>
        <section className="novoContainerTitulo">
          <h1>Sobre o Processo Seletivo:</h1>
          <i className="icone-editar" onClick={() => abrirModalEditarProcesso()}><MdEdit /></i>
        </section>
        <section>
          <label>Descrição:</label>
          <p>
            {processoSeletivoDetalhes.descricaoCargo}
          </p>
        </section>

        <section className="novoContainerDados">
          <label>Principais atribuições:</label>
          <p>
            {processoSeletivoDetalhes.principaisAtribuicao}
          </p>
        </section>

        {processoSeletivoDetalhes.beneficios && <section className="novoContainerDados">
          <label>Benefícios:</label>
          <p>
            {processoSeletivoDetalhes.beneficios}
          </p>
        </section>}

        <section className="novoContainerDados">
          {processoSeletivoDetalhes.requisitos &&
            <span>
              <label>Habilidades desejadas:</label>
              <p className="texto-destaque">{processoSeletivoDetalhes.requisitos}</p>
            </span>
          }
          <span>
            <label>Formação Acadêmica:</label>
            <p className="texto-destaque">{processoSeletivoDetalhes.formacaoAcademica}</p>
          </span>
          <span>
            <label>PerFil Comportamental:</label>
            <p className="texto-destaque">{processoSeletivoDetalhes.perfilComportamental}</p>
          </span>
        </section>

        <span className="botoesContainer">
          <button className="btn-detalhes" onClick={() => { modalExibir("") }}>Voltar</button>
        </span>
      </section>
    </section>
  )
}

export default ModalDadosProcesso