import styled from "styled-components"
import coresBtn from "../../interfaces/coresBtn"
import coresBackgroundDefault from "../../interfaces/coresBackgroundDefault"
import coresDivSituacaoDefault from "../../interfaces/coresDivSituacaoDefault"
import divSituacaoDefaultProps from "../../interfaces/divSituacaoDefaultProps"
import proposBackgroundNotificacoes from "../../interfaces/propsbackgroundNotificacoes"
import { propsBtn, propsLoading } from "../../interfaces/propsBtn"

const backgroundNotificacoes: coresBackgroundDefault = {
    sucesso: '#25AE88',
    erro: '#E21B1B'
}

const btnCores: coresBtn = {
    continuarSucesso: '#25AE88',
    continuarErro: '#E21B1B', 
    cancelar: '#E21B1B', 
    salvar: '#25AE88',
    contratar: '#25AE88',
    enviar: '#25AE88',
    solicitarUsuario: '#FF6B6C',
    cadastrar: '#018ABE',
    link: '#018ABE',
    novo: '#018ABE',
    processos: '#6A6C72',
    filtros: '#FF6B6C',
    baixarLancamento: '#6A6C72',
    cobrancasLancamentos: '#018ABE',
    voltar: '#001B48',
    detalhar: '#001B48'
}

const coresDiv: coresDivSituacaoDefault = {
    ativo: '#25AE88',
    ativa: '#25AE88',
    recebido: '#25AE88',
    paga: '#25AE88',
    pago: '#25AE88',
    finalizada: '#25AE88',
    finalizado: '#25AE88',
    conquistado: '#25AE88',
    conectado: '#25AE88',
    concluída: '#6A6C72',
    concluído: '#6A6C72',
    assinado: '#25AE88',
    aguardandoassinatura: '#6A6C72',
    repescagem: '#F7B558',
    aguardandoaprovação: '#F7B558',
    aguardandoinscritos: '#018ABE',
    recrutando: '#25AE88',
    nps: '#F7B558',
    aguardandorecebimento: '#6A6C72',
    aguardandopagamento: '#6A6C72',
    novo: '#018ABE',
    aberta: '#018ABE',
    emanálise: '#018ABE',
    aceita: '#018ABE',
    emdesenvolvimento: '#FF6B6C',
    recebimentoparcial: '#018ABE',
    pagamentoparcial: '#F7B558',
    aguardandoretornodosuporte: '#018ABE',
    aguardandoretornodocliente: '#018ABE',
    emnegociação: '#F7B558',
    emcobrança: '#F7B558',
    semanálise: '#F7B558',
    perdido: '#E21B1B',
    desconectado: '#E21B1B',
    recusada: '#E21B1B',
    inativo: '#A41623',
    inativa: '#A41623',
    atrasada: '#A41623',
    atrasado: '#A41623',
    vencida: '#A41623',
    cancelado: '#A41623',
    cancelada: '#A41623',
    urgente: '#A41623',
    pendente: '#F7B558',
    completo: '#25AE88',
    incompleto: '#F7B558',
    alta: '#F7B558',
    expirada: '#F7B558',
    baixa: '#A1A1A1',
    sim: '#25AE88',
    nao: '#A41623'
}

export const SectionDefault = styled.section <proposBackgroundNotificacoes> `
    background-color: ${(props) => backgroundNotificacoes[props.cor]};
    position: fixed;
    margin: 0 auto;
    color: #FFF;
    top: 10px;
    left: 0;
    right: 0;
    border-radius: 5px;
    width: max-content;
    height: 40px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    animation: AnimacaoNotificacao 300ms ease-in-out;

    @keyframes AnimacaoNotificacao { 
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export const DadosContainerDefault = styled.div `
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 230px);
    min-height: 100vh;
    background-color: #F7F8FA;

    @media (max-width: 900px) {
        width: calc(100% - 60px);
    }
`

export const DivSituacaoDefault = styled.div <divSituacaoDefaultProps> `
    background-color: ${(props) => coresDiv[props.cor]};
    width: 40%;
    min-width: 120px;
    color: #FFF;
    border-radius: 5px;
    padding: 7px 10px;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 900px) {
        min-width: 80px;
        padding: 3px 5px;
        width: 80%;
    }
`

export const Button = styled.button <propsBtn>`
    position: relative;
    min-width: 150px;
    height: 38px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: ${({ isLoading, cor }) => isLoading ? '#ccc' : btnCores[cor]};
    color: ${({ isLoading }) => isLoading ? '#999' : '#fff'};
    cursor: ${({ isLoading }) => isLoading ? 'not-allowed' : 'pointer'};
    transition: all 500ms;

    &:hover {
        transform: scale(105%)
    }
`;

export const Loader = styled.span <propsLoading> `
    display: ${({ isLoading }) => isLoading ? 'block' : 'none'};

    &::after,
    &::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent #F1F1FE #F1F1F3;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-sizing: border-box;
        animation: AnimacaoLoader 500ms linear infinite;
        transform-origin: center center;
    }

    @keyframes AnimacaoLoader { 
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`
