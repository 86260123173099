import { useEffect } from 'react'
import { DadosContainerDefault } from '../../components/Styles';
import MeuPainel from '../../components/MeuPainel';

function Painel () {

    useEffect (() => {
        document.title = 'Meu Painel | Gesttor'
    }, [])

    return (
        <DadosContainerDefault>
            <MeuPainel />
        </DadosContainerDefault>
    )
  }
  
  export default Painel