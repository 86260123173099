import BASEAPI from '../api/config'
import baseApiHg from '../api/formData'

import { Assinante, atualizarDocumentosPayload, cadastrarDocumentosPayload, paramsListarDocumentos } from '../../interfaces/documentosDigitais'

export function criarDocumentoDigital (payload: cadastrarDocumentosPayload) {
    return baseApiHg.post(`/documentos`, payload)
}

export function listarDocumentoDigital (params: paramsListarDocumentos) {
    return BASEAPI.get(`/documentos?situacao=${params.situacao}&nomeCadastro=${params.nomeCadastro}&pagina=${params.pagina}&dataInicial=${params.dataInicial}&dataFinal=${params.dataFinal}&escritorio=${params.escritorio}`)
} 

export function adicionarAssinanteAoDocumentoExistente(idDocumento:string, payload: Assinante) {
    return BASEAPI.post(`/documentos/assinantes/${idDocumento}`, payload)
}

export function atualizarDocumento(idDocumento:string, payload: atualizarDocumentosPayload) {
    return BASEAPI.patch(`/documentos/${idDocumento}`, payload)
}

export function solicitarAssinaturaWhatsapp(idAssinante: string) {
    return BASEAPI.post(`/documentos/assinatura/${idAssinante}`)
}