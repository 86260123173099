import { ProviderTuple } from "../interfaces/propsProvider"
import { ProvidersCompose } from "../provider"

import { Provider as ReduxProvider } from "react-redux"
import store from "../store"

import { ModalEstadoProvider } from "../contexts/ModalEstado"
import { AuthProvider } from "../contexts/Auth"

const providers: ProviderTuple [] = [
    [ AuthProvider, {}],
    [ ModalEstadoProvider, {} ],
    [ ReduxProvider, { store } ]
]

export const AppProvider = ProvidersCompose(providers)