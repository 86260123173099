import { useEffect } from "react"
import CadastroOrganizacao from "../../components/CadastroOrganizacao"

function OrganizacaoCadastro () {

    useEffect (() => {
        document.title = 'Cadastro | Gesttor'
    }, [])
    
    return <CadastroOrganizacao />
}

export default OrganizacaoCadastro
