import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import ContasPage from "../../components/Contas";

function ContasBancarias () {

    useEffect (() => {
        document.title = 'Contas Bancárias | Gesttor'
    }, [])


    return (
        <DadosContainerDefault>
            <ContasPage />
        </DadosContainerDefault>
    )
}

export default ContasBancarias