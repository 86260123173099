import BASEAPI from '../api/config'
import { cadastrarCategoriaPayload, paramsListCategoria, atualizarCategoriaPayload } from '../../interfaces/categorias'

export function novaCategoria (payload: cadastrarCategoriaPayload) {
    return BASEAPI.post('/categorias', payload)
}

export function lisatarCategorias (params: paramsListCategoria) {
    return BASEAPI.get (`/categorias?descricao=${params.descricao}&situacao=${params.situacao}`)
}

export function atualizarCategoria (id: string, payload: atualizarCategoriaPayload) {
    return BASEAPI.patch(`/categorias/${id}`, payload)
}
