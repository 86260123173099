import { FormEvent, useState, useEffect, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { AuthContext } from "../../contexts/Auth"
import { ModalEstadoContext } from "../../contexts/ModalEstado"
import { RootStore } from "../../store"
import { setExibirModalRecuperarSenha } from "../../store/modules/modalRecuperarSenha"
import { auth, buscarOrganizacoes } from "../../services/auth"
import { modalOrganizacoesUsuario } from "../../interfaces/auth"
import { io } from "socket.io-client"
import { Button } from "../Styles"
import ModalOrganizacoesUsuario from "../ModalOrganizacoesUsuarios"
import Notificacoes from "../Notificacoes"
import ModalRecuperarSenha from "../ModalRecuperarSenha"
import whatsAppImg from '../../assets/img/whatsapp.png'
import logoImg from '../../assets/img/logo.png'
import erroImg from '../../assets/img/erroFechar.png'
import "./auth.css";

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const paramsUrl = new URLSearchParams(search)
  const organizacaoSelecionada = paramsUrl.get('organizacaoSelecionada')

  const modalEstadoReceuperarSenha = useSelector(
    (store: RootStore) => store.modalRecuperarSenha
  );

  const { isAuth } = useContext(AuthContext)
  const { modal, modalExibir } = useContext(ModalEstadoContext)

  const [email, setEmail] = useState("")
  const [senha, setSenha] = useState("")
  const [organizacao, setOrganizacao] = useState<string | null>(null)
  const [organizacoesUsuario, setOrganizacoesUsuario] = useState<modalOrganizacoesUsuario[]>([])
  const [btnEntrar, setBtnEntrar] = useState(true)
  const [processando, setProcessando] = useState(false)
  const [dadosInvalidos, setDadosInvalidos] = useState(false)
  const [notUsuario, setNotUsuario] = useState(false)
  const [accessoNegado, setAcessoNegado] = useState(false)
  const [licencaExpirada, setLicencaExpirada] = useState(false)
  const [servidorIndisponivel, setServidorIndisponivel] = useState(false)
  const [modalRecuperarSenha, setModalRecuperarSenha] = useState(false)

  function dadosInvalidosLogin() {
    return setDadosInvalidos(false)
  }

  function cadastrarOrganizacao() {
    return navigate("/novaOrganizacao")
  }

  function exibirModalRecuperarSenha() {
    dispatch(
      setExibirModalRecuperarSenha({
        exibir: true,
      })
    )
  }


  const buscarOrganizacoesUser = async (e: FormEvent) => {

      e.preventDefault()
      setProcessando(true)

      try {

          const payload = {
            email
          }

          const resposta = await buscarOrganizacoes(payload)

          if(resposta.data.length === 1) {
            setOrganizacao(resposta.data[0].idOrganizacao)
            return setProcessando(false)
          }

          modalExibir('ModalOrganizacoesUsuario')
          setOrganizacoesUsuario(resposta.data)

          return setProcessando(false)
        
      } catch (error: any) {

        if (error.response.status === 404) {
          setProcessando(false)
          setNotUsuario(true)
  
          return setTimeout(() => {
            setNotUsuario(false)
          }, 5000)
        }
      }
  }

  async function Auth(e: FormEvent) {
    e.preventDefault();

    setBtnEntrar(false)
    setProcessando(true)

    if(organizacao === null) {
      return setProcessando(false)
    }

    const payload = {
      email,
      senha,
      idOrganizacao: organizacao
    };

    try {
      
      const resposta = await auth(payload)

      if (resposta.status === 200) {

        const data = new Date()
        const validadeToken = new Date (data.getTime() + 60 * 60 * 4 * 1000)

        const token = resposta.data.token;
        document.cookie = `authGesttor=${token}; expires=true ${validadeToken.toUTCString()}`

        localStorage.setItem("perfilGesttor", `${resposta.data.perfil}`)
        localStorage.setItem('usuarioGesttor', `${resposta.data.id}`)
        localStorage.setItem("organizacaoGesttor", `${resposta.data.idOrganizacao}`)
        localStorage.setItem("usuarioGesttorAvatar", `${resposta.data.avatar}`)
        localStorage.setItem('authGesttor', 'true')

        setBtnEntrar(true)
        setProcessando(false)

        const organizacao = resposta.data.idOrganizacao
        const usuario = resposta.data.id

        const socket = io(`${process.env.REACT_APP_API_GESTTOR}?idOrganizacao=${organizacao}`)

        const mensagemDados = {
          usuario
        }

        socket.emit('notificacaoUsuario', organizacao, mensagemDados)

        isAuth()
        
        
        return navigate("/tarefas");
      }
      
    } catch (error: any) {
      if (error.response.status === 400) {
        setDadosInvalidos(true);
        setBtnEntrar(true);
        setProcessando(false);

        return setTimeout(() => {
          setDadosInvalidos(false);
        }, 5000);
      }

      if (error.response.status === 408) {
        setLicencaExpirada(true);
        setBtnEntrar(true);
        setProcessando(false);

        return setTimeout(() => {
          setLicencaExpirada(false);
        }, 5000);
      }

      if (error.response.status === 0) {
        setServidorIndisponivel(true);
        setBtnEntrar(true);
        setProcessando(false);

        return setTimeout(() => {
          setServidorIndisponivel(false);
        }, 5000);
      }

      if (error.response.status === 403) {
        setAcessoNegado(true);
        setBtnEntrar(true);
        setProcessando(false);

        return setTimeout(() => {
          setAcessoNegado(false);
        }, 5000);
      }
    }
  }

  useEffect(() => {
    setModalRecuperarSenha(modalEstadoReceuperarSenha.exibir);
  }, [modalEstadoReceuperarSenha]);

  useEffect(() => {

    const identificarOrganizacao = () => {

      const organizacao  = organizacaoSelecionada

      if(!organizacao) {
        return
      }

      return setOrganizacao(organizacao)
    }

    identificarOrganizacao()

  },[organizacaoSelecionada])

  return (
    <div id="fundoLogin">
      {notUsuario && <Notificacoes icone={erroImg} titulo="Dados nâo Encontrado " texto="Tente Novamente" cor="erro"/>}
      { modal.nome === 'ModalOrganizacoesUsuario' && <ModalOrganizacoesUsuario dados={organizacoesUsuario}/>}
      {dadosInvalidos && (
        <Notificacoes icone={erroImg} titulo="Dados Inválidos" texto=", tente novamente!" cor="erro" />
      )}
      {accessoNegado && (
        <Notificacoes
          icone={erroImg}
          titulo="Acesso Negado"
          texto=". Fale com o administrador da sua empresa para liberar o acesso"
          cor="erro"
        />
      )}
       {licencaExpirada && (
        <Notificacoes icone={erroImg} texto="Licença Expirada. Fale com o nosso Atendimento" cor="erro" />
      )}
      {servidorIndisponivel && (
        <Notificacoes
          icone={erroImg}
          titulo="Serviço Indisponível"
          texto=". Tente novamente mais tarde!"
          cor="erro"
        />
      )}
      {modalRecuperarSenha && <ModalRecuperarSenha />}
      <section id="containerEsquerdoLogin">
        <div id="containerFormLogin">
          <img id="logoSistemaGesttor" src={logoImg} alt="logo do Sistema Gesttor"/>
          <span>
            <h1>Login</h1>
            <p>
              Ainda não tem uma conta?{" "}
              <strong id="cadastreSe" onClick={cadastrarOrganizacao}>
                Cadastre-se
              </strong>{" "}
            </p>
          </span>
          <form id="formLogin" onSubmit={organizacao === null ? buscarOrganizacoesUser : Auth }>
            <span className="formCampo">
              <label htmlFor="campoEmail">Usuário</label>
              <input
                type="email"
                id="campoEmail"
                className="email"
                placeholder="insira seu e-mail"
                required
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => dadosInvalidosLogin()}
              ></input>
            </span>
            {organizacao && <span className="formCampo">
              <label htmlFor="campoSenha">Senha</label>
              <input
                type="password"
                id="campoSenha"
                className="senha"
                placeholder="insira sua senha com 8 dígitos"
                minLength={8}
                maxLength={8}
                required
                onChange={(e) => setSenha(e.target.value)}
                onFocus={() => dadosInvalidosLogin()}
              ></input>
            </span>}
            <span>
              <p
                id="recuperarSenha"
                onClick={() => exibirModalRecuperarSenha()}
              >
                Esqueci Minha Senha
              </p>
            </span>
            <span id="formEntrar">
              {btnEntrar && <Button type="submit" isLoading={processando} cor='continuarSucesso'>Continuar</Button>}
            </span>
          </form>
        </div>
      </section>
      <section id="containerDireitoLogin"/>
      <a href="https://wa.me/message/YYTHX6J2OKZ3O1" target="_blanck">
        <span id="suporteSistemaGesttor">
              <img src={whatsAppImg} alt="" title="Como podemos te ajudar?"/>
              <p>Como podemos te ajudar?</p>
        </span>
      </a>
    </div>
  );
}

export default Login;
