import BASEAPI from '../api/config'
import { cadastrarCentroPayload, paramsListCentros, atualizarCentroPayload } from '../../interfaces/centros'

export function novoCentro (payload: cadastrarCentroPayload) {
    return BASEAPI.post('/centros', payload)
}

export function lisatarCentros (params: paramsListCentros) {
    return BASEAPI.get (`/centros?descricao=${params.descricao}&situacao=${params.situacao}`)
}

export function atualizarCentro (idCentro: string, payload: atualizarCentroPayload) {
    return BASEAPI.patch(`/centros?idCentro=${idCentro}`, payload)
}
