import { useState, FormEvent, useContext, ChangeEvent } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { novoLead, atualizarLead } from '../../services/leads'
import { ModalLeadDados } from '../../interfaces/leads'
import { telefoneMascara } from '../../helpers'
import { Button, Loader } from '../Styles'
import Notificacoes from '../Notificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import adicionarIcone from '../../assets/img/novoUsuario.png'
import atualizarIcone from '../../assets/img/usuario.png'
import '../../styles/modal.css'


function ModalLeads ({ lead }: ModalLeadDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { atualizarDados, modalExibir } = useContext(ModalEstadoContext)

    const { pathname, search } = location

    const [nome, setNome] = useState(lead?.nome)
    const [telefone, setTelefone] = useState(lead?.telefone)
    const [email, setEmail] = useState(lead?.email)
    const [empresa, setEmpresa] = useState(lead?.empresa)
    const [mensagem, setMensagem] = useState(lead?.mensagem)
    const [formaConhecimento, setFormaConhecimento] = useState(lead?.formaConhecimento || 'Instagram')
    const [resultado, setResultado] = useState(lead?.resultado)
    const [situacao, setSituacao] = useState(lead?.situacao || 'Novo')
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState(false)

    const exibirModalNovoCadastro = () => modalExibir ('cadastro')

    const exibirModalNovoProcesso = () => modalExibir('processo')  

    function ocultarModalLead () {

        navigate(`${pathname}`)

        return modalExibir ('')
    }

    const changeTelefone = (e: ChangeEvent<HTMLInputElement>) => {
        return setTelefone(telefoneMascara(e.target.value))
    }

    const cadastrarLead = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                nome,
                telefone,
                email,
                empresa,
                mensagem,
                situacao,
                formaConhecimento
            }

            const resposta = await novoLead(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {
            
            setProcessando(false)
            setErro(true)
            
            return setTimeout(() => {
                setErro(false)
            }, 5000)
        }

    }

    const updateLead = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const idLead = paramsUrl.get('idLead')

        if (idLead === null) {
            return setProcessando(false)
        }

        if ((situacao === 'Conquistado' || situacao === "Perdido") && resultado === null) {
            alert('Para encerrar o lead você precisa colocar o resultado')

            return setProcessando(false)
        }

        const payload = {
            nome,
            telefone,
            email,
            empresa,
            formaConhecimento,
            mensagem,
            resultado,
            situacao
        }

        try {

            const resposta = await atualizarLead(idLead, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                setConfirmacao(true)
                atualizarDados()

                return setTimeout(() => {
                    setConfirmacao(false)
                }, 5000)
            }
            
        } catch (error) {

            setProcessando(false)
            setErro(true)

             return setTimeout(() => {
                setErro(false)
            }, 5000)
        }
    }


    return (
        <div id = 'fundoModal'>
            {confirmacao && <Notificacoes  texto= {lead ? 'Dados atualizados com sucesso' : 'Dados Salvos com Sucesso'} icone={confirmacaoImg} cor='sucesso'/>}
            {erro && <Notificacoes texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' icone={erroImg} cor='erro'/>}
            <section className='containerModalCentro'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={lead ? atualizarIcone : adicionarIcone} alt='' />
                    <p>{!lead ? 'Novo Lead': 'Vizualizar Lead'}</p>
                </span>
                <form id='formModalNovo' onSubmit={lead ? updateLead : cadastrarLead}>
                    <section>
                        <span>
                            <label htmlFor="nomeLead">Nome</label>
                            <input
                                id='nomeLead'
                                value={nome} 
                                onChange={(e) => setNome(e.target.value)}
                                type="text"
                                required/>
                        </span>

                        <span>
                            <label htmlFor="situacaoLead">Situacao</label>
                            <select id="situacaoLead" value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                <option value="Novo">Sem Contato</option>
                                <option value="Em Negociação">Em Negociação</option>
                                {lead && <option value="Conquistado">Conquistado</option>}
                                {lead && <option value="Perdido">Perdido</option>}
                            </select>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="telefoneModalLead">Telefone</label>
                            <input
                                id='telefoneModalLead'
                                value={telefone}
                                onChange={(e) => changeTelefone(e)}
                                minLength={18}
                                maxLength={18} 
                                type="text"
                                required/>
                        </span>

                        <span>
                            <label htmlFor="emailLead">E-mail</label>
                            <input
                                id='emailLead'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} 
                                type="email"/>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="empresaLead">Empresa</label>
                            <input
                                id='empresaLead'
                                value={empresa === null ? '' : empresa}
                                onChange={(e) => setEmpresa(e.target.value)} 
                                type="text" />
                        </span>
                        
                        <span>
                            <label htmlFor="formaConhecimentoModalLead">Forma de Conhecimento</label>
                            <select id="formaConhecimentoModalLead" value={formaConhecimento} onChange={(e) => setFormaConhecimento(e.target.value)}>
                                <option value="Google" >Google</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Instagram" selected>Instagram</option>
                                <option value="Indicação de Amigos">Indicação de Amigos</option>
                                <option value="Participação em Eventos">Participação em Eventos</option>
                                <option value="Outros">Outros</option>
                            </select>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="mensagemLead">Mensagem</label>
                            <textarea
                                id="mensagemLead" 
                                rows={3}
                                value={mensagem}
                                onChange={(e) => setMensagem(e.target.value)}
                                placeholder='Descreva o que o seu lead está buscando' 
                                required/>
                        </span>
                    </section>

                    {lead && <section>
                        <span>
                            <label htmlFor="resultadoLead">Resultado</label>
                            <textarea
                                id="resultadoLead" 
                                rows={5}
                                value={resultado === null ? '' : resultado}
                                onChange={(e) => setResultado(e.target.value)}
                                placeholder='Descreva a situação final do seu lead, se ele fechou ou não algum serviço com você.'/>
                        </span>
                    </section>}
               
                    <span id="botoesContainer">
                        <Button type='button' isLoading={processando} cor='cancelar' onClick={ocultarModalLead}>Cancelar</Button>
                        {lead?.situacao === 'Conquistado' && <Button type='button' cor='cadastrar' isLoading={processando} onClick={exibirModalNovoCadastro}>Cadastros</Button>}
                        {lead?.situacao === 'Conquistado' && <Button type='button' cor='processos' isLoading={processando} onClick={exibirModalNovoProcesso}>Processos</Button>}
                        <Button type='submit' isLoading={processando} cor='salvar'>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalLeads
