import { FormEvent, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { novaOrganizacao } from '../../services/organizacao'
import { cpfCnpjMascara, validarCpfCnpj } from '../../helpers'
import ModalAlerta from '../ModalAlerta'
import Notificacoes from '../Notificacoes'
import LoadingPontinhos from '../LoadingPontinhos'
import logoImg from '../../assets/img/logo.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import whatsAppImg from '../../assets/img/whatsapp.png'
import erroImg from '../../assets/img/erroFechar.png'
import './cadastroorganizacao.css'

function CadastroOrganizacao () {

    const navigate = useNavigate()

    const [tipo, setTipo] = useState ('')
    const [nome, setNome] = useState ('')
    const [email, setEmail] = useState('')
    const [organizacao, setOrganizacao] = useState ('')
    const [cnpjCpf, setCnpjCpf] = useState('')
    const [senha, setSenha] = useState ('')
    const [confirmarSenha, setConfirmarSenha] = useState ('')
    const [btnCadastrar, setBtnCadastrar] = useState (true)
    const [processando, setProcessando] = useState (false)
    const [senhasDiferentes, setSenhasDiferentes] = useState (false)
    const [cpfCnpjInvalido, setCpfCnpjInvalido] = useState (false)
    const [servidorIndisponivel] = useState(false)
    const [dadosSalvos, setDadosSalvos] = useState (false)
    const [organizacaoCadastrada, setOrganizacaoCadastrada] = useState(false)
    const [emailCadastrado, setEmailCadastrado] = useState(false)
    const [erroSalvarOrganizacao, setErroSalvarOrganizacao] = useState(false)

    function acesarSistema () {
        
        return navigate ('/login')
    }

    function dadosInvalidosCadastro () {

        setSenhasDiferentes (false)
        setCpfCnpjInvalido (false)
        
        return
    }


    function tentarNovamenteCadastrarOrganizacao () {

        setOrganizacaoCadastrada (false)
        setEmailCadastrado (false)
        setErroSalvarOrganizacao (false)

        return
    }

    function conferirSenha () {

        if (senha !== confirmarSenha) {

            setSenha ('')
            setConfirmarSenha('')
            setSenhasDiferentes (true)

            return setTimeout (() => {
                setSenhasDiferentes (false)
            }, 3500)
        }
    }

    const cnpjCpfChange = (e: any) => {
        
        const { value } = e.target

        setCnpjCpf (cpfCnpjMascara(value))
    }

    const validarcnpjCpf = (e: any) => {
        
        const { value } = e.target

        const resposta = validarCpfCnpj (value)

        if (resposta === false) {

            setCnpjCpf ('')
            setCpfCnpjInvalido (true)

            return setTimeout (() => {
                setCpfCnpjInvalido (false)
            }, 3500)
        }
    }

    const cadastrarOrganizacao = async (e: FormEvent) => {

        e.preventDefault()

        setBtnCadastrar(false)
        setProcessando (true)

        const payload = {
            tipo,
            cnpjCpf,
            nomeFantasia: organizacao,
            nomeUsuario: nome,
            emailUsuario: email,
            senhaUsuario: senha
        }

        try {
            
            const resposta = await novaOrganizacao (payload)

            if (resposta.status === 201) {

                setProcessando (false)
                setBtnCadastrar(true)

                return setDadosSalvos (true)
            }

        } catch (error: any) {

            setProcessando (false)
            setBtnCadastrar(true)

            if (error.response.status === 405) {

                return setOrganizacaoCadastrada (true)
            }

            if (error.response.status === 410) {
                
                return setEmailCadastrado (true)
            }

            return setErroSalvarOrganizacao (true)
        }
    }

    useEffect (() => {

        const verificarTipo = () => {
            if (cnpjCpf.length === 20) {
                setTipo ('pessoa jurídica')
            } else {
                setTipo ('pessoa física')
            }
        }

        verificarTipo ()

    }, [cnpjCpf])
   
    return (
            <div id ="fundoCadastroOrganizacao">
            {cpfCnpjInvalido && <Notificacoes icone={erroImg} titulo='CNPJ/CPF Inválido' texto='. Tente Novamente!' cor='erro'  />}
            {senhasDiferentes && <Notificacoes icone={erroImg} titulo='As senhas não confere' texto='. Digite novamente!' cor='erro'  />}
            {servidorIndisponivel && <Notificacoes icone={erroImg} titulo='Servidor Indisponível' texto=', tente novamente mais tarde!' cor='erro'  />}
            {dadosSalvos && <ModalAlerta texto='Dados Salvos com Sucesso!' btnTitulo='Acessar o Sistema' icone={confirmacaoImg} cor='continuarSucesso' acao={acesarSistema}/>}
            {erroSalvarOrganizacao && <ModalAlerta texto='Ocorreu um erro ao tentar Salvar os Dados. Favor tente novamente!' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={tentarNovamenteCadastrarOrganizacao}/>}
            {organizacaoCadastrada && <ModalAlerta texto='Já existe um cadastro para o CPNJ/CPF informado, gentileza tentar outro diferente!' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={tentarNovamenteCadastrarOrganizacao}/>}
            {emailCadastrado && <ModalAlerta texto='Já existe um usuário com este e-mail informado, gentileza tentar outro diferente!' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={tentarNovamenteCadastrarOrganizacao}/>}
            <section id = 'containerEsquerdoCadastro'>
                <div id='containerFormCadastrarOrganizacao'>
                    <span>
                        <img id="logoSistemaGesttor" src={logoImg} alt="logo do Sistema Gesttor"/>
                        <h1>Cadastre-se</h1>
                        <p>Tem uma conta? <strong id = 'cadastreSe' onClick={acesarSistema}>Login</strong> </p>
                    </span>
                    <form id = 'formLogin' onSubmit={cadastrarOrganizacao}>
                        <span className = 'formCampo'>
                            <label htmlFor="campoNome">Nome</label>
                            <input 
                                type = 'text' 
                                id = 'campoNome' 
                                className = 'campoOrganizacaoCadastro' 
                                placeholder="insira seu nome e sobrenome"
                                value={nome} 
                                required
                                onChange={(e) => setNome(e.target.value)}>
                            </input>
                        </span>
                        <span className = 'formCampo'>
                            <label htmlFor="campoNome">E-mail</label>
                            <input 
                                type = 'email' 
                                id = 'campoEmail' 
                                className = 'campoOrganizacaoCadastro' 
                                placeholder="insira seu e-mail" 
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}>
                            </input>
                        </span>
                        <span className = 'formCampo'>
                            <label htmlFor="campoNome">Empresa</label>
                            <input 
                                type = 'text' 
                                id = 'campoNomeOrganizacao' 
                                className = 'campoOrganizacaoCadastro' 
                                placeholder="digite o nome fantasia do seu negócio"
                                value={organizacao} 
                                required
                                onChange={(e) => setOrganizacao(e.target.value)}>
                            </input>
                        </span>
                        <span className = 'formCampo'>
                            <label htmlFor="campoNome">CNPJ/CPF</label>
                            <input 
                                type = 'text' 
                                id = 'campoCnpjCpf' 
                                className = 'campoOrganizacaoCadastro' 
                                placeholder="digite o CNPJ ou CPF do seu negócio"
                                value={cnpjCpf}
                                minLength={14}
                                maxLength={20} 
                                required
                                onChange={cnpjCpfChange}
                                onFocus={() => dadosInvalidosCadastro()}
                                onBlur={validarcnpjCpf}>
                            </input>
                        </span>
                        <span className = 'formCampo'>
                            <label htmlFor="campoSenha">Senha</label>
                            <input 
                                type = 'password' 
                                id = 'campoSenha' 
                                className = 'senha' 
                                placeholder="insira sua senha com 8 dígitos"
                                value={senha} 
                                minLength= {8} 
                                maxLength= {8} 
                                required
                                onFocus={() => dadosInvalidosCadastro()}
                                onChange={(e) => setSenha(e.target.value)}>
                            </input>
                        </span>
                        <span className = 'formCampo'>
                            <label htmlFor="campoConfirmarSenha">Confirme a Senha</label>
                            <input 
                                type = 'password' 
                                id = 'campoConfirmarSenha' 
                                className = 'senha' 
                                placeholder="digite novamente a senha"
                                value={confirmarSenha} 
                                minLength= {8} 
                                maxLength= {8} 
                                required
                                onFocus={() => dadosInvalidosCadastro()}
                                onChange={(e) => setConfirmarSenha(e.target.value)}
                                onBlur={() => conferirSenha()}>
                            </input>
                        </span>
                        <span className='formCampo aceiteTermosContratacao'>
                            <input 
                                type="checkbox"
                                required/>
                            <label htmlFor="">Ao continuar você está de acordo com a nossa <a href="https://www.meuhg.com/politicaprivacidade" target='_blanck'>Política de Privacidade</a> e nossos <a href="https://www.meuhg.com/termosuso" target='_blanck'>Termos de Uso</a></label>
                        </span>    
                        <span id = 'formEntrar'>
                            {btnCadastrar && <button id = 'btnCadastrar'>Cadastrar</button>}
                            {processando && <LoadingPontinhos />}
                        </span>
                    </form>
                </div>
            </section>
            <section id = 'containerDireitoCadastro'/>
            <a href="https://wa.me/message/YYTHX6J2OKZ3O1" target="_blanck">
                <span id="suporteSistemaGesttor">
                    <img src={whatsAppImg} alt="" title="Como podemos te ajudar?"/>
                    <p>Como podemos te ajudar?</p>
                </span>
            </a>
        </div>
    )
}

export default CadastroOrganizacao