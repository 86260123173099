import BASEAPI from '../api/config'
import { cadastrarProcessos, paramsListaProcessos, processosAtualizarPayload } from '../../interfaces/processos'

export function novoProcesso (payload: cadastrarProcessos) {
    return BASEAPI.post('/processos', payload)
}

export function listarProcessos (paramns: paramsListaProcessos) {
    return BASEAPI.get(`/processos?cliente=${paramns.cliente}&situacao=${paramns.situacao}&processosEscritorio=${paramns.processosEscritorio}`)
}

export function atualizarProcesso (idProcesso: string, payload: processosAtualizarPayload) {
    return BASEAPI.patch(`/processos?idProcesso=${idProcesso}`, payload)
}

export function tarefasProcesso (idProcesso:string) {
    return BASEAPI.get(`/processos/tarefas?idProcesso=${idProcesso}`)
}

export function apagarProcesso (idProcesso: string) {
    return BASEAPI.delete(`/processos?idProcesso=${idProcesso}`)
}