import { useEffect } from "react"
import SuportePage from "../../components/Suporte"
import { DadosContainerDefault } from "../../components/Styles"

function Suporte () {

    useEffect (() => {
        document.title = 'Suporte | Gesttor'
    }, [])
    
    return (
        <DadosContainerDefault>
            <SuportePage />
        </DadosContainerDefault>
    )
}

export default Suporte
