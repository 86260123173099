import CryptoJS from 'crypto-js'

const descriptografarDado = (dado: string) => {

    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY

    const decodificarDado = decodeURIComponent(dado)

    const bytes = CryptoJS.AES.decrypt(decodificarDado, SECRET_KEY)

    return bytes.toString(CryptoJS.enc.Utf8)
}

export default descriptografarDado