import BASEAPI from '../api/config'

export function tarefasAtrasadas () {
    return BASEAPI.get ('/tarefas/atrasadas')
}

export function tarefasPendentes () {
    return BASEAPI.get ('/tarefas/pendentes')
}

export function chamadosPendentes () {
    return BASEAPI.get ('/chamados/pendentes')
}

export function atendimentosPendentes () {
    return BASEAPI.get ('/atendimentos/pendentes')
}

export function ultimosAtendimentos () {
    return BASEAPI.get ('/tarefas/finalizadas')
}

export function Produtividade () {
    return BASEAPI.get ('/tarefas/produtividade')
}

export function npsAtendimento () {
    return BASEAPI.get ('/nps/atendimento')
}
