import { useState } from 'react'
import Perfil from '../../middleware/perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import '../../styles/menuopcoes.css'

function MenuDashboard (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (
        <div>
            <div id = 'menuOpcoes' onClick={exibirSubmenu}>
                {<img id = 'iconeMenu' src= {props.icone} alt = {props.alt}></img>}
                <p id = 'tituloMenu'>{props.titulo}</p>
            </div>
            <div id = 'submenuOpcoes'>
                <Perfil perfil={['master']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '/dashboard' texto = 'Geral' />}
                        {subMenu && <SubMenuOpcoes redirect = '/dashboard/financeiro' texto = 'Financeiro' />}
                    </>
                </Perfil>
                {subMenu && <SubMenuOpcoes redirect = {'/painel'} texto = 'Meu Painel' />}
            </div>
        </div>
    )
}

export default MenuDashboard