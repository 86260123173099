import BASEAPI from '../api/authConfig'
import { buscarOrganizacoesUsuario, loginPayload, recuperarSenhaPayload, novaSenhaCadastrarPayload } from "../../interfaces/auth"

export function buscarOrganizacoes (payload: buscarOrganizacoesUsuario) {
    return BASEAPI.post('/auth/organizacoes', payload)
}

export function auth (payload: loginPayload) {
    return BASEAPI.post ('/auth', payload)
}

export function recuperarSenha (payload: recuperarSenhaPayload) {
    return BASEAPI.post('/recuperarsenha', payload)
}

export function novaSenha (payload: novaSenhaCadastrarPayload) {
    return BASEAPI.post ('/novasenha', payload)
}

