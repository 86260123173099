import { useState, useContext, useEffect } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { cobrancas } from '../../services/contratarSistema'
import { visualizarCobranca } from '../../services/cobrancas'
import { cobrancasResposta } from '../../interfaces/contratarSistema'
import ModalQrCode from '../ModalQrCodePix'
import DivSituacao from '../DivSituacao'
import ModalAlerta from '../ModalAlerta'
import LoadingCirculo from '../LoadingCirculo'
import boletoImg from '../../assets/img/codigoBarras.png'
import pixImg from '../../assets/icons/pix.svg'
import recorrenciaImg from '../../assets/img/recorrencia.png'
import fecharImg from '../../assets/img/erroFechar.png'
import erroImg from '../../assets/img/erroFechar.png'
import './modalassinatura.css'
import '../../styles/modal.css'


function ModalAssinatura () {

    const { modalSecundario, modalExibir, modalSecundarioExibir, atualizarInformacoes } = useContext(ModalEstadoContext)

    const [cobbrancas, setCobrancas] = useState<cobrancasResposta[]>([])
    const [dados, setDados] = useState(false)
    const [carregando, setCarregando] = useState(true)
    const [semAssinatura, setSemAssinatura] = useState(false)
    const [erro, setErro] = useState(false)
    const [cobrancasSelecionada, setCobrancaSelecionada] = useState({
        index: 0,
        exibir: false
    })

    const modalQrCodePix = (idCobranca: number) => {
        
        localStorage.setItem('idCobrancaGesttor', idCobranca.toString())
        
        return modalSecundarioExibir('qrCodePix')
    }


    const ocultarModal = () => modalExibir('')

    const ocultarModalAlerta = () => {
        setErro(false)
        setSemAssinatura(false)
    }

    const exbirCobrancas = (index: number) => {
        setCobrancaSelecionada(prevState => {

            const lancamentoAtual = index === prevState.index
            
            const novoEstado = {
                index: (index === prevState.index && prevState.exibir) ? -index : index,
                exibir: lancamentoAtual ? !prevState.exibir : true
            }
            
            return novoEstado
        })
    }

    const visualizarCobrancaBoleto = async (idCobranca: number) => {

        setCarregando(true)

        try {

            const resposta = await visualizarCobranca(idCobranca)

            if (resposta.status === 200) {

                const dadosPdf = URL.createObjectURL(resposta.data)

                const pdf = document.createElement('a')

                pdf.href = dadosPdf
                pdf.download = 'boleto.pdf'

                document.body.appendChild(pdf)

                pdf.click()

                document.body.removeChild(pdf)

                setCarregando(false)
            }
            
        } catch (error) {

            setCarregando(false)
            setErro(true)

            return
            
        }
    }

    useEffect(() => {
        
        const buscarCobrancas = async () => {

            try {

                const resposta = await cobrancas()

                if(resposta.status === 200) {

                    setCobrancas(resposta.data)
                    setCarregando(false)
                    setDados(true)
                }
                
            } catch (error: any) {

                if(error.response.status === 404) {
                    return setSemAssinatura(true)
                }

                return setErro(true)
            }
        }

        buscarCobrancas ()

    }, [atualizarInformacoes])


    return (
        <div id = 'fundoModal'>
            {modalSecundario.nome === 'qrCodePix' && <ModalQrCode/>}
            {semAssinatura && <ModalAlerta texto='Você ainda não contratou o sistema. Acesse configurações > Dados da Empresa e realize a sua contratação' btnTitulo='Fechar Tela' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            {erro && <ModalAlerta texto='Ocorreu um erro ao processar a solicitação. Tente Novamente' btnTitulo='Tentar Novamente' icone={erroImg} cor='continuarErro' acao={ocultarModalAlerta} />}
            <section id='containerModalNovo'>
                <div id='modalAssinaturaTituloContent'>
                    <span id='novoContainerTitulo'>
                        <img id='adicionarModalIcone' src={recorrenciaImg} alt='' />
                        <p>Minha Assinatura</p>
                    </span>
                    <span>
                        <img onClick={() => ocultarModal()} id='fecharModalAssinatura' src={fecharImg} alt='' title='Fechar Modal'/>
                    </span>
                </div>

                <section id='fundoContainerPagina' className='tabelaCobrancasContainer'>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th id='ladoEsquerdoContent' align='left'>Descrição</th>
                                <th align='left'>Valor Total</th>
                                <th align='center'>Vencimento</th>
                                <th align='center'>Situação</th>
                            </tr>
                        </thead>
                        {dados && <tbody>
                            {cobbrancas.map((cobranca, index) => (
                                <>
                                    <tr key={index} onClick={() => exbirCobrancas(index)}>
                                        <td className='primeiraColuna descricaoCobranca'>{cobranca.descricao.toUpperCase()}</td>
                                        <td className='dadosCobrancas valorTotalCobranca'>{Number(cobranca.valorTotal).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                                        <td className='dadosCobranca' align='center'>{cobranca.vencimento.slice(8,10)}/{cobranca.vencimento.slice(5,7)}/{cobranca.vencimento.slice(0,4)}</td>
                                        <td className='dadosCobranca ladoDireitoContent' align='center'>
                                            <DivSituacao texto={`${cobranca.situacao.charAt(0).toUpperCase()}${cobranca.situacao.substring(1)}`} cor={cobranca.situacao.toLowerCase().replace(/\s+/g, '')}/>
                                        </td>
                                    </tr>
                                    { cobrancasSelecionada.exibir && cobrancasSelecionada.index === index &&
                                        <tr className='linhaTabelaListaCobrancasAssiantens' key={Math.random()}>
                                            <td colSpan={4}>
                                                <table id='tabelaListaContainer'>
                                                    <thead>
                                                        <tr>
                                                            <th className='ladoEsquerdoContent' align='left'>Vencimento da Cobrança</th>
                                                            <th className='contaBaixaLancamento' align='left'>Tipo</th>
                                                            <th className = 'valorBaixaLancamento' align='left'>Valor</th>
                                                            <th className = 'valorBaixaLancamento' align='left'>Data do Recebimento</th>
                                                            <th className = 'valorBaixaLancamento' align='left'>Situação</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cobranca.cobrancas.map((cobrancaGerada) => (
                                                            <tr key={cobrancaGerada.id}>
                                                                <td className='primeiraColuna'>{cobrancaGerada.dataCobranca.slice(8,10)}-{cobrancaGerada.dataCobranca.slice(5,7)}-{cobrancaGerada.dataCobranca.slice(0,4)}</td>
                                                                <td className='contaBaixaLancamento'>{cobrancaGerada.tipo}</td>
                                                                <td className='contaBaixaLancamento'>{cobrancaGerada.valor?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                                <td className='contaBaixaLancamento'>{cobrancaGerada.dataRecebimento?.slice(8,10)}-{cobrancaGerada.dataRecebimento?.slice(5,7)}-{cobrancaGerada.dataRecebimento?.slice(0,4)}</td>
                                                                <td>
                                                                    <DivSituacao texto={cobrancaGerada.situacao} cor={cobrancaGerada.situacao.toLowerCase().replace(/\s+/g, '')} />
                                                            </td>
                                                            <td id='ladoDireitoContent' className='ladoDireitoCobrancasMinhasAssinaturas' align='center'>
                                                                {cobrancaGerada.situacao === 'Em Cobrança' && <img id='apagarIconeTarefa' title='Visualizar Boleto' src={boletoImg} alt='' onClick={() => visualizarCobrancaBoleto(Number(cobrancaGerada.id))}/>}
                                                                {cobrancaGerada.situacao === 'Em Cobrança' && <img id='apagarIconeTarefa' title='Pagar com o Pix' src={pixImg} alt='' onClick={() => modalQrCodePix(cobrancaGerada.id)}/>}
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    }
                                </>
                            ))}
                        </tbody>}
                    </table>
                    {carregando && <div className='processandoDadosCobrancas'>
                        <LoadingCirculo/>
                    </div>}
                </section>
            </section>
        </div>
    )
}

export default ModalAssinatura
