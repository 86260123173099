import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts'
import { npsOrganizacao } from '../../services/dashboard'
import { npsOrganizacaoResposta } from '../../interfaces/nps'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import LoadinCirculo from '../LoadingCirculo'
import './npsorganizacao.css'

function GraficoNpsOrganizacao () {

    const navigate = useNavigate()

    const [dados, setDados] = useState<npsOrganizacaoResposta []>([])
    const [valorMaximo, setValorMaximo] = useState(0)
    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const mediaAtendimento = async () => {


            try {
                const resposta = await npsOrganizacao ()
                setCarregando (false)
                setDados (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setGrafico (true)

            } catch (error: any) {

                if (error.status === 401) {
                    return navigate('/');
                }

            }
        }

        mediaAtendimento ()

    }, [navigate])

    useEffect (() => {

        let valorMaximo = 0

        dados.forEach (valor => {
            if (Number(valor.nps) > valorMaximo) {
                valorMaximo = Number(valor.nps)
            }
        })

        return setValorMaximo(Number(valorMaximo.toFixed()))

    }, [dados])


    return (
        <>
            <div id = 'graficoNpsOrganizacao'>
                <p id='graficoNpsOrganizacaoP'>NPS</p>
                {carregando && <LoadinCirculo />}
                {grafico && <ResponsiveContainer>
                    <AreaChart data={dados} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="media_atendimento" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#25AE88" stopOpacity={1}/>
                            <stop offset="95%" stopColor="#25AE88" stopOpacity={0.4}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="mes" />
                    <YAxis domain={[0, valorMaximo]} />
                    <Tooltip />
                    <CartesianGrid strokeDasharray="6 6" />
                    <Area type="monotone" dataKey="nps" strokeWidth={2} stroke="#25AE88"  activeDot = {{r: 8}} fillOpacity={1} fill="url(#media_atendimento)" />
                    </AreaChart>
                </ResponsiveContainer>}
                {semDados && <NenhumDadoGrafico texto='Nenhum atendimento da sua organização' subtexto='foi avaliado'/>}
            </div>
        </>
    )
}

export default GraficoNpsOrganizacao