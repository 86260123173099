import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovoUsuarioReduce = createSlice ({
    name: 'modalNovoUsuario',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovoUsuario (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovoUsuario (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovoUsuario, setOcultarModalNovoUsuario } = modalNovoUsuarioReduce.actions

export default modalNovoUsuarioReduce.reducer